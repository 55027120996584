import React from 'react'
import Select from "react-select";
import { TagsInput } from "react-tag-input-component";


const AddNewLiveVideo = () => {
  return (
    <div>
        <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 p-2">
              <div className="card ">
                <div className="card-body">
                  <div className="container-fluid p-0">
                    <div className="p-3">
                      <div>
                        <h4 className="">Add New Video</h4>
                        <hr />

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Title</label>
                            <p>Add The Video Title In The Textbox Below:</p>
                            
                            <div>
                              <input
                                type="text"
                                id="title"
                                name="title"
                                className="form-control"
                                // onChange={(e) => setTitle(e.target.value)}
                                placeholder="Title"
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <label className="m-0">Slug</label>
                            <p className="">
                              Add the Live stream slug in the textbox below:
                            </p>
                            <div>
                              <input
                                type="text"
                                id="slug"
                                name="slug"
                                className="form-control"
                                // onChange={(e) => setSlug(e.target.value)}
                                placeholder="slug"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start align-items-center">
                          <div className="col-md-6">
                            <label className="m-0">
                              Video Image Cover
                            </label>
                            <p className="p1">
                            Select The Video Image (1080x1920)
                            </p>
                            <div>
                              <input
                                type="file"
                                className="form-control"
                                name="image"
                                id="image"
                                // onChange={(e) => setImage(e.target.files[0])}
                                placeholder="Live_stream_video"
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="row">
                              <label className="m-0">Player Image Cover</label>
                              <p className="">
                              Select The Video Image(1280x720 Px ):
                              </p>
                              <div>
                                <input
                                  type="file"
                                  className="form-control"
                                  name="player_image"
                                  id="player_image"
                                //   onChange={(e) =>
                                //     setPlayer_image(e.target.files[0])
                                //   }
                                  placeholder="player_image"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Video Source</label>
                           
                            <div className="">
                              <div>
                                <select
                                  className="form-select url_type"
                                //   onClick={(event) => {
                                //     setLivesource(event.target.value);
                                //   }}
                                >
                                  <option defaultValue="0" selected>
                                    Choose URL Format
                                  </option>
                                  <option value="mp4" selected>
                                    mp4
                                  </option>
                                  <option value="Embed_url">Embed_url</option>
                                  <option value="Mp3">Mp3</option>
                                </select>
                              </div>
                              {/* {live_stream_source()} */}

                            </div>
                          </div>

                          
                        </div>

                        

                        <div className="row mt-3 text-start">
                          <div className="col-sm-12">
                            <label className="m-0">Short Description</label>
                            <p className="">
                              Add a short description of the Livestream below:
                            </p>
                            <div className="">
                              <textarea
                                className="form-control"
                                id="description"
                                name="description"
                                // onChange={(e) => setDescription(e.target.value)}
                                placeholder="Description"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Search Tags</label>
                            {/* <div className="">
                              <input
                                className="form-control me-2"
                                type="search"
                                placeholder="search_tags"
                                aria-label="Search"
                                name="search_tags"
                                id="search_tag"
                                onChange={(e) => setSearch_tags(e.target.value)}
                              />
                            </div> */}
                            <TagsInput
                            //   value={search_tags}
                            //   onChange={setSearch_tags}
                              name="search_tags"
                            />
                          </div>

                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-12">
                            <label className="m-0">
                            Video Details, Links, and Info
                            </label>
                            <div className="">
                              <textarea
                                className="form-control"
                                id="details"
                                name="details"
                                // onChange={(e) => setDetails(e.target.value)}
                                placeholder="setDetails"
                              />

                              {/* <JoditEditor
                              
                                value={details}
                                onChange={(newContent) =>
                                  contentFieldChanaged(newContent)
                                }
                              /> */}
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Category</label>
                            <p className="p1">
                              Select A Live Stream Category Below:
                            </p>

                            <div className=" form-control">
                              <div className="text-dark">
                                {/* <MultiSelectDropdown
                                  options={category}
                                  onChange={handleCategory}
                                  className= 'form-control'
                                /> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <label className="m-0">Language</label>
                            <p className="p1">
                              Select A Live Stream Language Below:
                            </p>
                            <div className="form-control">
                              <div className="text-dark">
                                {/* <MultiSelectDropdown
                                  options={language}
                                  onChange={handleLanguageChange}
                                /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                     

                        <div className="row text-start mt-4">
                          <div className="col-sm-6">
                            <label className="m-0">Video Ratings</label>
                            <p className="p1">
                              Live Stream Ratings 10 Out Of 10{" "}
                            </p>
                            <div className="form-control">
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                // onChange={(e) => setRating(e.target.value)}
                              >
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <label className="m-0">Video Year</label>
                            <p className="p1">Video Created Year</p>
                            <div className="">
                              <input
                                type="text"
                                name="year"
                                className="form-control"
                                // onChange={(e) => setYear(e.target.value)}
                                placeholder="Set Year"
                              />
                            </div>
                          </div>
                        </div>

                        

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Duration</label>
                            <p className="p1">
                              Enter The Live Stream Duration In (HH : MM : SS)
                            </p>
                            <div>
                              <input
                                type="text"
                                className="form-control"
                                // onChange={(e) => setDuration(e.target.value)}
                                placeholder="duration"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 ">
                            <label className="m-0">User Access</label>
                            <p className="p1">
                              Who Is Allowed To View This Live Stream ?
                            </p>
                            <div className="form-control">
                              <Select
                                // options={userAccesses}
                                // onChange={(e) => handleAccessChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                        {/* {userAccessesValue?.value === "ppv" ? (
                          <>
                            <div class="row mt-3 text-start ">
                              <div class="col-sm-4">
                                <label class="m-0">PPV Price</label>
                                <p class="p1">
                                  Apply PPV Price from Global Settings?
                                  <input
                                    type="checkbox"
                                    name="ppv_gobal_price"
                                    id="ppv_gobal_price"
                                    checked={checkboxChecked}
                                    onChange={handleCheckboxChange}
                                  />
                                </p>

                                <div class="panel-body">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="PPV Price"
                                    name="ppv_price"
                                    id="price"
                                    value={inputValue}
                                    onChange={handleInputChange}
                                  />
                                  <div class="clear"></div>
                                </div>
                              </div>

                              <div class="col-sm-4">
                                <label class="m-0"> IOS PPV Price</label>
                                <p class="p1">
                                  Apply IOS PPV Price from Global Settings?
                                </p>
                                <div class="panel-body form-control">
                                  <Select
                                    options={ios_ppv_price}
                                    onChange={(e) => setIos_ppv_price(e)}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null} */}

                        <div className="row mt-3 text-start">
                          <div className="col-sm-4">
                            <label className="m-0">Publish Type</label>

                            <div className="p2">
                              <div>
                                <input
                                  // className="active"
                                  type="radio"
                                  id="publish_now"
                                  name="publish_type"
                                  value="1"
                                //   checked={publish_type === "1"}
                                //   onChange={() => handlePublishType("1")}
                                />
                                <label> Publish Now</label>
                              </div>
                              <div>
                                <input
                                  type="radio"
                                  id="publish_later"
                                  name="publish_type"
                                  value="0"
                                //   checked={publish_type === "0"}
                                //   onChange={() => handlePublishType("0")}
                                />
                                <label> Publish Later </label>
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-4">
                            {/* {publish_type === "0" && (
                              <div>
                                <label>Publish Time:</label>
                                <input
                                  type="datetime-local"
                                  id="publish_time"
                                  name="publish_time"
                                  value={publish_time}
                                  onChange={(e) =>
                                    handlePublishTime(e.target.value)
                                  }
                                />
                              </div>
                            )} */}
                          </div>
                          
                        </div>
                        <div className="text-end">
                          <button 
                        //   onClick={stream} 
                          className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default AddNewLiveVideo