import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-select";
import { TagsInput } from "react-tag-input-component";
import { AiFillYoutube } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import JoditEditor from "jodit-react";

const Edit_NewLiveVideo = () => {
  const { id } = useParams();
  // const [editnewlivevideo, setEditnewlivevideo] = useState([]);
  // const [search_tag, setSearch_tags] = useState({});
  const [livestream_source, setLivestream_source] = useState([]);
  const [editUser, setEditUser] = useState({
    title: "",
    id: "",
    slug: "",
    active: "",
    status: "",
    url_type: "",
    mp4_url: "",
    embed_url: "",
    m3u_url: "",
    live_stream_video: "",
    rtmp_url: "",
    hls_url: "",
    Stream_key: "",
    description: "",
    details: "",
    search_tags: "",
    rating: "",
    age_restrict: "",
    duration: "",
    featured: "",
    banner: "",
    year: "",
    publish_status: "",
    publish_type: "",
    publish_time: "",
    user_id: "",
    uploaded_by: "",
    access: "",
    ppv_price: "",
    ios_ppv_price: "",
    enable_restream: "",
    fb_restream_url: "",
    fb_streamkey: "",
    youtube_restream_url: "",
    youtube_streamkey: "",
    twitter_restream_url: "",
    twitter_streamkey: "",
    linkedin_restream_url: "",
    linkedin_streamkey: "",
    video_category_id: "",
    language: "",
    footer: "",
    ads_position: "",
    live_ads: "",
    Video_thumbnail: "",
    Player_thumbnail: "",
    Video_TV_Thumbnail: "",
  });

  const [image, setImage] = useState("");
  const [player_image, setPlayer_image] = useState("");
  const [Tv_image, setTv_Image] = useState("");
  const [live_stream_video, setLive_stream_video] = useState("");

  const [publish_type, setPublish_type] = useState("");
  // console.log(publish_type, "pp");
  const [publish_time, setPublish_time] = useState("");
  // console.log(publish_time, "time");

  const [enable_restream, setEnable_restream] = useState("");
  // console.log(enable_restream, "ee");
  const [showModal, setShowModal] = useState(false);
  const [selectedButtons, setSelectedButtons] = useState([]);
  const [showInputs, setShowInputs] = useState(false);

  const [user_access, setUser_access] = useState([]);
  const [inapppurchase, setInappPurchase] = useState("");

  const [age_restrictdata, setAge_restrictdata] = useState([]);
  const [pre_ads_category, setPre_ads_category] = useState("");
  const [mid_ads_category, setMid_ads_category] = useState("");
  const [post_ads_category, setPost_ads_category] = useState("");

  // console.log(banner, "banner");
  const [active, setActive] = useState(0);
  const [search_tags, setSearch_tags] = useState("");
  const [ios_ppv_price, setIos_ppv_price] = useState("");

  const [publish, setPublish] = useState([]);
  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");
  const [Content_Partner_id, setContent_Partner_id] = useState("1");

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleChangeFirst = (e) => {
    setEditUser({ ...editUser, details: e });
    // console.log(e, "onchnageddddd");
  };

  // console.log(inputValue, "checkboxChecked");

  const handleCheckbox = () => {
    setEnable_restream(!enable_restream);
    if (!enable_restream) {
      setShowModal(true);
    } else {
      setShowInputs(false);
      setSelectedButtons([]);
    }
  };

  const handleButtonClick = (button) => {
    setSelectedButtons((prevSelectedButtons) => [
      ...prevSelectedButtons,
      button,
    ]);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSave = () => {
    setShowModal(false);
    setShowInputs(true);
  };

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    // console.log(selectedValue5); // Selected value
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // MULTI SELECT NEW
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  const [optionsartists, setOptionsartists] = useState([]);
  const [selectedValuesartists, setSelectedValuesartists] = useState([]);

  const [optionscategories, setOptionscategories] = useState([]);
  const [selectedValuescategories, setSelectedValuescategories] = useState([]);

  const [optionslanguages, setOptionslanguages] = useState([]);
  const [selectedValueslanguages, setSelectedValueslanguages] = useState([]);

  const [optionsAge_Restrict, setOptionsAge_Restrict] = useState([]);
  const [selectedValuesAge_Restrict, setSelectedValuesAge_Restrict] = useState(
    []
  );

  const [formData, setFormData] = useState({
    block_country: [],
    artists: [],
    categories: [],
    languages: [],
    Age_Restrict: [],
    // ...editInputvalue,
    // other form data properties...
  });

  // const [languages, setLanguages] = useState([]);
  // const [selectedLanguages, setSelectedLanguages] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/Edit/${id}`,
        { headers: headers }
      );
      const data = response?.data?.selected_Audios_block_countries;
      const dataartists = response?.data?.selected_Audios_artists;
      const datacategories = response?.data?.selected_Audios_categories;
      const datalanguages = response?.data?.selected_languages;
      const dataAge_Restrict = response?.data?.selected_Age_Restrict;

      var result = response?.data?.user_access;
      var InappPurchase = response?.data?.InappPurchase;
      setUser_access(result);
      setInappPurchase(InappPurchase);

      var res = response?.data?.audios[0];
      setEditUser(res);

      // console.log(res)
      // console.log(res)

      const selectedData = data;
      const formattedOptions = selectedData.map((item) => ({
        value: item.id,
        label: item.country_name,
      }));
      const formattedOptionsartists = dataartists?.map((item) => ({
        value: item.id,
        label: item.id,
      }));
      const formattedOptionscategories = datacategories?.map((item) => ({
        value: item.id,
        label: item.id,
      }));
      const formattedOptionslanguages = datalanguages?.map((item) => ({
        value: item.id,
        label: item.id,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict?.map((item) => ({
        value: item.id,
        label: item.id,
      }));

      // Set the initial selected values
      setSelectedValues(formattedOptions);
      setOptions(formattedOptions);

      setSelectedValuesartists(formattedOptionsartists);
      setOptionsartists(formattedOptionsartists);

      setSelectedValuescategories(formattedOptionscategories);
      setOptionscategories(formattedOptionscategories);

      setSelectedValueslanguages(formattedOptionslanguages);
      setOptionslanguages(formattedOptionslanguages);

      setSelectedValuesAge_Restrict(formattedOptionsAge_Restrict);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
    } catch (error) {
      console.error(error);
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-Create`,
        { headers: headers }
      );
      const data = response?.data?.Block_country;
      const dataartists = response?.data?.artists;
      const datacategories = response?.data?.audio_categories;
      const datalanguages = response?.data?.languages;
      const dataAge_Restrict = response?.data?.Age_Restrict;

      var age_restrictdata = response?.data?.Age_Restrict;
      setAge_restrictdata(age_restrictdata);
      const globelset = response?.data?.ppv_gobal_price;
      setInputValueAPI(globelset);
      // console.log(data);
      // console.log(data);
      // Extract the selected data from the response
      const selectedData = data;
      const formattedOptions = selectedData.map((item) => ({
        value: item.id,
        label: item.country_name,
      }));
      const formattedOptionsartists = dataartists.map((item) => ({
        value: item.id,
        label: item.id,
      }));
      const formattedOptionscategories = datacategories.map((item) => ({
        value: item.id,
        label: item.id,
      }));
      const formattedOptionslanguages = datalanguages.map((item) => ({
        value: item.id,
        label: item.id,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict.map((item) => ({
        value: item.id,
        label: item.id,
      }));

      // Set the options for the multi-select component
      setOptions(formattedOptions);
      setOptionsartists(formattedOptionsartists);
      setOptionscategories(formattedOptionscategories);
      setOptionslanguages(formattedOptionslanguages);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
    } catch (error) {
      console.error(error);
    }
  };
  // const handleLanguageChange = (event) => {
  //   const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
  //   setSelectedLanguages(selectedOptions);
  // };

  const handleSelectChange = (selectedOptions) => {
    const block_country = selectedOptions.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      block_country: block_country,
    });

    setSelectedValues(selectedOptions);
  };
  const handleSelectChangeartists = (selectedOptionsartists) => {
    const artists = selectedOptionsartists?.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      artists: artists,
    });

    setSelectedValuesartists(selectedOptionsartists);
  };

  const handleSelectChangecategories = (selectedOptionscategories) => {
    const categories = selectedOptionscategories?.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      categories: categories,
    });

    setSelectedValuescategories(selectedOptionscategories);
  };

  const handleSelectChangelanguages = (selectedOptionslanguages) => {
    const languages = selectedOptionslanguages?.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      languages: languages,
    });

    setSelectedValueslanguages(selectedOptionslanguages);
  };
  const handleSelectChangeAge_Restrict = (selectedOptionsAge_Restrict) => {
    const Age_Restrict = selectedOptionsAge_Restrict?.map(
      (option) => option.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      Age_Restrict: Age_Restrict,
    });

    setSelectedValueslanguages(selectedOptionsAge_Restrict);
  };

  // MULTI SELECT NEW

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.Live_Stream[0];
      setEditUser(res);
      setSearch_tags(res?.search_tags);
      setLivestream_source(resData?.Livestream_source);
      // console.log(resData)
      // console.log(res);
      // console.log(resData?.data?.Livestream_source);
      // console.log(res.search_tags);
    };
    getUser();
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
    setSearch_tags({ ...search_tags, [e.target.name]: e.target.value });
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      title: editUser.title,
      slug: editUser.slug,
      active: editUser.active,
      status: editUser.status,
      url_type: editUser.url_type,
      mp4_url: editUser.mp4_url,
      embed_url: editUser.embed_url,
      m3u_url: editUser.m3u_url,
      live_stream_video: editUser.live_stream_video,
      rtmp_url: editUser.rtmp_url,
      hls_url: editUser.hls_url,
      Stream_key: editUser.Stream_key,
      description: editUser.description,
      details: editUser.details,
      search_tags: editUser.search_tags,
      rating: editUser.rating,
      age_restrict: editUser.age_restrict,
      duration: editUser.duration,
      featured: editUser.featured,
      banner: editUser.banner,
      year: editUser.year,
      publish_status: editUser.publish_status,
      publish_type: publish_type,
      publish_time: editUser.publish_time,
      user_id: editUser.user_id,
      uploaded_by: editUser.uploaded_by,
      access: editUser.access,
      ppv_price: editUser.ppv_price,
      ios_ppv_price: editUser.ios_ppv_price,
      enable_restream: editUser.enable_restream,
      fb_restream_url: editUser.fb_restream_url,
      fb_streamkey: editUser.fb_streamkey,
      youtube_restream_url: editUser.youtube_restream_url,
      youtube_streamkey: editUser.youtube_streamkey,
      twitter_restream_url: editUser.twitter_restream_url,
      twitter_streamkey: editUser.twitter_streamkey,
      linkedin_restream_url: editUser.linkedin_restream_url,
      linkedin_streamkey: editUser.linkedin_streamkey,
      video_category_id: editUser.video_category_id,
      footer: editUser.footer,
      language: editUser.language,
      live_ads: editUser.live_ads,
      ads_position: editUser.ads_position,
      Content_Partner_id: Content_Partner_id,
      ...formData,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-update/` + id,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson.arti)
    if (res.status === 200) {
      // setMessage(resjson.success);
      // alert("Success");

      var result = resjson.LiveStream.id;

      const formDatas = new FormData();

      formDatas.append("image", image);
      formDatas.append("player_image", player_image);
      formDatas.append("TV_image", Tv_image);
      formDatas.append("livestream_id", result);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-image-upload/${result}`,
          formDatas,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api' , response);
          if (response.data.status === true) {
            var result = response.data;
            // console.log('result', response);
            // navigate('/admin/all-artist')
            // alert("Added successfully  !");
          }
        });

      const formData1 = new FormData();

      formData1.append("livestream_id", result);
      formData1.append("live_stream_video ", live_stream_video);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-video-upload`,
          formData1,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api' , response);
          if (response.data.status === true) {
            var result = response.data;
            alert("Request failed with status: " + response.status);
          }
        });

      // setTimeout(() => {
      //   navigate('/admin/livestream');
      // }, 2000);
    } else {
      // setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  useEffect(() => {
    // fetchAdsPositions();
  }, []);

  const fetchAdsPositions = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-Create`,
        {
          headers: headers,
        }
      );

      if (response.ok) {
        const data = await response?.json();
        const adsPositionOptions = data?.ads_position?.map((position) => ({
          value: position?.position,
          label: position?.name,
        }));
        setAdsPositions(adsPositionOptions);
      } else {
        throw new Error("Request failed with status: " + response?.status);
      }
    } catch (error) {
      console.log("Error fetching ads positions:", error);
    }
  };

  useEffect(() => {
    if (selectedAdsPosition !== "") {
      // console.log(selectedAdsPosition, "adsssssssssss");
      const formData = new FormData();
      formData.append("position", selectedAdsPosition?.value);

      axios

        .post(
          `${process.env.REACT_APP_Baseurl}/CPP/advertisement-tag-url`,
          formData,
          { headers: headers }
        )

        .then((response) => {
          const data = response?.data;
          // console.log(data, "exceptioned");

          const adsNames = data?.Advertisement?.map((position) => ({
            value: position?.id,
            label: position?.ads_name,
          }));

          setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition]);

  const [enable_restreampublic, setEnable_restreampublic] = useState("");
  const [enablestreamvisiblepublic, setEnablestreamvisiblepublic] =
    useState("");

  function proceednextpublic() {
    switch (enable_restreampublic) {
      case "publish_now":
        return <div></div>;
      case "publish_later":
        return (
          <div className="mb-3">
            <label className="m-0">Publish Time</label>
            <input
              type="datetime-local"
              className="form-control"
              id="publish_time"
              value={editUser.publish_time}
              name="publish_time"
              onChange={handleInput}
            />
          </div>
        );
      default:
        return null;
    }
  }

  const [livesource, setLivesource] = useState("");

  function live_stream_source() {
    switch (livesource) {
      case "0":
        return <div></div>;
      case "mp4":
        return (
          <div className="mt-3">
            <label>Mp4/M3U8 URL</label>
            <input
              type="text"
              className="form-control mt-2"
              value={editUser.mp4_url}
              name="mp4_url"
              onChange={handleInput}
              placeholder="Mp4/M3U8 URL"
            />
          </div>
        );
      case "embed":
        return (
          <div className="mt-3">
            <label>Embed Url</label>

            <input
              type="text"
              className="form-control mt-2"
              name="embed_url"
              value={editUser.embed_url}
              onChange={handleInput}
              placeholder="Embed_url"
            />
          </div>
        );
      case "live_stream_video":
        return (
          <div className="mt-3">
            <label>Live Stream Video</label>

            <input
              type="file"
              accept="video/mp4"
              name="live_stream_video"
              className="form-control mt-2"
              onChange={(e) => setLive_stream_video(e.target.files[0])}
              placeholder="live_stream_video"
            />
          </div>
        );
      case "m3u_url":
        return (
          <div className="mt-3">
            <label>M3u_url</label>

            <input
              type="text"
              className="form-control mt-2"
              name="m3u_url"
              value={editUser.m3u_url}
              onChange={handleInput}
              placeholder="m3u_url"
            />
          </div>
        );

      case "Encode_stream_video":
        return (
          <div className="mt-3">
            <label>Encode_stream_video</label>

            <input
              type="text"
              className="form-control mt-2"
              value={editUser.rtmp_url}
              onChange={handleInput}
              placeholder="RTMP URL"
            />
          </div>
        );

      default:
        return null;
    }
  }

  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 p-2">
              <div className="card ">
                <div className="card-body">
                  <div className="container-fluid p-0">
                    <div className="p-3">
                      <div>
                        <h4 className="">Edit Video Stream</h4>
                        <hr />

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Title</label>
                            <p className="p1">
                              Add The Video Title In The Textbox Below:
                            </p>

                            <div>
                              <input
                                type="text"
                                id="title"
                                name="title"
                                className="form-control"
                                value={editUser?.title}
                                onChange={handleInput}
                                placeholder="Title"
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <label className="m-0">Slug</label>
                            <p className="p1">
                              Add the Live stream slug in the textbox below:
                            </p>
                            <div>
                              <input
                                type="text"
                                id="slug"
                                name="slug"
                                className="form-control"
                                value={editUser?.slug}
                                onChange={handleInput}
                                placeholder="slug"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start align-items-center">
                          <div className="col-md-6">
                            <label className="m-0">Video Image Cover</label>
                            <div></div>
                            <p className="p1">
                              Select The Video Image (1080x1920)
                            </p>
                            <div>
                              <img
                                src={editUser.Video_thumbnail}
                                width={200}
                                height={200}
                              />
                              <input
                                type="file"
                                className="form-control"
                                name="image"
                                id="image"
                                onChange={(e) => setImage(e.target.files[0])}
                                placeholder="Live_stream_video"
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="row">
                              <label className="m-0">Player Image Cover</label>
                              <p className="p1">
                                Select The Video Image(1280x720 Px ):
                              </p>
                              <div>
                                <img
                                  src={editUser.Video_thumbnail}
                                  width={200}
                                  height={200}
                                />
                                <input
                                  type="file"
                                  className="form-control"
                                  name="player_image"
                                  id="player_image"
                                  onChange={(e) =>
                                    setPlayer_image(e.target.files[0])
                                  }
                                  placeholder="player_image"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3 text-start align-items-center">
                          <div className="col-md-6">
                            <div className="row">
                              <label className="m-0">TV Live Image</label>
                              <p className="">
                                Select The TV Live Image(1280x720 Px ):
                              </p>
                              <div>
                                <input
                                  type="file"
                                  className="form-control"
                                  name="player_image"
                                  id="player_image"
                                  onChange={(e) =>
                                    setPlayer_image(e.target.files[0])
                                  }
                                  placeholder="player_image"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Video Source</label>

                            <div className="">
                              <div>
                                <select
                                  className="form-control url_type"
                                  value={editUser.live_stream_source}
                                  onClick={(event) => {
                                    setLivesource(event.target.value);
                                  }}
                                >
                                  <option defaultValue="" selected>
                                    Choose URL Format
                                  </option>
                                  {livestream_source?.map((item, key) => (
                                    <option value={item?.value}>
                                      {item?.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {live_stream_source()}
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="">
                              <label className="m-0">TV Image Cover</label>
                              <p className="p1">
                                Select The TV Image(1280x720 Px ):
                              </p>
                              <img
                                src={editUser.Video_TV_Thumbnail}
                                width={200}
                                height={200}
                              />
                              <div>
                                <input
                                  type="file"
                                  className="form-control"
                                  name="player_image"
                                  id="player_image"
                                  onChange={(e) =>
                                    setTv_Image(e.target.files[0])
                                  }
                                  placeholder="player_image"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="row mt-3 text-start">
                          <div className="col-sm-12">
                            <label className="m-0">Live Stream URL</label>

                            <div className="">
                              <input
                                className="form-control me-2"
                                type="search"
                                placeholder="Mp4_url"
                                aria-label="Search"
                                name="mp4_url"
                                id="mp4_url"
                                // onChange={(e) => setMp4_url(e.target.value)}
                              />
                            </div>
                          </div>
                        </div> */}

                        <div className="row mt-3 text-start">
                          <div className="col-sm-12">
                            <label className="m-0">Short Description</label>
                            <p className="p1">
                              Add a short description of the Livestream below:
                            </p>
                            <div className="">
                              <textarea
                                className="form-control"
                                id="description"
                                name="description"
                                value={editUser?.description}
                                onChange={handleInput}
                                placeholder="Description"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Search Tags</label>
                            {/* <div className="">
                              <input
                                className="form-control me-2"
                                type="search"
                                placeholder="search_tags"
                                aria-label="Search"
                                name="search_tags"
                                id="search_tag"
                                onChange={(e) => setSearch_tags(e.target.value)}
                              />
                            </div> */}
                            <TagsInput
                              // value={editUser?.search_tags}
                              onChange={setSearch_tags}
                              name="search_tags"
                            />
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-12">
                            <label className="m-0">
                              Video Details, Links, and Info
                            </label>
                            <div className="">
                              {/* <textarea
                                className="form-control"
                                id="details"
                                name="details"
                                value={editUser?.details}
                                onChange={handleInput}
                                placeholder="Details"
                              /> */}

                              <JoditEditor
                                // ref={editor}
                                value={editUser?.details}
                                onChange={(e) => {
                                  handleChangeFirst(e);
                                }}
                              />

                              {/* <JoditEditor
                              
                                value={details}
                                onChange={(newContent) =>
                                  contentFieldChanaged(newContent)
                                }
                              /> */}
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Category</label>
                            <p className="p1">
                              Select A Live Stream Category Below:
                            </p>
                            <div className="">
                              <div className="text-dark">
                                <Select
                                  options={optionscategories}
                                  isMulti
                                  className="form-control"
                                  onChange={handleSelectChangecategories}
                                  value={selectedValuescategories}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <label className="m-0">Language</label>
                            <p className="p1">
                              Select A Live Stream Language Below:
                            </p>
                            <div className="">
                              <div className="text-dark">
                                <Select
                                  options={optionslanguages}
                                  isMulti
                                  className="form-control"
                                  onChange={handleSelectChangelanguages}
                                  value={selectedValueslanguages}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row text-start mt-4">
                          <div className="col-sm-6">
                            <label className="m-0">Video Ratings</label>
                            <p className="p1">
                              Live Stream Ratings 10 Out Of 10{" "}
                            </p>
                            <div className="form-control">
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                name="rating"
                                value={editUser?.rating}
                                onChange={handleInput}
                              >
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <label className="m-0">Video Year</label>
                            <p className="p1">Video Created Year</p>
                            <div className="">
                              <input
                                type="text"
                                name="year"
                                className="form-control"
                                value={editUser?.year}
                                onChange={handleInput}
                                placeholder="Set Year"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Duration</label>
                            <p className="p1">
                              Enter The Live Stream Duration In (HH : MM : SS)
                            </p>
                            <div>
                              <input
                                type="text"
                                className="form-control"
                                value={editUser?.duration}
                                onChange={handleInput}
                                placeholder="duration"
                                name="duration"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 ">
                            <label className="m-0">User Access</label>
                            <p className="p1">
                              Who Is Allowed To View This Live Stream ?
                            </p>
                            <div className="">
                              <select
                                onChange={handleInput}
                                name="access"
                                className="form-control"
                                value={editUser?.access}
                                onClick={(event) => {
                                  setLivesource(event.target.value);
                                }}
                              >
                                {user_access?.map((item, key) => (
                                  <option value={item.role}>{item.name}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div>{live_stream_source()}</div>

                        {/* <div className="row mt-3 text-start">
                          <div className="col-sm-4">
                            <label className="m-0">Publish Type</label>

                            <div className="p2">
                              <div>
                                <input
                                  className="active"
                                  type="radio"
                                  id="publish_now"
                                  name="publish_type"
                                  value="1"
                                  checked={publish_type === "1"}
                                  onChange={() => handlePublishType("1")}
                                />
                                <label> Publish Now</label>
                              </div>
                              <div>
                                <input
                                  type="radio"
                                  id="publish_later"
                                  name="publish_type"
                                  value="0"
                                  checked={publish_type === "0"}
                                  onChange={() => handlePublishType("0")}
                                />
                                <label> Publish Later </label>
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-4">
                            {publish_type === "0" && (
                              <div>
                                <label>Publish Time:</label>
                                <input
                                  type="datetime-local"
                                  id="publish_time"
                                  name="publish_time"
                                  value={editUser?.publish_time}
                                  onChange={(e) =>
                                    handlePublishTime(e.target.value)
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div> */}

                        <div className="row">
                          <div className="col-sm-6 form-group">
                            <label className="mb-2">Publish Type</label>
                            <div>
                              <input
                                type="radio"
                                id="publish_now"
                                name="publish_type"
                                onClick={(event) => {
                                  setEnable_restreampublic(event.target.value);
                                }}
                                onChange={(e) =>
                                  setPublish_type(e.target.value)
                                }
                                value="publish_now"
                              />{" "}
                              <span>Publish Now</span>
                            </div>
                            <div>
                              <input
                                type="radio"
                                id="publish_later"
                                value="publish_later"
                                name="publish_type"
                                onClick={(event) => {
                                  setEnable_restreampublic(event.target.value);
                                }}
                                onChange={(e) =>
                                  setPublish_type(e.target.value)
                                }
                              />{" "}
                              <span
                                value="publish_later"
                                name="publish_type"
                                onClick={(event) => {
                                  setEnable_restreampublic(event.target.value);
                                }}
                                onChange={(e) =>
                                  setPublish_type(e.target.value)
                                }
                              >
                                Publish Later
                              </span>{" "}
                            </div>
                          </div>

                          <div
                            className="col-sm-6 form-group"
                            id="publishlater"
                          >
                            <div
                              onClick={(event) => {
                                setEnablestreamvisiblepublic(
                                  event.target.value
                                );
                              }}
                            >
                              {proceednextpublic()}
                            </div>
                          </div>
                        </div>
                        <div className="text-end">
                          <button
                            className="btn btn-primary"
                            onClick={handleUpdate}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Edit_NewLiveVideo;
