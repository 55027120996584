import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import JoditEditor from "jodit-react";

function Edit_Pages() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
    body: "",
    active: "",
    banner: "",
    image: "",
  });

  const [image, setImage] = useState("");

  const handleChangeFirst = (e) => {
    setEditUser({ ...editUser, body: e });
  };

  // console.log(editUser)
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");
  const [Content_Partner_id, setContent_Partner_id] = useState("1");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Page-Edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.Page;
      setEditUser(res);
      // console.log(resData)
      // console.log(result)
      console.log(res);
    };
    getUser();
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.id]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.id]: 0 });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      id: editUser.id,
      user_id: editUser.user_id,
      title: editUser.title,
      slug: editUser.slug,
      body: editUser.body,
      active: editUser.active,
      banner: editUser.banner,
      Content_Partner_id: Content_Partner_id,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/CPP/Backend/Page-update/` + id,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (res.status === 200) {
      setMessage(resjson.success);
      // alert('Success')
      setTimeout(() => {
        // navigate('/admin/series-list');
      }, 2000);

      var page_id = resjson.Page_id;

      // console.log(page_id)
      // console.log(resjson)

      const formDat = new FormData();

      formDat.append("page_id", page_id);
      formDat.append("Content_Partner_id", Content_Partner_id);
      formDat.append("image", image);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/Page-Image-Upload`,
          formDat,
          {
            headers: headers,
          }
        )
        .then((response) => {
          // console.log('api', response);
          if (response.data.status === true) {
            var result = response.data;
            // console.log('result', result);
            alert("Added successfully  !");
          }
        });
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="">
                <div className="iq-card">
                  <div id="admin-container">
                    <div className="admin-section-title">
                      <h5>
                        <i className="entypo-plus"></i> Add New Page
                      </h5>
                    </div>
                    <hr />
                    <div className="row mt-4">
                      <div className="col-sm-6 col-sm-8 ">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Title</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Add the page title in the textbox below:
                            </p>
                            <input
                              type="text"
                              className="form-control"
                              name="title"
                              id="title"
                              placeholder="Page Title"
                              onChange={handleInput}
                              value={editUser?.title}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-3 col-sm-4">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>SEO URL Slug</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <p className="p1">(example. /page/slug-name)</p>
                            <input
                              type="text"
                              className="form-control"
                              name="slug"
                              id="slug"
                              placeholder="slug-name"
                              onChange={handleInput}
                              value={editUser?.slug}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=" mt-3 form-group ">
                      <label>Banner:</label> <br />
                      <img src={editUser?.image} width={150} height={150} />
                      <input
                        type="file"
                        multiple="true"
                        className="form-control"
                        name="banner"
                        id="banner"
                        onChange={(e) => setImage(e.target.files[0])}
                      />
                    </div>

                    <div className="panel panel-primary" data-collapsed="0">
                      {" "}
                      <div className="panel-heading">
                        <div className="panel-title">
                          <label>Page Content</label>
                        </div>{" "}
                      </div>
                      <div className="panel-body mt-3">
                        <JoditEditor
                          value={editUser?.body}
                          onChange={(e) => {
                            handleChangeFirst(e);
                          }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-4 mt-3">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            {" "}
                            <div className="panel-title">
                              <label> Status Settings</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <div>
                              <label for="active">Is this page Active:</label>
                              <label className="switch">
                                <input
                                  name="active"
                                  id="active"
                                  onChange={handleInput}
                                  defaultChecked={
                                    editUser?.active == 1 ? true : false
                                  }
                                  checked={editUser?.active == 1 ? true : false}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="active"
                                  id="active"
                                  onChange={handleInput}
                                  value={editUser.active == 1 ? "1" : "0"}
                                ></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-2 p-2">
                        <input
                          type="submit"
                          value="Update New Page"
                          className="btn btn-primary pull-right"
                          onClick={handleUpdate}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Edit_Pages;
