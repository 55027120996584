import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const About_MyProfile = () => {
  const [editUser, setEditUser] = useState({
    id: "",
    username: "",
    slug: "",
    email: "",
    mobile_number: "",
    description: "",
    ccode: "",
    status: "",
    user_role: "",
    bank_name: "",
    branch_name: "",
    account_number: "",
    IFSC_Code: "",
    upi_id: "",
    upi_mobile_number: "",
  });
  const [picture, setPicture] = useState("");
  const [banner, setBanner] = useState("");
  const [intro_video, setIntro_video] = useState("");

  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");
  const [Content_Partner_id, setContent_Partner_id] = useState("1");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // console.log(headers)

  useEffect(async () => {
    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/About`,
        { Content_Partner_id: Content_Partner_id },
        {
          headers: headers,
        }
      )
      .then((response) => {
        // console.log("api checks", response.data.comment_section);
        var result = response?.data?.ContentPartner;
        // setOrder(result);
        setEditUser(result);
        // console.log("result", result);
        // console.log("result", result);
        // console.log('orders', order);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      username: editUser.username,
      slug: editUser.slug,
      email: editUser.email,
      mobile_number: editUser.mobile_number,
      description: editUser.description,
      ccode: editUser.ccode,
      status: editUser.status,
      user_role: editUser.user_role,
      bank_name: editUser.bank_name,
      branch_name: editUser.branch_name,
      account_number: editUser.account_number,
      IFSC_Code: editUser.IFSC_Code,
      upi_id: editUser.upi_id,
      upi_mobile_number: editUser.upi_mobile_number,
      id: Content_Partner_id,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/CPP/Backend/About-Update`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (res.status === 200) {
      setMessage(resjson.success);
      // alert('Success')
      // window.location.reload();
      // setTimeout(() => {}, 2000);

      const formDatas = new FormData();

      formDatas.append("Content_Partner_id", Content_Partner_id);
      formDatas.append("picture", picture);
      formDatas.append("banner", banner);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/Image-upload`,
          formDatas,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api' , response);
          if (response.data.status == true) {
            var result = response.data;
            // console.log('result', response);
            // navigate('/admin/all-artist')
          }
        });

      const formDatass = new FormData();

      formDatass.append("Content_Partner_id", Content_Partner_id);
      formDatass.append("intro_video", intro_video);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/Introvideo-upload`,
          formDatass,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api' , response);
          if (response.data.status == true) {
            var result = response.data;
            // console.log('result', response);
            // navigate('/admin/all-artist')
            alert("Added successfully  !");
          }
        });
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="">
                <div className="iq-card">
                  <div id="moderator-container">
                    <div className="moderator-section-title">
                      <h4>
                        <i className="entypo-globe"></i> My Profile
                      </h4>
                    </div>
                    <div className="clear"></div>
                    <hr />

                    <form>
                      <div className="row justify-content-between p-3">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="name"
                              className=" col-form-label text-md-right"
                            >
                              User Name:
                            </label>
                            <input
                              id="username"
                              type="text"
                              className="form-control"
                              name="username"
                              value={editUser.username}
                              onChange={handleInput}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="email"
                              className=" col-form-label text-md-right"
                            >
                              Email:
                            </label>
                            <input
                              id="email"
                              type="email"
                              className="form-control "
                              name="email"
                              value={editUser.email}
                              onChange={handleInput}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="mobile_number"
                              className=" col-form-label text-md-right"
                            >
                              Phone Number:
                            </label>
                            <input
                              id="mobile_number"
                              type="number"
                              className="form-control "
                              name="mobile_number"
                              value={editUser.mobile_number}
                              onChange={handleInput}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="name"
                              className=" col-form-label text-md-right"
                            >
                              Description:
                            </label>
                            <input
                              id="name"
                              type="text"
                              className="form-control"
                              name="description"
                              value={editUser.description}
                              onChange={handleInput}
                              autofocus
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="mobile_number"
                              className=" col-form-label text-md-right"
                            >
                              UPI ID:
                            </label>
                            <input
                              id="upi_id"
                              type="number"
                              className="form-control "
                              name="upi_id"
                              value={editUser.upi_id}
                              onChange={handleInput}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="upi_mobile_number"
                              className=" col-form-label text-md-right"
                            >
                              UPI Phone Number:
                            </label>
                            <input
                              id="upi_mobile_number"
                              type="number"
                              className="form-control "
                              name="upi_mobile_number"
                              value={editUser.upi_mobile_number}
                              onChange={handleInput}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="banner"
                              className=" col-form-label text-md-right"
                            >
                              Banner :
                            </label>
                            <div>
                              <img src={editUser.banner} width={200} />
                            </div>
                            <input
                              id="banner"
                              type="file"
                              className="form-control"
                              name="banner"
                              onChange={(e) => setBanner(e.target.files[0])}
                            />
                            <p className="text" id="error_picture">
                              {" "}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="picture"
                              className=" col-form-label text-md-right"
                            >
                              Profile Picture:
                            </label>
                            <div>
                              <img src={editUser.picture} width={200} />
                            </div>
                            <input
                              id="picture"
                              type="file"
                              className="form-control"
                              name="picture"
                              onChange={(e) => setPicture(e.target.files[0])}
                            />
                            <p className="text" id="error_picture">
                              {" "}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="intro_video"
                              className=" col-form-label text-md-right"
                            >
                              Intro Video:
                            </label>
                            <div>
                              <media-player
                                width="200px"
                                title={editUser?.username}
                                src={editUser?.intro_video}
                                poster=""
                                controls
                              >
                                <media-outlet width="200px"></media-outlet>
                              </media-player>
                            </div>
                            <input
                              id="intro_video"
                              type="file"
                              className="form-control"
                              name="intro_video"
                              onChange={(e) =>
                                setIntro_video(e.target.files[0])
                              }
                            />
                            <p className="text" id="error_picture">
                              {" "}
                            </p>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-md-12 text-end">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={handleUpdate}
                            >
                              Update Profile
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default About_MyProfile;
