// import { Route, Routes } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import Layout from "./layout/layout";
import Dashboard from "./Pages/Dashboard/Dashboard";
import MainPage from "./components/MainPage/MainPage";
import Signin from "./components/Signin/Signin";
import Signup from "./components/Signup/Signup";

import Allvideo from "./Pages/Video/Video_Management/Allvideo";
import Videoforapproval from "./Pages/Video/Video_Management/Videoforapproval";
import Videochannelpartner from "./Pages/Video/Video_Management/Videochannelpartner";
import Videocontentpartner from "./Pages/Video/Video_Management/Videocontentpartner";
import Mastervideolist from "./Pages/Video/Video_Management/Mastervideolist";
import Videoschedule from "./Pages/Video/Video_Management/Videoschedule";
import AddVideoschedule from "./Pages/Video/Video_Management/AddVideoschedule";
import EditVideoschedule from "./Pages/Video/Video_Management/EditVideoschedule";
import Manageschedule from "./Pages/Video/Video_Management/Manageschedule";
import Videopartner from "./Pages/Video/Video_Management/Videopartner";
import Managevideocategories from "./Pages/Video/Video_Management/Managevideocategories";
import AddManagevideocategories from "./Pages/Video/Video_Management/AddManagevideocategories";
import EditManagevideocategories from "./Pages/Video/Video_Management/EditManagevideocategories";
import Addnewvideo from "./Pages/Video/Video_Management/Addnewvideo";
import EditVideotag from "./Pages/Video/Video_Management/EditVideotag";

import AddNewLiveStream from "./Pages/Live Stream/Management_Live_Stream/AddNewLiveStream";
import AllLiveStream from "./Pages/Live Stream/Management_Live_Stream/AllLiveStream";
import LiveStreamApproval from "./Pages/Live Stream/Management_Live_Stream/LiveStreamApproval";
import Livecontentpartner from "./Pages/Live Stream/Management_Live_Stream/Livecontentpartner";
import Livechannelpartner from "./Pages/Live Stream/Management_Live_Stream/Livechannelpartner";
import ManageLiveCategories from "./Pages/Live Stream/Management_Live_Stream/ManageLiveCategories";
import AddManageLiveCategories from "./Pages/Live Stream/Management_Live_Stream/AddManageLiveCategories";
import EditManageLiveCategories from "./Pages/Live Stream/Management_Live_Stream/EditManageLiveCategories";
import LiveEventArtist from "./Pages/Live Stream/Management_Live_Stream/LiveEventArtist";
import AddLiveEventArtist from "./Pages/Live Stream/Management_Live_Stream/AddLiveEventArtist";
import EditLiveEventArtist from "./Pages/Live Stream/Management_Live_Stream/EditLiveEventArtist";
import SeriesList from "./Pages/Video/Series&Episode/SeriesList";
import Addnewseries from "./Pages/Video/Series&Episode/Addnewseries";
import ManageSeries from "./Pages/Video/Series&Episode/ManageSeries";
import AddManageSeries from "./Pages/Video/Series&Episode/AddManageSeries";
import EditManageseriesgenre from "./Pages/Video/Series&Episode/EditManageseriesgenre";
import SeriesApproval from "./Pages/Video/Series&Episode/SeriesApproval";
import Editseason from "./Pages/Video/Series&Episode/Editseason";
import EditSeriesList from "./Pages/Video/Series&Episode/EditSeriesList";
import Editvideomanage from "./Pages/Video/Series&Episode/Editvideomanage";
import EditEpisodemanage from "./Pages/Video/Series&Episode/EditEpisodemanage";
import EditEpisodeVideo from "./Pages/Video/Series&Episode/EditEpisodeVideo";
import Addepisode from "./Pages/Video/Series&Episode/Addepisode";
import Manageepisode from "./Pages/Video/Series&Episode/Manageepisode";
import AddSeason from "./Pages/Video/Series&Episode/AddSeason";
import SeriesContentPartner from "./Pages/Video/Series&Episode/SeriesContentPartner";
import SeriesChannelPartner from "./Pages/Video/Series&Episode/SeriesChannelPartner";

import AudioList from "./Pages/Audio/Audio_Management/AudioList";
import Addnewaudio from "./Pages/Audio/Audio_Management/Addnewaudio";
import AudioApproval from "./Pages/Audio/Audio_Management/AudioApproval";
import Audiocontentpartner from "./Pages/Audio/Audio_Management/Audiocontentpartner";
import Audiochannelpartner from "./Pages/Audio/Audio_Management/Audiochannelpartner";
import Editnewaudio from "./Pages/Audio/Audio_Management/Editnewaudio";
import Manageaudiocategories from "./Pages/Audio/Audio_Management/Manageaudiocategories";
import Managealbum from "./Pages/Audio/Audio_Management/Managealbum";
import AllArtist from "./Pages/Audio/Artist_Management/AllArtist";
import NewArtist from "./Pages/Audio/Artist_Management/NewArtist";
import EditNewArtist from "./Pages/Audio/Artist_Management/EditNewArtist";
import Addcategory from "./Pages/Audio/Audio_Management/Addcategory";
import Editcategory from "./Pages/Audio/Audio_Management/Editcategory";
import Addalbum from "./Pages/Audio/Audio_Management/Addalbum";
import Editalbum from "./Pages/Audio/Audio_Management/Editalbum";

import AllUser from "./Pages/Account/User/AllUser";
import AddUser from "./Pages/Account/User/AddUser";
import EditUser from "./Pages/Account/User/EditUser";
import ImportUser from "./Pages/Account/User/ImportUser";
import MultiUserManagement from "./Pages/Account/User/MultiUserManagement";
import Menu from "./Pages/Account/Menu/Menu";
import AddMenu from "./Pages/Account/Menu/AddMenu";
import EditMenu from "./Pages/Account/Menu/EditMenu";
import FooterMenu from "./Pages/Account/Menu/FooterMenu";
import AddFooterMenu from "./Pages/Account/Menu/AddFooterMenu";
import EditFooterMenu from "./Pages/Account/Menu/EditFooterMenu";
import MobileMenu from "./Pages/Account/Menu/MobileMenu";
import AddMobileMenu from "./Pages/Account/Menu/AddMobileMenu";
import EditMobileMenu from "./Pages/Account/Menu/EditMobileMenu";
import SignupMenu from "./Pages/Account/SignupMenu/SignupMenu";
import FileManager from "./Pages/Account/FileManager/FileManager";
import Slider from "./Pages/Language/Slider/Slider";
import AddSlider from "./Pages/Language/Slider/AddSlider";
import EditSlider from "./Pages/Language/Slider/EditSlider";
import VideoLanguage from "./Pages/Language/Manage_Language/VideoLanguage";
import SubtitleLanguage from "./Pages/Language/Manage_Language/SubtitleLanguage";
import EditVideoLanguage from "./Pages/Language/Manage_Language/EditVideoLanguage";
import ManageTranslation from "./Pages/Language/Manage_Language/ManageTranslation";
import ManageTranslationLanguage from "./Pages/Language/Manage_Language/ManageTranslationLanguage";
import EditSubtitleLanguage from "./Pages/Language/Manage_Language/EditSubtitleLanguage";

import Player from "./Pages/Site/Player_UI/Player";

import AddContentPartner from "./Pages/Site/Content_Partner/AddContentPartner";
import ViewContentPartner from "./Pages/Site/Content_Partner/ViewContentPartner";
import EditContentPartner from "./Pages/Site/Content_Partner/EditContentPartner";
import ContentPartnerApproval from "./Pages/Site/Content_Partner/ContentPartnerApproval";
import ContentAddRole from "./Pages/Site/Content_Partner/ContentAddRole";
import ContentPartnerView from "./Pages/Site/Content_Partner/ContentPartnerView";
import EditContentPartnerView from "./Pages/Site/Content_Partner/EditContentPartnerView";
import ContentCommission from "./Pages/Site/Content_Partner/ContentCommission";
import ContentPayout from "./Pages/Site/Content_Partner/ContentPayout";

import ChannelPartnerApproval from "./Pages/Site/Channel_Partner/ChannelPartnerApproval";
import ChannelCommission from "./Pages/Site/Channel_Partner/ChannelCommission";
import ChannelPayout from "./Pages/Site/Channel_Partner/ChannelPayout";
import ChannelPackage from "./Pages/Site/Channel_Partner/ChannelPackage";
import AddChannelPackage from "./Pages/Site/Channel_Partner/AddChannelPackage";
import AllPages from "./Pages/Site/Site_Pages/AllPages";
import AddPages from "./Pages/Site/Site_Pages/AddPages";
import EditPages from "./Pages/Site/Site_Pages/EditPages";
import CreateLandingPages from "./Pages/Site/Site_Pages/CreateLandingPages";
import LandingPages from "./Pages/Site/Site_Pages/LandingPages";
import AddLandingPages from "./Pages/Site/Site_Pages/AddLandingPages";
import EditLandingPages from "./Pages/Site/Site_Pages/EditLandingPages";
import SubscriptionPlan from "./Pages/Site/Plans/SubscriptionPlan";
import AddSubscriptionPlan from "./Pages/Site/Plans/AddSubscriptionPlan";
import EditSubscriptionPlan from "./Pages/Site/Plans/EditSubscriptionPlan";
import PurchasePlan from "./Pages/Site/Plans/PurchasePlan";
import EditPurchasePlan from "./Pages/Site/Plans/EditPurchasePlan";
import LifeTimeSubcription from "./Pages/Site/Plans/LifeTimeSubcription";
import Devices from "./Pages/Site/Plans/Devices";
import EditDevices from "./Pages/Site/Plans/EditDevices";
import TotalRevenue from "./Pages/Site/Payment_Management/TotalRevenue";
import SubcriptionPayment from "./Pages/Site/Payment_Management/SubcriptionPayment";
import ViewSubcription from "./Pages/Site/Payment_Management/ViewSubcription";
import PayperviewPayments from "./Pages/Site/Payment_Management/PayperviewPayments";
import ViewPayperview from "./Pages/Site/Payment_Management/ViewPayperview";
import UsersRevenue from "./Pages/Site/Analytics/UsersRevenue";
import UsersAnalytics from "./Pages/Site/Analytics/UsersAnalytics";
import PurchaseVideoAnalytics from "./Pages/Site/Analytics/PurchaseVideoAnalytics";
import CPPAnalytics from "./Pages/Site/Analytics/CPPAnalytics";
import CPPVideoAnalytics from "./Pages/Site/Analytics/CPPVideoAnalytics";
import CPPRevenue from "./Pages/Site/Analytics/CPPRevenue";
import ViewRegion from "./Pages/Site/Analytics/ViewRegion";
import RevenueRegion from "./Pages/Site/Analytics/RevenueRegion";
import PlayerVideoAnalytics from "./Pages/Site/Analytics/PlayerVideoAnalytics";
import RegionVideoAnalytics from "./Pages/Site/Analytics/RegionVideoAnalytics";
import PlayerUserAnalytics from "./Pages/Site/Analytics/PlayerUserAnalytics";
import CPPLiveVideo from "./Pages/Site/Analytics/CPPLiveVideo";
import LiveStreamAnalytics from "./Pages/Site/Analytics/LiveStreamAnalytics";
import StorefrontSettings from "./Pages/Site/Settings/StorefrontSettings";
import HomePageSettings from "./Pages/Site/Settings/HomePageSettings";
import Editorderhome from "./Pages/Site/Settings/Editorderhome";
import LinkSettings from "./Pages/Site/Settings/LinkSettings";
import ManageAge from "./Pages/Site/Settings/ManageAge";
import AddManageAge from "./Pages/Site/Settings/AddManageAge";
import EditManageAge from "./Pages/Site/Settings/EditManageAge";
import ThemeSettings from "./Pages/Site/Settings/ThemeSettings";
import EmailSettings from "./Pages/Site/Settings/EmailSettings";
import PaymentSettings from "./Pages/Site/Settings/PaymentSettings";
import EmailSettingsTemplate from "./Pages/Site/Settings/EmailSettingsTemplate";
import StorageSettings from "./Pages/Site/Settings/StorageSettings";
import MobileSettings from "./Pages/Site/Settings/MobileSettings";
import EditSplashMobileSettings from "./Pages/Site/Settings/EditSplashMobileSettings";
import EditMobileSettings from "./Pages/Site/Settings/EditMobileSettings";
import SocialLoginSettings from "./Pages/Site/Settings/SocialLoginSettings";
import CurrencySettings from "./Pages/Site/Settings/CurrencySettings";
import RevenueSettings from "./Pages/Site/Settings/RevenueSettings";
import ThumbnailSettings from "./Pages/Site/Settings/ThumbnailSettings";
import ProfileScreen from "./Pages/Site/Settings/ProfileScreen";
import ViewChannelPartner from "./Pages/Site/Channel_Partner/ViewChannelPartner";
import Theme from "./Pages/Site/Settings/Theme";
import ImageSettings from "./Pages/Site/Settings/ImageSettings";
import PopupSettings from "./Pages/Site/Settings/PopupSettings";
import CommentSetting from "./Pages/Site/Settings/CommentSetting";
import Advertiser from "./Pages/Ads_Categories/Manage_Advertiser/Advertiser";
import EditAdvertiser from "./Pages/Ads_Categories/Manage_Advertiser/EditAdvertiser";
import EditAdsList from "./Pages/Ads_Categories/Ads_List/EditAdsList";
import AdsCategories from "./Pages/Ads_Categories/Ads_Categories/AdsCategories";
import EditAdsCategories from "./Pages/Ads_Categories/Ads_Categories/EditAdsCategories";
import AdsList from "./Pages/Ads_Categories/Ads_List/AdsList";
import AdsPlan from "./Pages/Ads_Categories/Ads_Plan/AdsPlan";
import AdsRevenue from "./Pages/Ads_Categories/Ads_Revenue/AdsRevenue";
import CalenderEvent from "./Pages/Ads_Categories/Calender_Event/CalenderEvent";
import AdsCampaigns from "./Pages/Ads_Categories/Ads_Campaigns/AdsCampaigns";
import AdsTimeSlot from "./Pages/Ads_Categories/Ads_Time_Slot/AdsTimeSlot";
import ManageGeoFencing from "./Pages/Geo_Fencing/Manage_Geo_Fencing/ManageGeoFencing";
import ManageCountry from "./Pages/Geo_Fencing/Manage_Country/ManageCountry";
import CacheManagement from "./Pages/Configuration/Cache_Management/CacheManagement";
import Debug from "./Pages/Configuration/Manage_Geo_Fencing/Debug";
import ContactRequest from "./Pages/ContactUs/Cache Management/ContactRequest";
import SeeLogActivity from "./Pages/LogActivity/Cache_Management/SeeLogActivity";
import Editvideo from "./Pages/Video/Video_Management/Editvideo";
import EditLiveStream from "./Pages/Live Stream/Management_Live_Stream/EditLiveStream";
import EditAddNewLiveStream from "./Pages/Live Stream/Management_Live_Stream/EditAddNewLiveStream";
import UpdateCurrencySettings from "./Pages/Site/Settings/UpdateCurrencySettings";
import ViewTemplate from "./Pages/Site/Settings/ViewTemplate";

import AllVideos from "./Channel_Partner/VideoManagement/AllVideos";
import AddNewVideo from "./Channel_Partner/VideoManagement/AddNewVideo";
import Edit_Manage_VideoCategory from "./Content_Partner/VideoManagement/Edit_Manage_VideoCategory";
import ManageVideoCategories from "./Channel_Partner/VideoManagement/ManageVideoCategories";
import Payouts from "./Channel_Partner/Analytics/Payouts";
import VideoAnalytics from "./Channel_Partner/Analytics/VideoAnalytics";
import VideoViewRegion from "./Channel_Partner/Analytics/VideoViewRegion";
import MyChannelSettings from "./Channel_Partner/SettingManage/MyChannelSettings";
import AllLiveVideo from "./Channel_Partner/ManageLiveVideo/AllLiveVideo";
import AddNewLiveVideo from "./Channel_Partner/ManageLiveVideo/AddNewLiveVideo";
import ManageLiveVideoCategories from "./Channel_Partner/ManageLiveVideo/ManageLiveVideoCategories";
import AddManageCategories from "./Channel_Partner/ManageLiveVideo/AddManageCategories";
import ManageLiveEvent from "./Channel_Partner/ManageLiveVideo/ManageLiveEvent";
import AddManageLiveEvent from "./Channel_Partner/ManageLiveVideo/AddManageLiveEvent";
import AudioLists from "./Channel_Partner/AudioManagement/AudioLists";
import ManageAudioCategories from "./Channel_Partner/AudioManagement/ManageAudioCategories";
import AddCategory from "./Channel_Partner/AudioManagement/AddCategory";
import ManageAlbums from "./Channel_Partner/AudioManagement/ManageAlbums";
import Addalbums from "./Channel_Partner/AudioManagement/Addalbums";
import SeriesLists from "./Channel_Partner/Series&Episode/SeriesLists";
import AddNewSeries from "./Channel_Partner/Series&Episode/AddNewSeries";
import AddManageVideoCategory from "./Channel_Partner/VideoManagement/AddManageVideoCategory";
import AddnewAudios from "./Channel_Partner/AudioManagement/AddnewAudios";
import ChannelPage from "./Channel_Partner/ChannelPage";
import DashBoards from "./Channel_Partner/DashBoard/DashBoards";
import All_Videos from "./Content_Partner/VideoManagement/All_Videos";
import Add_NewVideos from "./Content_Partner/VideoManagement/Add_NewVideos";
import Edit_New_Video from "./Content_Partner/VideoManagement/Edit_New_Video";
import Edit_Video from "./Content_Partner/VideoManagement/Edit_Video";
import Manage_VideoCategories from "./Content_Partner/VideoManagement/Manage_VideoCategories";
import AddManage_VideoCategories from "./Content_Partner/VideoManagement/AddManage_VideoCategories";
import Audio_Lists from "./Content_Partner/AudioManagement/Audio_Lists";
import Add_NewAudios from "./Content_Partner/AudioManagement/Add_NewAudios";
import Manage_AudioCategories from "./Content_Partner/AudioManagement/Manage_AudioCategories";
import Manage_Albums from "./Content_Partner/AudioManagement/Manage_Albums";
import Add_category from "./Content_Partner/AudioManagement/Add_category";
import Add_Albums from "./Content_Partner/AudioManagement/Add_Albums";
import All_LiveVideo from "./Content_Partner/ManageLiveVideo/All_LiveVideo";
import Add_NewLiveVideo from "./Content_Partner/ManageLiveVideo/Add_NewLiveVideo";
import Manage_LiveCategory from "./Content_Partner/ManageLiveVideo/Manage_LiveCategory";
import Add_manageLiveCategory from "./Content_Partner/ManageLiveVideo/Add_manageLiveCategory";
import Manage_LiveEvent from "./Content_Partner/ManageLiveVideo/Manage_LiveEvent";
import Add_ManageLiveEvent from "./Content_Partner/ManageLiveVideo/Add_ManageLiveEvent";
import Video_Analytics from "./Content_Partner/Analytics/Video_Analytics";
import Video_ViewRegion from "./Content_Partner/Analytics/Video_ViewRegion";
import Video_Payout from "./Content_Partner/Analytics/Video_Payout";
import Live_StreamPayout from "./Content_Partner/Analytics/Live_StreamPayout";
import All_pages from "./Content_Partner/Pages/All_pages";
import Add_pages from "./Content_Partner/Pages/Add_pages";
import Dash_Board from "./Content_Partner/Dash_Board/Dash_Board";
import About_MyProfile from "./Content_Partner/Profile/About_MyProfile";
import Edit_Manage_LiveEvent from "./Content_Partner/ManageLiveVideo/Edit_Manage_LiveEvent";
import Edit_Manage_Categories from "./Content_Partner/ManageLiveVideo/Edit_Manage_Categories";
import Edit_NewLiveVideo from "./Content_Partner/ManageLiveVideo/Edit_NewLiveVideo";
import Edit_AudioAlbum from "./Content_Partner/AudioManagement/Edit_AudioAlbum";
import Edit_Category from "./Content_Partner/AudioManagement/Edit_Category";
import Edit_newaudio from "./Content_Partner/AudioManagement/Edit_newaudio";
import Edit_SingleAudio from "./Content_Partner/AudioManagement/Edit_SingleAudio";
import Series_Lists from "./Content_Partner/Series&Episode/Series_Lists";
import Add_New_Series from "./Content_Partner/Series&Episode/Add_New_Series";
import Edit_EpisodeManage from "./Content_Partner/Series&Episode/Edit_EpisodeManage";
import Edit_Episodevideo from "./Content_Partner/Series&Episode/Edit_Episodevideo";
import Edit_New_Series from "./Content_Partner/Series&Episode/Edit_New_Series";
import Edit_seriesseason from "./Content_Partner/Series&Episode/Edit_seriesseason";
import Manage_Episodeseries from "./Content_Partner/Series&Episode/Manage_Episodeseries";
import Manage_Series from "./Content_Partner/Series&Episode/Manage_Series";
import Add_Manage_Series from "./Content_Partner/Series&Episode/Add_Manage_Series";
import Edit_Manageseries_genre from "./Content_Partner/Series&Episode/Edit_Manageseries_genre";

import Error404 from "./Error404/error404";
import Maintances from "./Error404/Maintances";
import Edit_Pages from "./Content_Partner/Pages/Edit_Pages";

function AllRoutes(props) {
  const [loginData, setLoginData] = useState(null);
  useEffect(() => {
    const userData = localStorage.getItem("access_token");
    setLoginData(userData);
  }, []);
  // console.log(loginData, "LoginData");

  return (
    <>
      {loginData === null ? (
        <Router>
          <Routes>
            <Route path="/admin" element={<MainPage></MainPage>}></Route>
            <Route path="/" element={<Signin />}></Route>
            <Route path="/signup" element={<Signup />}></Route>
            <Route path="*" element={<Error404></Error404>}></Route>
            <Route
              path="/maintances"
              element={<Maintances></Maintances>}
            ></Route>
          </Routes>
        </Router>
      ) : (
        <Router>
          <Layout>
            <Routes>
              <Route path="*" element={<Error404></Error404>}></Route>

              {/* ChannelPartner */}
              <Route
                path="dashboard"
                element={<DashBoards></DashBoards>}
              ></Route>
              <Route path="allvideos" element={<AllVideos></AllVideos>}></Route>
              <Route
                path="add-new-videos"
                element={<AddNewVideo></AddNewVideo>}
              ></Route>
              <Route
                path="video-categories"
                element={<ManageVideoCategories></ManageVideoCategories>}
              ></Route>
              <Route
                path="add-video-category"
                element={<AddManageVideoCategory></AddManageVideoCategory>}
              ></Route>
              <Route
                path="series-lists"
                element={<SeriesLists></SeriesLists>}
              ></Route>
              <Route
                path="add-new-series"
                element={<AddNewSeries></AddNewSeries>}
              ></Route>
              <Route
                path="audio-lists"
                element={<AudioLists></AudioLists>}
              ></Route>
              <Route
                path="add-new-audio"
                element={<AddnewAudios></AddnewAudios>}
              ></Route>
              <Route
                path="manage-audio-category"
                element={<ManageAudioCategories></ManageAudioCategories>}
              ></Route>
              <Route
                path="add-category"
                element={<AddCategory></AddCategory>}
              ></Route>
              <Route
                path="manage-albums"
                element={<ManageAlbums></ManageAlbums>}
              ></Route>
              <Route
                path="add-albums"
                element={<Addalbums></Addalbums>}
              ></Route>
              <Route
                path="all-livevideos"
                element={<AllLiveVideo></AllLiveVideo>}
              ></Route>
              <Route
                path="add-newlive-video"
                element={<AddNewLiveVideo></AddNewLiveVideo>}
              ></Route>
              <Route
                path="manage-live-category"
                element={
                  <ManageLiveVideoCategories></ManageLiveVideoCategories>
                }
              ></Route>
              <Route
                path="add-manage-live-category"
                element={<AddManageCategories></AddManageCategories>}
              ></Route>
              <Route
                path="manage-live-event"
                element={<ManageLiveEvent></ManageLiveEvent>}
              ></Route>
              <Route
                path="add-manage-live-event"
                element={<AddManageLiveEvent></AddManageLiveEvent>}
              ></Route>
              <Route
                path="mychannel-settings"
                element={<MyChannelSettings></MyChannelSettings>}
              ></Route>
              <Route
                path="video-analytics"
                element={<VideoAnalytics></VideoAnalytics>}
              ></Route>
              <Route
                path="video-region"
                element={<VideoViewRegion></VideoViewRegion>}
              ></Route>
              <Route path="payouts" element={<Payouts></Payouts>}></Route>

              {/* ContentPartner */}
              <Route
                path="/cpp/home"
                element={<Dash_Board></Dash_Board>}
              ></Route>
              <Route
                path="/cpp/all-videos"
                element={<All_Videos></All_Videos>}
              ></Route>
              <Route
                path="/cpp/add-newvideos"
                element={<Add_NewVideos></Add_NewVideos>}
              ></Route>
              <Route
                path="/cpp/editnewvideo/:id"
                element={<Edit_New_Video></Edit_New_Video>}
              ></Route>
              <Route
                path="/cpp/editvideo/:id"
                element={<Edit_Video></Edit_Video>}
              ></Route>
              <Route
                path="/cpp/manage-video-category"
                element={<Manage_VideoCategories></Manage_VideoCategories>}
              ></Route>
              <Route
                path="/cpp/edit-categories/:id"
                element={
                  <Edit_Manage_VideoCategory></Edit_Manage_VideoCategory>
                }
              ></Route>
              <Route
                path="/cpp/add-manage-video-category"
                element={
                  <AddManage_VideoCategories></AddManage_VideoCategories>
                }
              ></Route>
              <Route
                path="/cpp/series-lists"
                element={<Series_Lists></Series_Lists>}
              ></Route>
              <Route
                path="/cpp/add-new-series"
                element={<Add_New_Series></Add_New_Series>}
              ></Route>
              <Route
                path="/cpp/manage-series-genre"
                element={<Manage_Series></Manage_Series>}
              ></Route>
              <Route
                path="/cpp/add-series-genre"
                element={<Add_Manage_Series></Add_Manage_Series>}
              ></Route>
              <Route
                path="/cpp/edit-series-genre/:id"
                element={<Edit_Manageseries_genre></Edit_Manageseries_genre>}
              ></Route>
              <Route
                path="/cpp/edit-episode/manage/:id"
                element={<Edit_EpisodeManage></Edit_EpisodeManage>}
              ></Route>
              <Route
                path="/cpp/edit-episode/video-manage/:id"
                element={<Edit_Episodevideo></Edit_Episodevideo>}
              ></Route>
              <Route
                path="/cpp/edit-series/:id"
                element={<Edit_New_Series></Edit_New_Series>}
              ></Route>
              <Route
                path="/cpp/edit-season/:id"
                element={<Edit_seriesseason></Edit_seriesseason>}
              ></Route>
              <Route
                path="/cpp/manage-episode/:seriesid/:id"
                element={<Manage_Episodeseries></Manage_Episodeseries>}
              ></Route>
              <Route
                path="/cpp/audio-lists"
                element={<Audio_Lists></Audio_Lists>}
              ></Route>
              <Route
                path="/cpp/add-newaudio-lists"
                element={<Add_NewAudios></Add_NewAudios>}
              ></Route>
              <Route
                path="/cpp/manage-audio-category"
                element={<Manage_AudioCategories></Manage_AudioCategories>}
              ></Route>
              <Route
                path="/cpp/add-category"
                element={<Add_category></Add_category>}
              ></Route>
              <Route
                path="/cpp/manage-albums"
                element={<Manage_Albums></Manage_Albums>}
              ></Route>
              <Route
                path="/cpp/edit-audio/:id"
                element={<Edit_newaudio></Edit_newaudio>}
              ></Route>
              <Route
                path="/cpp/audio/editaudio/:id"
                element={<Edit_SingleAudio></Edit_SingleAudio>}
              ></Route>
              <Route
                path="/cpp/edit-albums/:id"
                element={<Edit_AudioAlbum></Edit_AudioAlbum>}
              ></Route>

              <Route
                path="/cpp/edit-category/:id"
                element={<Edit_Category></Edit_Category>}
              ></Route>
              <Route
                path="/cpp/add-manage-albums"
                element={<Add_Albums></Add_Albums>}
              ></Route>
              <Route
                path="/cpp/all-livevideo"
                element={<All_LiveVideo></All_LiveVideo>}
              ></Route>
              <Route
                path="/cpp/edit-livestream/:id"
                element={<Edit_NewLiveVideo></Edit_NewLiveVideo>}
              ></Route>
              <Route
                path="/cpp/edit-category/:id"
                element={<Edit_Manage_Categories></Edit_Manage_Categories>}
              ></Route>
              <Route
                path="/cpp/edit-live-event-artist/:id"
                element={<Edit_Manage_LiveEvent></Edit_Manage_LiveEvent>}
              ></Route>
              <Route
                path="/cpp/add-new-livevideo"
                element={<Add_NewLiveVideo></Add_NewLiveVideo>}
              ></Route>
              <Route
                path="/cpp/manage-live-videocategory"
                element={<Manage_LiveCategory></Manage_LiveCategory>}
              ></Route>
              <Route
                path="/cpp/add-manage-live-category"
                element={<Add_manageLiveCategory></Add_manageLiveCategory>}
              ></Route>
              <Route
                path="/cpp/manage-live-event"
                element={<Manage_LiveEvent></Manage_LiveEvent>}
              ></Route>
              <Route
                path="/cpp/add-manage-live-event"
                element={<Add_ManageLiveEvent></Add_ManageLiveEvent>}
              ></Route>
              <Route
                path="/cpp/video-analytics"
                element={<Video_Analytics></Video_Analytics>}
              ></Route>
              <Route
                path="/cpp/video-view-region"
                element={<Video_ViewRegion></Video_ViewRegion>}
              ></Route>
              <Route
                path="/cpp/video-payout"
                element={<Video_Payout></Video_Payout>}
              ></Route>
              <Route
                path="/cpp/live-stream-payout"
                element={<Live_StreamPayout></Live_StreamPayout>}
              ></Route>
              <Route
                path="/cpp/all-pages"
                element={<All_pages></All_pages>}
              ></Route>
              <Route
                path="/cpp/add-pages"
                element={<Add_pages></Add_pages>}
              ></Route>
              <Route
                path="/cpp/editpages/:id"
                element={<Edit_Pages></Edit_Pages>}
              ></Route>
              <Route
                path="/cpp/about-myprofile"
                element={<About_MyProfile></About_MyProfile>}
              ></Route>

              {/* video */}
              {/* <Route path="" element={<Dashboard></Dashboard>}></Route> */}
              <Route path="allvideo" element={<Allvideo></Allvideo>}></Route>
              <Route
                path="addnewvideo"
                element={<Addnewvideo></Addnewvideo>}
              ></Route>
              <Route
                path="videos/editvideo/:id"
                element={<EditVideotag></EditVideotag>}
              ></Route>
              <Route
                path="editvideo/:id"
                element={<Editvideo></Editvideo>}
              ></Route>
              <Route
                path="video-for-approval"
                element={<Videoforapproval></Videoforapproval>}
              ></Route>
              <Route
                path="video-channel-partner"
                element={<Videochannelpartner></Videochannelpartner>}
              ></Route>
              <Route
                path="video-content-partner"
                element={<Videocontentpartner></Videocontentpartner>}
              ></Route>
              <Route
                path="master-list"
                element={<Mastervideolist></Mastervideolist>}
              ></Route>
              <Route
                path="video-schedule"
                element={<Videoschedule></Videoschedule>}
              ></Route>
              <Route
                path="add-schedule"
                element={<AddVideoschedule></AddVideoschedule>}
              ></Route>
              <Route
                path="edit-schedule/:id"
                element={<EditVideoschedule></EditVideoschedule>}
              ></Route>
              <Route
                path="manage-schedule/:id"
                element={<Manageschedule></Manageschedule>}
              ></Route>
              <Route
                path="video-partner"
                element={<Videopartner></Videopartner>}
              ></Route>
              <Route
                path="video-categories"
                element={<Managevideocategories></Managevideocategories>}
              ></Route>
              <Route
                path="add-categories"
                element={<AddManagevideocategories></AddManagevideocategories>}
              ></Route>
              <Route
                path="edit-categories/:id"
                element={
                  <EditManagevideocategories></EditManagevideocategories>
                }
              ></Route>
              <Route
                path="series-list"
                element={<SeriesList></SeriesList>}
              ></Route>
              <Route
                path="add-series"
                element={<Addnewseries></Addnewseries>}
              ></Route>
              <Route
                path="edit-series/:id"
                element={<EditSeriesList></EditSeriesList>}
              ></Route>
              <Route
                path="edit-video/manage"
                element={<Editvideomanage></Editvideomanage>}
              ></Route>
              <Route
                path="edit-episode/manage/:id"
                element={<EditEpisodemanage></EditEpisodemanage>}
              ></Route>
              <Route
                path="edit-episode/video-manage/:id"
                element={<EditEpisodeVideo></EditEpisodeVideo>}
              ></Route>
              <Route
                path="add-episode"
                element={<Addepisode></Addepisode>}
              ></Route>
              <Route
                path="manage-episode/:seriesid/:id"
                element={<Manageepisode></Manageepisode>}
              ></Route>
              <Route
                path="edit-season/:id"
                element={<Editseason></Editseason>}
              ></Route>

              <Route
                path="Series-Genre"
                element={<ManageSeries></ManageSeries>}
              ></Route>
              <Route
                path="add-Genre"
                element={<AddManageSeries></AddManageSeries>}
              ></Route>
              <Route
                path="edit-genre/:id"
                element={<EditManageseriesgenre></EditManageseriesgenre>}
              ></Route>
              <Route
                path="Add-season"
                element={<AddSeason></AddSeason>}
              ></Route>
              <Route
                path="CPPSeriesIndex"
                element={<SeriesApproval></SeriesApproval>}
              ></Route>
              <Route
                path="series-content-partner"
                element={<SeriesContentPartner></SeriesContentPartner>}
              ></Route>
              <Route
                path="series-channel-partner"
                element={<SeriesChannelPartner></SeriesChannelPartner>}
              ></Route>
              {/* Live stream */}
              <Route
                path="livestream"
                element={<AllLiveStream></AllLiveStream>}
              ></Route>
              <Route
                path="add-livestream"
                element={<AddNewLiveStream></AddNewLiveStream>}
              ></Route>
              <Route
                path="CPPLiveVideosIndex"
                element={<LiveStreamApproval></LiveStreamApproval>}
              ></Route>
              <Route
                path="live-content-partner"
                element={<Livecontentpartner></Livecontentpartner>}
              ></Route>
              <Route
                path="live-channel-partner"
                element={<Livechannelpartner></Livechannelpartner>}
              ></Route>
              <Route
                path="livestream-categories"
                element={<ManageLiveCategories></ManageLiveCategories>}
              ></Route>
              <Route
                path="livestream-add-categories"
                element={<AddManageLiveCategories></AddManageLiveCategories>}
              ></Route>
              <Route
                path="livestream-edit-categories/:id"
                element={<EditManageLiveCategories></EditManageLiveCategories>}
              ></Route>
              <Route
                path="live-event-artist"
                element={<LiveEventArtist></LiveEventArtist>}
              ></Route>
              <Route
                path="add-live-event-artist"
                element={<AddLiveEventArtist></AddLiveEventArtist>}
              ></Route>
              <Route
                path="live-event-artist-edit/:id"
                element={<EditLiveEventArtist></EditLiveEventArtist>}
              ></Route>
              <Route
                path="editlivestream/:id"
                element={<EditLiveStream></EditLiveStream>}
              ></Route>
              <Route
                path="edit/add-livestream/:id"
                element={<EditAddNewLiveStream></EditAddNewLiveStream>}
              ></Route>
              {/* Audio */}
              <Route
                path="audio-list"
                element={<AudioList></AudioList>}
              ></Route>
              <Route
                path="edit-audio/:id"
                element={<Editnewaudio></Editnewaudio>}
              ></Route>
              <Route
                path="addnewaudio"
                element={<Addnewaudio></Addnewaudio>}
              ></Route>
              <Route
                path="audio-approval"
                element={<AudioApproval></AudioApproval>}
              ></Route>
              <Route
                path="audio-content-partner"
                element={<Audiocontentpartner></Audiocontentpartner>}
              ></Route>
              <Route
                path="audio-channel-partner"
                element={<Audiochannelpartner></Audiochannelpartner>}
              ></Route>
              <Route
                path="add-audio-categories"
                element={<Addcategory></Addcategory>}
              ></Route>
              <Route
                path="edit-audio-categories/:id"
                element={<Editcategory></Editcategory>}
              ></Route>
              <Route
                path="audio-categories"
                element={<Manageaudiocategories></Manageaudiocategories>}
              ></Route>
              <Route
                path="manage-albums"
                element={<Managealbum></Managealbum>}
              ></Route>
              <Route path="add-albums" element={<Addalbum></Addalbum>}></Route>
              <Route
                path="edit-albums/:id"
                element={<Editalbum></Editalbum>}
              ></Route>
              <Route
                path="all-artist"
                element={<AllArtist></AllArtist>}
              ></Route>
              <Route
                path="new-artist"
                element={<NewArtist></NewArtist>}
              ></Route>
              <Route
                path="edit-artist/:id"
                element={<EditNewArtist></EditNewArtist>}
              ></Route>
              {/* Accounts */}
              <Route path="users" element={<AllUser></AllUser>}></Route>
              <Route path="add-users" element={<AddUser></AddUser>}></Route>
              <Route
                path="edit-users/:id"
                element={<EditUser></EditUser>}
              ></Route>
              <Route
                path="import-users-view"
                element={<ImportUser></ImportUser>}
              ></Route>
              <Route
                path="MultiUser-limit"
                element={<MultiUserManagement></MultiUserManagement>}
              ></Route>
              <Route path="menu" element={<Menu></Menu>}></Route>
              <Route path="addmenu" element={<AddMenu></AddMenu>}></Route>
              <Route
                path="editmenu/:id"
                element={<EditMenu></EditMenu>}
              ></Route>
              <Route
                path="footermenu"
                element={<FooterMenu></FooterMenu>}
              ></Route>
              <Route
                path="addfootermenu"
                element={<AddFooterMenu></AddFooterMenu>}
              ></Route>
              <Route
                path="editfootermenu/:id"
                element={<EditFooterMenu></EditFooterMenu>}
              ></Route>
              <Route
                path="mobilemenu"
                element={<MobileMenu></MobileMenu>}
              ></Route>
              <Route
                path="addmobilemenu"
                element={<AddMobileMenu></AddMobileMenu>}
              ></Route>
              <Route
                path="editmobilemenu/:id"
                element={<EditMobileMenu></EditMobileMenu>}
              ></Route>
              <Route path="signup" element={<SignupMenu></SignupMenu>}></Route>
              <Route
                path="filemanager"
                element={<FileManager></FileManager>}
              ></Route>
              {/* Languages */}
              <Route
                path="admin-languages"
                element={<VideoLanguage></VideoLanguage>}
              ></Route>
              <Route
                path="admin-editlanguages/:id"
                element={<EditVideoLanguage></EditVideoLanguage>}
              ></Route>
              <Route
                path="languages"
                element={<ManageTranslation></ManageTranslation>}
              ></Route>
              <Route
                path="subtitlelanguage"
                element={<SubtitleLanguage></SubtitleLanguage>}
              ></Route>
              <Route
                path="edit-subtitlelanguage/:id"
                element={<EditSubtitleLanguage></EditSubtitleLanguage>}
              ></Route>
              <Route
                path="admin-languages-transulates"
                element={
                  <ManageTranslationLanguage></ManageTranslationLanguage>
                }
              ></Route>
              <Route path="sliders" element={<Slider></Slider>}></Route>
              <Route path="addslider" element={<AddSlider></AddSlider>}></Route>
              <Route
                path="editslider/:id"
                element={<EditSlider></EditSlider>}
              ></Route>
              {/* Site */}
              <Route path="players" element={<Player></Player>}></Route>
              <Route
                path="moderator"
                element={<AddContentPartner></AddContentPartner>}
              ></Route>
              <Route
                path="allmoderator"
                element={<ViewContentPartner></ViewContentPartner>}
              ></Route>
              <Route
                path="edit-allmoderator/:id"
                element={<EditContentPartner></EditContentPartner>}
              ></Route>
              <Route
                path="allmoderator-approval"
                element={<ContentPartnerApproval></ContentPartnerApproval>}
              ></Route>
              <Route
                path="moderator/role"
                element={<ContentAddRole></ContentAddRole>}
              ></Route>
              <Route
                path="moderator/view"
                element={<ContentPartnerView></ContentPartnerView>}
              ></Route>
              <Route
                path="moderator/view/edit/:id"
                element={<EditContentPartnerView></EditContentPartnerView>}
              ></Route>
              <Route
                path="moderator/commission"
                element={<ContentCommission></ContentCommission>}
              ></Route>
              <Route
                path="moderator/payouts"
                element={<ContentPayout></ContentPayout>}
              ></Route>
              {/* Ads Management */}
              <Route
                path="view-channel-members"
                element={<ViewChannelPartner></ViewChannelPartner>}
              ></Route>
              <Route
                path="partnerapproval"
                element={<ChannelPartnerApproval></ChannelPartnerApproval>}
              ></Route>
              <Route
                path="commission"
                element={<ChannelCommission></ChannelCommission>}
              ></Route>
              <Route
                path="payouts"
                element={<ChannelPayout></ChannelPayout>}
              ></Route>
              <Route
                path="channel-package-index"
                element={<ChannelPackage></ChannelPackage>}
              ></Route>
              <Route
                path="add-channel-package-index"
                element={<AddChannelPackage></AddChannelPackage>}
              ></Route>
              <Route path="pages" element={<AllPages></AllPages>}></Route>
              <Route path="addpages" element={<AddPages></AddPages>}></Route>
              <Route
                path="editpages/:id"
                element={<EditPages></EditPages>}
              ></Route>
              <Route
                path="landing-page/index"
                element={<LandingPages></LandingPages>}
              ></Route>
              <Route
                path="add-landing-page/index"
                element={<AddLandingPages></AddLandingPages>}
              ></Route>
              <Route
                path="edit-landing-page/:id"
                element={<EditLandingPages></EditLandingPages>}
              ></Route>
              <Route
                path="landing-page/create"
                element={<CreateLandingPages></CreateLandingPages>}
              ></Route>
              <Route
                path="subscription-plans"
                element={<SubscriptionPlan></SubscriptionPlan>}
              ></Route>
              <Route
                path="add-subscription-plans"
                element={<AddSubscriptionPlan></AddSubscriptionPlan>}
              ></Route>
              <Route
                path="edit-subscription-plans/:id"
                element={<EditSubscriptionPlan></EditSubscriptionPlan>}
              ></Route>
              <Route
                path="inapp-purchase"
                element={<PurchasePlan></PurchasePlan>}
              ></Route>
              <Route
                path="edit-inapp-purchase/:id"
                element={<EditPurchasePlan></EditPurchasePlan>}
              ></Route>
              <Route
                path="Life-time-subscription"
                element={<LifeTimeSubcription></LifeTimeSubcription>}
              ></Route>
              <Route path="devices" element={<Devices></Devices>}></Route>
              <Route
                path="edit-devices/:id"
                element={<EditDevices></EditDevices>}
              ></Route>
              <Route
                path="payment/total_revenue"
                element={<TotalRevenue></TotalRevenue>}
              ></Route>
              <Route
                path="payment/subscription"
                element={<SubcriptionPayment></SubcriptionPayment>}
              ></Route>
              <Route
                path="payment/subscription-view"
                element={<ViewSubcription></ViewSubcription>}
              ></Route>
              <Route
                path="payment/payPerView"
                element={<PayperviewPayments></PayperviewPayments>}
              ></Route>
              <Route
                path="payment/payPerView-view"
                element={<ViewPayperview></ViewPayperview>}
              ></Route>

              <Route
                path="analytics/revenue"
                element={<UsersAnalytics></UsersAnalytics>}
              ></Route>
              <Route
                path="users/revenue"
                element={<UsersRevenue></UsersRevenue>}
              ></Route>
              <Route
                path="video/purchased-analytics"
                element={<PurchaseVideoAnalytics></PurchaseVideoAnalytics>}
              ></Route>
              <Route
                path="cpp/analytics"
                element={<CPPAnalytics></CPPAnalytics>}
              ></Route>
              <Route
                path="cpp/video-analytics"
                element={<CPPVideoAnalytics></CPPVideoAnalytics>}
              ></Route>
              <Route
                path="cpp/revenue"
                element={<CPPRevenue></CPPRevenue>}
              ></Route>
              <Route
                path="analytics/ViewsRegion"
                element={<ViewRegion></ViewRegion>}
              ></Route>
              <Route
                path="analytics/RevenueRegion"
                element={<RevenueRegion></RevenueRegion>}
              ></Route>
              <Route
                path="analytics/PlayerVideoAnalytics"
                element={<PlayerVideoAnalytics></PlayerVideoAnalytics>}
              ></Route>
              <Route
                path="analytics/RegionVideoAnalytics"
                element={<RegionVideoAnalytics></RegionVideoAnalytics>}
              ></Route>
              <Route
                path="analytics/PlayerUserAnalytics"
                element={<PlayerUserAnalytics></PlayerUserAnalytics>}
              ></Route>
              <Route
                path="analytics/livestream-analytics"
                element={<CPPLiveVideo></CPPLiveVideo>}
              ></Route>
              <Route
                path="live/purchased-analytics"
                element={<LiveStreamAnalytics></LiveStreamAnalytics>}
              ></Route>

              <Route
                path="settings"
                element={<StorefrontSettings></StorefrontSettings>}
              ></Route>
              <Route
                path="home-settings"
                element={<HomePageSettings></HomePageSettings>}
              ></Route>
              <Route
                path="edit-orderhome/:id"
                element={<Editorderhome></Editorderhome>}
              ></Route>
              <Route
                path="linking_settings"
                element={<LinkSettings></LinkSettings>}
              ></Route>
              <Route path="age/index" element={<ManageAge></ManageAge>}></Route>
              <Route
                path="add-age"
                element={<AddManageAge></AddManageAge>}
              ></Route>
              <Route
                path="edit/manage-age/:id"
                element={<EditManageAge></EditManageAge>}
              ></Route>
              <Route
                path="theme_settings"
                element={<ThemeSettings></ThemeSettings>}
              ></Route>
              <Route
                path="email_settings"
                element={<EmailSettings></EmailSettings>}
              ></Route>
              <Route
                path="payment_settings"
                element={<PaymentSettings></PaymentSettings>}
              ></Route>
              <Route
                path="edit-template-email/:id"
                element={<EmailSettingsTemplate></EmailSettingsTemplate>}
              ></Route>
              <Route
                path="template-email/view/:id"
                element={<ViewTemplate></ViewTemplate>}
              ></Route>
              <Route
                path="storage_settings"
                element={<StorageSettings></StorageSettings>}
              ></Route>
              <Route
                path="mobileapp"
                element={<MobileSettings></MobileSettings>}
              ></Route>
              <Route
                path="mobileapp/splash/edit/:id"
                element={<EditSplashMobileSettings></EditSplashMobileSettings>}
              ></Route>
              <Route
                path="mobileapp/edit/:id"
                element={<EditMobileSettings></EditMobileSettings>}
              ></Route>
              <Route
                path="system_settings"
                element={<SocialLoginSettings></SocialLoginSettings>}
              ></Route>
              <Route
                path="edit_currency_settings"
                element={<CurrencySettings></CurrencySettings>}
              ></Route>
              <Route
                path="currency_settings"
                element={<CurrencySettings></CurrencySettings>}
              ></Route>
              <Route
                path="update_currency_settings/:id"
                element={<UpdateCurrencySettings></UpdateCurrencySettings>}
              ></Route>
              <Route
                path="revenue_settings/index"
                element={<RevenueSettings></RevenueSettings>}
              ></Route>
              <Route
                path="ThumbnailSetting"
                element={<ThumbnailSettings></ThumbnailSettings>}
              ></Route>
              <Route
                path="ChooseProfileScreen"
                element={<ProfileScreen></ProfileScreen>}
              ></Route>
              <Route path="ThemeIntegration" element={<Theme></Theme>}></Route>
              <Route
                path="compress-image-setting"
                element={<ImageSettings></ImageSettings>}
              ></Route>
              <Route
                path="pop-up-setting"
                element={<PopupSettings></PopupSettings>}
              ></Route>
              <Route
                path="comment-section-setting"
                element={<CommentSetting></CommentSetting>}
              ></Route>

              <Route
                path="advertisers"
                element={<Advertiser></Advertiser>}
              ></Route>
              <Route
                path="editadvertiser/:id"
                element={<EditAdvertiser></EditAdvertiser>}
              ></Route>
              <Route
                path="edit_ads_list/:id"
                element={<EditAdsList></EditAdsList>}
              ></Route>
              <Route
                path="ads_categories"
                element={<AdsCategories></AdsCategories>}
              ></Route>
              <Route
                path="edit_ads_categories/:id"
                element={<EditAdsCategories></EditAdsCategories>}
              ></Route>
              <Route path="ads_list" element={<AdsList></AdsList>}></Route>
              <Route path="ads_plans" element={<AdsPlan></AdsPlan>}></Route>
              <Route
                path="ads_revenue"
                element={<AdsRevenue></AdsRevenue>}
              ></Route>
              <Route
                path="calender"
                element={<CalenderEvent></CalenderEvent>}
              ></Route>
              <Route
                path="ads_campaigns"
                element={<AdsCampaigns></AdsCampaigns>}
              ></Route>
              <Route
                path="ads-time-slot"
                element={<AdsTimeSlot></AdsTimeSlot>}
              ></Route>

              {/* Geo_Fencing */}
              <Route
                path="Geofencing"
                element={<ManageGeoFencing></ManageGeoFencing>}
              ></Route>
              <Route
                path="countries"
                element={<ManageCountry></ManageCountry>}
              ></Route>
              {/* Configuration */}
              <Route
                path="clear_cache"
                element={<CacheManagement></CacheManagement>}
              ></Route>
              <Route path="debug" element={<Debug></Debug>}></Route>
              {/* Contact Us */}
              <Route
                path="contact-us"
                element={<ContactRequest></ContactRequest>}
              ></Route>
              {/* Log Activity */}
              <Route
                path="logActivity"
                element={<SeeLogActivity></SeeLogActivity>}
              ></Route>
            </Routes>
          </Layout>
        </Router>
      )}
    </>
  );
}

export default AllRoutes;
