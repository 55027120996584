import React from 'react'
import { Link } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";

const ManageLiveEvent = () => {

    const datas = [
        {
          // Image: ,
          title: "Zootopia",
          name: "Admin",
          type: "Paid",
          access: "ppv",
          stream: "Live Stream Video",
        },
      ];

  return (
    <div>
        <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="iq-card mt-4">
              <div id="content-page" className="content-page">
                <div className="container-fluid">
                  <div className="admin-section-title">
                    <div className="">
                      <div className="row">
                        <div className="col-md-6">
                          <h4>
                            <i className="entypo-archive"></i> Live Event Artist
                          </h4>
                        </div>
                        <div className="col-md-6" align="right">
                          <Link to="/admin/channel/add-manage-live-event">
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                            >
                              <i className="fa fa-plus-circle"></i> Add New
                            </button>
                          </Link>
                        </div>
                      </div>

                      <div className="clear"></div>

                      <div className="d-flex justify-content-between p-3">
                        <div className="d-flex">
                          <p>Show 10</p>
                          <p>&nbsp; &nbsp; entries</p>
                        </div>
                        <div className="d-flex">
                          <p>Search:</p>
                          <input
                            type="search"
                            id="gsearch"
                            name="gsearch"
                            className="border-0 search-rev"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <table className="table" id="user_tabledss">
                            <thead>
                              <tr className="r1">
                                <th>ID</th>
                                <th>Image</th>
                                <th>Title</th>
                                <th>User Name</th>
                                <th> Video Type </th>
                                <th>Video Access</th>
                                <th>Status</th>
                                <th>Stream Type</th>
                                <th>Slider</th>
                                <th>ACTION</th>
                              </tr>
                            </thead>
                            {/* {datas.map((item, key) => (
                              <thead>
                                <tr className="r1">
                                  <td key={key}> {key + 1} </td>
                                  <td>{item.Image}</td>
                                  <td>{item.title}</td>
                                  <td>{item.name}</td>
                                  <td>{item.type} </td>
                                  <td>{item.access}</td>
                                  <td>
                                    <button className="btn btn-primary">
                                      approved
                                    </button>
                                  </td>
                                  <td>{item.stream}</td>
                                  <td>
                                    <div className="mt-2 d-flex align-items-center col-md-4">
                                      <label class="switch">
                                        <input type="checkbox" />
                                        <span class="slider slid-on round"></span>
                                      </label>
                                    </div>
                                  </td>

                                  <td className="table-content">
                                    <Link
                                      to={"/admin/payment/payPerView-view"}
                                      className="edit ms-1"
                                    >
                                      <AiOutlineEye />
                                    </Link>
                                    <Link
                                      to={"/admin/live-event-artist-edit/:id"}
                                    >
                                      <i
                                        className="fa fa-pencil-square"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                    <Link>
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  </td>
                                </tr>
                              </thead>
                            ))} */}
                            <tbody></tbody>
                          </table>
                        </div>
                        <div className="col-md-4"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default ManageLiveEvent