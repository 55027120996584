import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import axios, { CancelToken, isCancel } from "axios";
import { ProgressBar } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";

export default function Edit_Episodevideo() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const { id } = useParams();
  const { seriesid } = useParams();

  const [editUser, setEditUser] = useState({ mp4_url: "", });
  // console.log(seriesid)
  // console.log(id)

  const [file, setFile] = useState("");
  const access_token = localStorage.getItem("access_token");
  const [Content_Partner_id, setContent_Partner_id] = useState("1");
  const [message, setMessage] = useState("");

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const cancelFileUpload = useRef(null);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-Episode/list`,
        { Content_Partner_id: Content_Partner_id },
        {
          headers: headers,
        }
      )
      .then((response) => {
        // console.log('api checks', response.data.user_access)
        var result = response?.data?.Episodes?.data;
        setEditUser(result);
        // console.log("result", result);
      })
      .catch((error) => console.log(error));
  }, []);


  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setSelectedFile(selectedFile);
    uploadFile(selectedFile);
  };

  const uploadFile = (selectedFile) => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("Content_Partner_id", Content_Partner_id);

      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-Episode/upload-update/${id}`,
        { headers: headers }
      );
      xhr.setRequestHeader("Authorization", "Bearer " + access_token);

      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          setUploadProgress(progress);
        }
      });

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          // File upload completed
          alert("Added successfully  !");
          // console.log("Upload completed");
        }
      };

      xhr.send(formData);
    }
  };

  const getFileSizeInMB = () => {
    if (selectedFile) {
      const fileSizeInBytes = selectedFile.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
      return fileSizeInMB.toFixed(2);
    }
    return null;
  };

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid pt-3">
          <div className="p-2">
            <div className="iq-card">
              <media-player
                width="200px"
                // title={editUser?.ppv_access}
                // src={editUser?.trailer}
                poster=""
                controls
              >
                <media-outlet width="200px"></media-outlet>
              </media-player>

              <div className="modal-body">
                <div>
                  <div>
                    <div>
                      <h3>Upload Full Episode Here</h3>

                      <div className=" text-center file-drag mt-3 editvideo mb-5">
                        <label
                          id="label-file-upload"
                          htmlFor="input-file-upload"
                        >
                          <div>
                            <p>
                              Trailers Can Be Uploaded From Video Edit Screen
                            </p>
                            <p>
                              <input
                                type="file"
                                accept="video/mp4"
                                id="input-file-upload"
                                name="addvideo"
                                multiple
                                className="form-control"
                                onChange={handleFileChange}
                              />
                            </p>
                            {selectedFile && (
                              <div>File Size: {getFileSizeInMB()} MB</div>
                            )}
                            {selectedFile && (
                              <div>Upload Progress: {uploadProgress}%</div>
                            )}
                          </div>
                          <br />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
