import { Link, useNavigate, useParams } from "react-router-dom";
import JoditEditor from "jodit-react";
import React, { useState, useRef, useEffect } from "react";
import axios, { CancelToken, isCancel } from "axios";
import { ProgressBar } from "react-bootstrap";

function Edit_Video() {
  const [type, setType] = useState("");

  const [mp3_url, setMp3_url] = useState("");
  const [videomp4, setVideomp4] = useState("");
  const [embed_code, setEmbed_code] = useState("");
  const [file, setFile] = useState("");

  const [enable_restream, setEnable_restream] = useState("");
  const [enablestreamvisible, setEnablestreamvisible] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");
  const [Content_Partner_id, setContent_Partner_id] = useState("1");

  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setSelectedFile(selectedFile);
    uploadFile(selectedFile);
  };

  const uploadFile = (selectedFile) => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("id", id);

      formData.append("Content_Partner_id", Content_Partner_id);

      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/video-edit/${id}`,
        { headers: headers }
      );
      xhr.setRequestHeader("Authorization", "Bearer " + access_token);

      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          setUploadProgress(progress);
        }
      });

      xhr.onreadystatechange = (res) => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            alert("Added successfully!");
            const responseData = JSON.parse(xhr.responseText);
            var audio_id = responseData.Audio_id;
            // setAudio_id(audio_id);
          } else {
            console.error("Request failed with status:", xhr.status);
          }
        }
      };

      xhr.send(formData);
    }
  };

  const getFileSizeInMB = () => {
    if (selectedFile) {
      const fileSizeInBytes = selectedFile.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
      return fileSizeInMB.toFixed(2);
    }
    return null;
  };

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const cancelFileUpload = useRef(null);

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload.");
  };

  const [uploadPercentagefile, setUploadPercentagefile] = useState(0);
  const cancelFileUploadfile = useRef(null);

  const cancelUploadfile = () => {
    if (cancelFileUploadfile.current)
      cancelFileUploadfile.current("User has canceled the file upload.");
  };

  async function videofilesubmit() {
    const formData = new FormData();

    formData.append("m3u8_url", mp3_url);
    formData.append("mp4_url", videomp4);
    formData.append("embed_code", embed_code);
    formData.append("video_type", type);
    formData.append("Content_Partner_id", Content_Partner_id);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/video-upload-url`,
        formData,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response.data.status == true) {
          var result = response.data.Audio;
          var results = response.data.Audio_id;
          // setEditUser(result);
          // setAudio_id(results);
          alert("Added successfully  !");
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  function proceednext() {
    switch (enable_restream) {
      case "0":
        return <div></div>;
      case "1":
        return (
          <div className="mt-3 videoupload">
            <label>Upload Video </label>

            <div className=" text-center file-drag ">
              <label id="label-file-upload" htmlFor="input-file-upload">
                <div
                  name="addvideo"
                  id="addvideo"
                  onChange={(event) => {
                    setEnable_restreamaddvideo(event.target.name);
                  }}
                >
                  <input
                    type="file"
                    accept="video/*"
                    id="input-file-upload"
                    name="addvideo"
                    multiple
                    className="form-control"
                    onChange={handleFileChange}
                    onClick={(event) => {
                      setEnable_restreamaddvideo(event.target.name);
                    }}
                  />

                  {selectedFile && <div>File Size: {getFileSizeInMB()} MB</div>}
                  {selectedFile && (
                    <div>Upload Progress: {uploadProgress}%</div>
                  )}
                </div>
              </label>

              <div
                onClick={(event) => {
                  setEnablestreamvisibleaddvideo(event.target.value);
                }}
              >
                {proceednextaddvideo()}
              </div>
            </div>
          </div>
        );
      case "m3u8_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> m3u8 URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="m3u8_trailer"
                    placeholder="m3u8 Trailer"
                  />
                </div>
              </div>
              <div className="text-center mt-3">
              <button
                    className="btn btn-primary"
                    onClick={() => {
                      videofilesubmit();
                    }}
                  >
                    Submit{" "}
                  </button>
              </div>
            </div>
          </div>
        );
      case "mp4_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> Mp4 File URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="live_mp3_url"
                    placeholder="Mp4 File URL"
                  />
                </div>
              </div>
              <div className="text-center mt-2">
              <button
                    className="btn btn-primary"
                    onClick={() => {
                      videofilesubmit();
                    }}
                  >
                    Submit{" "}
                  </button>
              </div>
            </div>
          </div>
        );
      case "embed":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> Embed URL: </label>
                  <div>
                    <p>
                      Example URL Format : (
                      Https://Www.Youtube.Com/Embed/*Xxxxxxxxx*/ ){" "}
                    </p>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name="embed_trailer"
                    placeholder="Embed Trailer"
                  />
                </div>
              </div>
              <div className="text-center mt-3">
              <button
                    className="btn btn-primary"
                    onClick={() => {
                      videofilesubmit();
                    }}
                  >
                    Submit{" "}
                  </button>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  }

  const [enable_restreamaddvideo, setEnable_restreamaddvideo] = useState("");
  const [enablestreamvisibleaddvideo, setEnablestreamvisibleaddvideo] =
    useState("");

  function proceednextaddvideo() {
    switch (enable_restreamaddvideo) {
      case "addvideo":
        return (
          <div>
            <div className="mb-12">
              {uploadPercentage > 0 && (
                <div className="row mt-3">
                  <div className="col pt-1">
                    <ProgressBar
                      now={uploadPercentage}
                      striped={true}
                      label={`${uploadPercentage}%`}
                    />
                  </div>
                  <div className="col-auto">
                    <span
                      className="text-primary cursor-pointer"
                      onClick={() => cancelUpload()}
                    >
                      Cancel
                    </span>
                  </div>
                </div>
              )}
            </div>

            {/* <div className="text-center mt-3 mb-5">
              <button className="btn btn-primary">Proceed to Update </button>
            </div> */}
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="pt-3">
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card addvideo">
                    <div className="iq-card-header">
                      <div className="iq-header-title">
                        <h4 className="card-title">Edit Tag Video</h4>
                        <hr />
                        <div>
                          <div
                            onClick={(event) => {
                              setEnablestreamvisible(event.target.value);
                            }}
                          >
                            {proceednext()}
                          </div>
                          <div>
                            {/* <input type='checkbox' value='1' onClick={(event) => {setEnable_restream(event.target.value); }} />
                    <input type='checkbox' value='2' onClick={(event) => {setEnable_restream(event.target.value); }} />
                    <input type='checkbox' value='3' onClick={(event) => {setEnable_restream(event.target.value); }} /> */}

                            <div className="row d-flex">
                              <div className="col-md-12 offset-md-2 mt-3">
                                <input
                                  className="form-check-input ms-5"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                  value="1"
                                  onClick={(event) => {
                                    setEnable_restream(event.target.value);
                                  }}
                                />
                                <label
                                  className="form-check-label ms-1"
                                  for="flexRadioDefault1"
                                >
                                  Video Upload
                                </label>

                                <input
                                  className="form-check-input ms-5"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault2"
                                  value="m3u8_url"
                                  onClick={(event) => {
                                    setEnable_restream(event.target.value);
                                  }}
                                />
                                <label
                                  className="form-check-label ms-1"
                                  for="flexRadioDefault2"
                                >
                                  m3u8 Url
                                </label>

                                <input
                                  className="form-check-input ms-5"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault3"
                                  value="mp4_url"
                                  onClick={(event) => {
                                    setEnable_restream(event.target.value);
                                  }}
                                />
                                <label
                                  className="form-check-label ms-1"
                                  for="flexRadioDefault3"
                                >
                                  Video mp4
                                </label>

                                <input
                                  className="form-check-input ms-5"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault3"
                                  value="embed"
                                  onClick={(event) => {
                                    setEnable_restream(event.target.value);
                                  }}
                                />
                                <label
                                  className="form-check-label ms-1"
                                  for="flexRadioDefault3"
                                >
                                  Embed Code
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Edit_Video;
