import './App.css';
import AllRoutes from './AllRoutes';
import './components/museo_font/stylesheet.css';
import ChannelRoute from './ChannelRoute';

function App() {
  return (
    <div >
      <AllRoutes></AllRoutes>
      <ChannelRoute></ChannelRoute>
    </div>
  );
}

export default App;
