import React from "react";
import "./layout.css";
import Sidebar from "./Sidebar/Sidebar";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";

const Layout = ({ children }) => {
  return (
    <>
      <div className="row p-0 m-0">
        <div className="col-lg-2 p-0 m-0">
          <div className="layout-sidebar">
            <Sidebar />
          </div>
        </div>
        <div className="col-lg-10 pe-0 me-0">
          <div className="right-content">
            <div className="layout-header">
              <Header />
            </div>
            <div className="layout-children">{children}</div>
            <div className="layout-footer">
              <Footer />
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </>
  );
};

export default Layout;
