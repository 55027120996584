import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import axios, { CancelToken, isCancel } from "axios";
import { ProgressBar } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
// import "./Addnewaudio.css";
import Select from "react-select";

const Add_NewAudios = () => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [file, setFile] = useState("");
  const [audio_id, setAudio_id] = useState("");
  const [title, setTitle] = useState("");
  const [video_title, setVideo_title] = useState("");
  const [video_duration, setVideo_duration] = useState("");
  const [slug, setSlug] = useState("");
  const [ppv_status, setPpv_status] = useState("");
  const [ppv_price, setPpv_price] = useState("");
  const [type, setType] = useState("");
  const [album, setAlbum] = useState([]);
  const [albumset, setAlbumset] = useState("");
  const [artistss, setArtistss] = useState("");
  const [rating, setRating] = useState("");
  const [details, setDetails] = useState("");
  const [description, setDescription] = useState("");
  const [active, setActive] = useState("");
  const [status, setStatus] = useState("");
  const [draft, setDraft] = useState("");
  const [featured, setFeatured] = useState("");
  const [global_ppv, setGlobal_ppv] = useState("");
  const [banner, setBanner] = useState("");
  const [duration, setDuration] = useState("");
  const [views, setViews] = useState("");
  const [year, setYear] = useState("");
  const [mp3_url, setMp3_url] = useState("");
  const [mp3_live_url, setMp3_live_url] = useState("");
  const [image, setImage] = useState("");
  const [player_image, setPlayer_image] = useState("");
  const [tv_image, setTv_image] = useState("");
  const [search_tags, setSearch_tags] = useState("");
  const [ios_ppv_price, setIos_ppv_price] = useState("");
  const [uploaded_by, setUploaded_by] = useState("");
  const [image_url, setImage_url] = useState("");
  const [Player_thumbnail, setPlayer_thumbnail] = useState("");
  const [TV_Thumbnail, setTV_Thumbnail] = useState("");

  const [user_access, setUser_access] = useState([]);
  const [useraccess, setUserAccess] = useState("");
  const [inapppurchase, setInappPurchase] = useState("");

  const [order, setOrder] = useState({});
  const [data, setData] = useState([]);
  const [publish, setPublish] = useState([]);

  const [message, setMessage] = useState("");

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");
  const [Content_Partner_id, setContent_Partner_id] = useState("1");

  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  // Multiselect
  const [block_country, setBlock_Country] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [language, setLanguage] = useState([]);
  const [selectedOptionslanguage, setSelectedOptionslanguage] = useState([]);

  const [age_restrict, setAge_Restrict] = useState([]);
  const [selectedOptionsage_restrict, setSelectedOptionsage_restrict] =
    useState([]);

  const [artists, setArtists] = useState([]);
  const [selectedOptionsartists, setSelectedOptionsartists] = useState([]);

  const [category, setCategory] = useState([]);
  const [selectedOptionscategory, setSelectedOptionscategory] = useState([]);

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/create`,
        { headers: headers }
      );
      setBlock_Country(response.data.Block_country);
      setLanguage(response.data.languages);
      setAge_Restrict(response.data.Age_Restrict);
      setArtists(response.data.artists);
      setCategory(response.data.category);
      setInputValueAPI(response.data.ppv_gobal_price);
      // setAudio_albums(response.data.audio_albums);
      setAlbum(response?.data?.audio_albums);
      var result = response?.data?.user_access;
      var InappPurchase = response?.data?.InappPurchase;
      setUser_access(result);
      setInappPurchase(InappPurchase);
      //  console.log(response.data.Block_country);
      // console.log(response);
      // console.log(response.data.ppv_gobal_price);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const handleSelectChangeage_restrict = (selectedValues) => {
    setSelectedOptionsage_restrict(selectedValues);
  };

  const handleSelectChangelanguage = (selectedValues) => {
    setSelectedOptionslanguage(selectedValues);
  };

  const handleSelectChangeartists = (selectedValues) => {
    setSelectedOptionsartists(selectedValues);
  };

  const handleSelectChangecategory = (selectedValues) => {
    setSelectedOptionscategory(selectedValues);
  };
  // Multiselect

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  // console.log(inputValue, "checkboxChecked");

  const [userAccesses, setUserAccesses] = useState([]);
  const [userAccessesValue, setUserAccessesValue] = useState([]);
  const handleAccessChange = (e) => {
    setUserAccessesValue(e);
  };
  // console.log(userAccessesValue, "userAccess");
  const [editUser, setEditUser] = useState({ title: "", duration: "" });
  // console.log(editUser)
  // console.log(editUser)

  const handleInput = (e) => {
    // setGlobal_ppv({ ...global_ppv, [e.target.name]: e.target.value });
    // setBanner({ ...banner, [e.target.name]: e.target.value });
    // setFeatured({ ...featured, [e.target.name]: e.target.value });
    // setTitle({ ...title, [e.target.id]: e.target.value });

    setEditUser({ ...editUser, [e.target.name]: e.target.value });
    setVideo_title({ ...video_title, [e.target.name]: e.target.value });
    setVideo_duration({ ...video_duration, [e.target.name]: e.target.value });

    if (e.target.checked == true) {
      setGlobal_ppv({ ...global_ppv, [e.target.name]: 1 });
      setViews({ ...views, [e.target.name]: 1 });
      setPpv_status({ ...ppv_status, [e.target.name]: 1 });
      setFeatured({ ...featured, [e.target.name]: 1 });
      setBanner({ ...banner, [e.target.name]: 1 });
      setActive({ ...active, [e.target.name]: 1 });
      setStatus({ ...status, [e.target.name]: 1, [e.target.id]: 0 });
    } else {
      setGlobal_ppv({ ...global_ppv, [e.target.name]: 0 });
      setViews({ ...views, [e.target.name]: 0 });
      setPpv_status({ ...ppv_status, [e.target.name]: 0 });
      setFeatured({ ...featured, [e.target.name]: 0 });
      setBanner({ ...banner, [e.target.name]: 0 });
      setActive({ ...active, [e.target.name]: 0 });
      setStatus({ ...status, [e.target.name]: 0, [e.target.id]: 0 });
    }
  };

  // console.log("publish", publishdata)
  // console.log("ThumbnailTitle", ThumbnailTitle)

  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setSelectedFile(selectedFile);
    uploadFile(selectedFile);
  };

  const uploadFile = (selectedFile) => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("Content_Partner_id", Content_Partner_id);

      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/Upload-file`,
        { headers: headers }
      );
      xhr.setRequestHeader("Authorization", "Bearer " + access_token);

      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          setUploadProgress(progress);
        }
      });

      xhr.onreadystatechange = (res) => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          // Request completed
          if (xhr.status === 200) {
            // Successful response
            alert("Added successfully!");
            // Handle the response data here
            const responseData = JSON.parse(xhr.responseText);
            var audio_id = responseData.Audio_id;
            var video_title = responseData.Audio.title;
            var duration = responseData.Audio_duration_seconds;
            setEditUser(responseData.Audio);
            setAudio_id(audio_id);
            setVideo_duration(duration);
            setVideo_title(video_title);
            // console.log("Response responseData:", responseData.Audio);
            // console.log("Response video_title:", video_title);
            // console.log("Response video_title:", video_title);
            // console.log("Response duration:", duration);
            // console.log("Response data:", audio_id);
          } else {
            // Error response
            console.error("Request failed with status:", xhr.status);
          }
        }
      };

      xhr.send(formData);
    }
  };

  const getFileSizeInMB = () => {
    if (selectedFile) {
      const fileSizeInBytes = selectedFile.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
      return fileSizeInMB.toFixed(2);
    }
    return null;
  };

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const cancelFileUpload = useRef(null);

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload.");
  };

  const [uploadPercentagefile, setUploadPercentagefile] = useState(0);
  const cancelFileUploadfile = useRef(null);

  const cancelUploadfile = () => {
    if (cancelFileUploadfile.current)
      cancelFileUploadfile.current("User has canceled the file upload.");
  };

  async function audiofilesubmit() {
    // console.log('append data', title, image, url_type, player_image, fb_streamkey, twitter_streamkey, youtube_streamkey, fb_restream_url, twitter_restream_url, youtube_restream_url, ppv_price, Tv_live_image, search_tags, embed_url, m3u_url, details, year, banner, active, publish_type, featured, enable_restream, duration, access, live_stream_video, mp4_url, rating, mp4_url, description, category, artist, language);

    const formData = new FormData();

    formData.append("mp3_url", mp3_url);
    formData.append("mp3_live_url", mp3_live_url);
    formData.append("type", type);
    formData.append("Content_Partner_id", Content_Partner_id);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/upload-url`,
        formData,
        {
          headers: headers,
        }
      )
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status == true) {
          var result = response.data.Audio;
          var results = response.data.Audio_id;
          setEditUser(result);
          // console.log("result", result);
          // console.log("result", results);
          setAudio_id(results);
          // navigate('/admin/livestream')
          alert("Added successfully  !");
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  async function Audio() {
    const editInputvalue = active.active;
    var data = JSON.stringify(editInputvalue);
    var aactivedata = data;

    const editInputglobal_ppv = global_ppv.global_ppv;
    var dataglobal_ppv = JSON.stringify(editInputglobal_ppv);
    var global_ppvdata = dataglobal_ppv;

    const editInputfeatured = featured.featured;
    var datafeatured = JSON.stringify(editInputfeatured);
    var featureddata = datafeatured;

    const editInputbanner = banner.banner;
    var databanner = JSON.stringify(editInputbanner);
    var bannerdata = databanner;

    const editInputstatus = status.status;
    var datastatus = JSON.stringify(editInputstatus);
    var statusdata = datastatus;

    const editInputppv_status = ppv_status.ppv_status;
    var datappv_status = JSON.stringify(editInputppv_status);
    var ppv_statusdata = datappv_status;

    const editInputviews = views.views;
    var dataviews = JSON.stringify(editInputviews);
    var viewsdata = dataviews;

    const formData = new FormData();

    formData.append("title", editUser.title);
    formData.append("Content_Partner_id", Content_Partner_id);
    formData.append("audio_id", audio_id);
    formData.append("ppv_status", ppv_statusdata);
    formData.append("ppv_price", inputValue);
    formData.append("slug", slug);
    formData.append("type", type);
    formData.append("access", useraccess);

    formData.append("global_ppv", global_ppvdata);
    formData.append("album_id", albumset);
    formData.append(
      "artists",
      JSON.stringify(selectedOptionsartists?.map((option) => option.value))
    );
    formData.append(
      "block_country",
      JSON.stringify(selectedOptions?.map((option) => option.value))
    );
    formData.append(
      "category",
      JSON.stringify(selectedOptionscategory?.map((option) => option.value))
    );
    formData.append(
      "language",
      JSON.stringify(selectedOptionslanguage?.map((option) => option.value))
    );
    // formData.append(
    //   "audio_albums",
    //   JSON.stringify(selectedOptionsaudio_albums?.map((option) => option.value))
    // );
    formData.append(
      "age_restrict",
      JSON.stringify(age_restrict?.map((option) => option.value))
    );
    formData.append("rating", rating);
    formData.append("details", details);
    formData.append("description", description);
    formData.append("active", aactivedata);
    formData.append("status", statusdata);
    formData.append("draft", draft);
    formData.append("featured", featureddata);
    formData.append("banner", bannerdata);
    formData.append("duration", editUser.duration);
    formData.append("views", viewsdata);
    formData.append("year", year);

    formData.append("mp3_url", mp3_url);
    formData.append("search_tags", search_tags);
    formData.append("ios_ppv_price", ios_ppv_price);
    formData.append("uploaded_by", uploaded_by);

    formData.append("ads_position", selectedAdsPosition?.value);
    formData.append("live_ads", secondSelectOptions?.value);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/store`,
        formData,
        {
          headers: headers,
        }
      )
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status == true) {
          var result = response.data;
          // console.log('result', response);
          // navigate('/admin/livestream')
          alert("Added successfully  !");

          var audio_id = response.data.Audio_id;
          var resssss = response.data;
          console.log("resssss", resssss);
          console.log("audio_id", audio_id);

          const formDatas = new FormData();

          formDatas.append("audio_id", audio_id);
          formDatas.append("image", image);
          formDatas.append("player_image", player_image);
          formDatas.append("tv_image", tv_image);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/Image-upload`,
              formDatas,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api' , response);
              if (response.data.status == true) {
                var result = response.data;
                // console.log('result', response);
                // navigate('/admin/all-artist')
                alert("Added successfully  !");
              }
            });
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const fetchAdsPositions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          // const data = await response.json();
          // console.log(data);

          const adsPositionOptions = data?.ads_position?.map((position) => ({
            value: position.position,
            label: position.name,
          }));
          console.log(adsPositionOptions);
          setAdsPositions(adsPositionOptions);
        } else {
          throw new Error("Request failed with status: " + response.status);
        }
      } catch (error) {
        console.log("Error fetching ads positions:", error);
      }
    };
    fetchAdsPositions();
  }, []);

  useEffect(() => {
    if (selectedAdsPosition !== "") {
      // console.log(selectedAdsPosition, "adsssssssssss");
      const formData = new FormData();
      formData.append("position", selectedAdsPosition?.value);

      axios

        .post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/video-advertisement-tag-url`,
          formData,
          { headers: headers }
        )

        .then((response) => {
          const data = response.data;
          console.log(data, "exceptioned");

          const adsNames = data?.Advertisement?.map((position) => ({
            value: position?.id,
            label: position?.ads_name,
          }));

          setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition]);

  const [enable_restream, setEnable_restream] = useState("");
  const [enablestreamvisible, setEnablestreamvisible] = useState("");

  const [enable_restreamaddvideo, setEnable_restreamaddvideo] = useState("");
  const [enablestreamvisibleaddvideo, setEnablestreamvisibleaddvideo] =
    useState("");

  function proceednextaddvideo() {
    switch (enable_restreamaddvideo) {
      case "addvideo":
        return (
          <div>
            <div className="mb-12">
              {uploadPercentage > 0 && (
                <div className="row mt-3">
                  <div className="col pt-1">
                    <ProgressBar
                      now={uploadPercentage}
                      striped={true}
                      label={`${uploadPercentage}%`}
                    />
                  </div>
                  <div className="col-auto">
                    <span
                      className="text-primary cursor-pointer"
                      onClick={() => cancelUpload()}
                    >
                      Cancel
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className="text-center mt-2 mb-5">
              <button onClick={handleNext} className="btn btn-primary">
                Proceed to Next{" "}
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  function proceednext() {
    switch (enable_restream) {
      case "0":
        return <div></div>;
      case "audio_upload":
        return (
          <div>
            <label>Upload Audio </label>
            <div className=" text-center file-drag mb-5">
              <label id="label-file-upload" htmlFor="input-file-upload">
                <div
                  name="addvideo"
                  id="addvideo"
                  onChange={(event) => {
                    setEnable_restreamaddvideo(event.target.name);
                  }}
                >
                  <input
                    type="file"
                    accept="audio/mp3"
                    id="input-file-upload"
                    name="addvideo"
                    multiple
                    className="form-control"
                    onChange={handleFileChange}
                    onClick={(event) => {
                      setEnable_restreamaddvideo(event.target.name);
                    }}
                  />
                  {/* <div>File Size: {getFileSizeInMB()} MB</div>
                    <div>Upload Progress: {uploadProgress}%</div> */}

                  {selectedFile && <div>File Size: {getFileSizeInMB()} MB</div>}
                  {selectedFile && (
                    <div>Upload Progress: {uploadProgress}%</div>
                  )}
                </div>

                {/* <div
                    name="addvideo"
                    id="addvideo"
                    onChange={(event) => {
                      setEnable_restreamaddvideo(event.target.name);
                    }}
                  >
                    <p>Drag and drop your file here or</p>
                    <p>
                      <input
                        type="file"
                        className="form-control"
                        onChange={uploadFile}
                        multiple
                        accept="video/mp4"
                        id="input-file-upload"
                        name="addvideo"
                        onClick={(event) => {
                          setEnable_restreamaddvideo(event.target.name);
                        }}
                      />
                    </p>
                  </div> */}
              </label>

              <div
                onClick={(event) => {
                  setEnablestreamvisibleaddvideo(event.target.value);
                }}
              >
                {proceednextaddvideo()}
              </div>

              {/* <input className="form-check-input ms-5" type="radio" name="addvideo" id="addvideo"
                  value='1' onClick={(event) => { setEnable_restreamaddvideo(event.target.name); }} />
                <label className="form-check-label ms-1" for="flexRadioDefault1">
                  Video Upload
                </label> */}

              {/* <div className='mb-5'>
                {uploadPercentage > 0 && (
                  <div className="row mt-3">
                    <div className="col pt-1">
                      <ProgressBar
                        now={uploadPercentage}
                        striped={true}
                        label={`${uploadPercentage}%`}
                      />
                    </div>
                    <div className="col-auto">
                      <span
                        className="text-primary cursor-pointer"
                        onClick={() => cancelUpload()}
                      >
                        Cancel
                      </span>
                    </div>
                  </div>
                )}
  
              </div> */}
            </div>
          </div>
        );
      case "mp3":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> Mp3 File URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="mp3_url"
                    onChange={(e) => setMp3_url(e.target.value)}
                    placeholder="Mp3 File URL"
                  />
                </div>
                <div className="mt-4 text-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      audiofilesubmit();
                      handleNext();
                    }}
                  >
                    Proceed to Next{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      case "live_mp3":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> Live Mp3 File URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="live_mp3_url"
                    placeholder="Live Mp3 File URL"
                    onChange={(e) => setMp3_live_url(e.target.value)}
                  />
                </div>
                <div className="mt-4 text-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      audiofilesubmit();
                      handleNext();
                    }}
                  >
                    Proceed to Next{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  }

  const [livesource, setLivesource] = useState("");

  function live_stream_source() {
    switch (livesource) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div class="row mt-3 text-start ">
            <div class="col-sm-6">
              <label class="m-0">PPV Price</label>
              <div className="col-lg-12 row">
                <div className="col-8 col-lg-8">
                  <p class="p1">Apply PPV Price from Global Settings? </p>
                </div>
                <div className="col-4 col-lg-4">
                  <label className="switch">
                    <input
                      name="ppv_price"
                      defaultChecked={checkboxChecked}
                      onChange={handleCheckboxChange}
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="ppv_price"
                      checked={checkboxChecked}
                      onChange={handleCheckboxChange}
                    ></span>
                  </label>
                </div>
              </div>

              <div class="panel-body">
                <input
                  type="text"
                  class="form-control"
                  placeholder="PPV Price"
                  name="ppv_price"
                  id="price"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div class="col-sm-6">
              <label class="m-0"> IOS PPV Price</label>
              <p class="p1">Apply IOS PPV Price from Global Settings?</p>
              <div class="panel-body ppv_price_ios">
                {/* <Select
                                name="ios_ppv_price"
                                className="form-control"
                                options={ios_ppv_price}
                                onChange={(e) =>
                                  setIos_ppv_price(e.target.value)
                                }
                              /> */}

                <select
                  onChange={(e) => setIos_ppv_price(e.target.value)}
                  className="form-control"
                >
                  <option value="">Choose a PPV Price</option>
                  {inapppurchase?.map((item, key) => (
                    <option value={item.id}>{item.plan_price}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  const steps = [
    {
      label: "Select campaign settings",
      description: (
        <div>
          <div
            onClick={(event) => {
              setEnablestreamvisible(event.target.value);
            }}
          >
            {proceednext()}
          </div>
          <div>
            <div className="row d-flex mt-5">
              <div className="col-md-10 offset-md-2 mt-3">
                <input
                  className="form-check-input ms-5"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  value="audio_upload"
                  onClick={(event) => {
                    setEnable_restream(event.target.value);
                  }}
                  onChange={(e) => setType(e.target.value)}
                />
                <label
                  className="form-check-label ms-1"
                  for="flexRadioDefault1"
                >
                  Audio Upload
                </label>

                <input
                  className="form-check-input ms-5"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="mp3"
                  onChange={(e) => setType(e.target.value)}
                  onClick={(event) => {
                    setEnable_restream(event.target.value);
                  }}
                />
                <label
                  className="form-check-label ms-1"
                  for="flexRadioDefault2"
                >
                  Audio File
                </label>

                <input
                  className="form-check-input ms-5"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault3"
                  value="live_mp3"
                  onChange={(e) => setType(e.target.value)}
                  onClick={(event) => {
                    setEnable_restream(event.target.value);
                  }}
                />
                <label
                  className="form-check-label ms-1"
                  for="flexRadioDefault3"
                >
                  Live Audio File
                </label>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      label: "Create an ad group",
      description: (
        <div className="iq-card-body ">
          <h5>Audio Info Details</h5>
          <div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="panel panel-primary " data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Title</label>
                    </div>{" "}
                  </div>
                  <div className="panel-body">
                    <p className="p1">
                      Add the audio title in the textbox below:
                    </p>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      id="title"
                      placeholder="Audio Title"
                      value={editUser.title}
                      onChange={handleInput}
                    />
                    {/* <input
                      type="text"
                      hidden
                      className="form-control"
                      value={file}
                      name="title"
                      id="title"
                      placeholder="Audio Title"
                      onChange={(e) => setFile(e.target.value)}
                    /> */}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="panel panel-primary" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Slug</label>
                    </div>{" "}
                  </div>
                  <div className="panel-body">
                    <p className="p1">Add the Audio slug:</p>
                    <input
                      type="text"
                      className="form-control"
                      name="slug"
                      id="slug"
                      placeholder=""
                      onChange={(e) => setSlug(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              {/* <div className="col-md-6">
            <div className="panel panel-primary" data-collapsed="0"> <div className="panel-heading">
              <div className="panel-title"><label>Created Date</label></div> <div className="panel-options"> <Link to="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
              <div className="panel-body" >
                <p className="p1">Select Date/Time Below</p>
                <input type="text" className="form-control" name="created_at" id="created_at" placeholder="" value="" />
              </div>
            </div>
          </div> */}
            </div>
            <div className="row">
              <div className="col-md-6">
                <div
                  className="panel panel-primary  p-0 mt-3"
                  data-collapsed="0"
                >
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Audio Image Cover</label>
                    </div>{" "}
                  </div>
                  <div className="panel-body">
                    <p className="p1">
                      Select the audio image ( 9:16 Ratio or 1080X1920px ):
                    </p>
                    <input
                      type="file"
                      multiple="true"
                      className="form-control"
                      name="image"
                      id="image"
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="panel panel-primary  p-0 mt-3"
                  data-collapsed="0"
                >
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Player Image Cover</label>
                    </div>{" "}
                  </div>
                  <div className="panel-body">
                    <p className="p1">
                      Select the audio image ( 16:9 Ratio or 1280X720px ):
                    </p>
                    <input
                      type="file"
                      multiple="true"
                      className="form-control"
                      name="player_image"
                      onChange={(e) => setPlayer_image(e.target.files[0])}
                      id="player_image"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div
                  className="panel panel-primary  p-0 mt-3"
                  data-collapsed="0"
                >
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Audio TV Thumbnail Cover</label>
                    </div>{" "}
                  </div>
                  <div className="panel-body">
                    <p className="p1">
                      Select the audio TV Thumbnail ( 9:16 Ratio or 1080X1920px
                      ):
                    </p>
                    <input
                      type="file"
                      multiple="true"
                      className="form-control"
                      name="tv_thumbnail"
                      id="tv_thumbnail"
                      onChange={(e) => setTv_image(e.target.files[0])}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6 mt-3">
                <div className="panel panel-primary" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Age Restrict</label>
                    </div>{" "}
                  </div>
                  <div className="panel-body">
                    <p className="p1">Select a Age Restrict Below:</p>

                    <Select
                      options={age_restrict?.map((option) => ({
                        value: option?.id,
                        label: option?.albumname,
                      }))}
                      className="form-control"
                      isMulti
                      value={selectedOptionsage_restrict}
                      onChange={handleSelectChangeage_restrict}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row ">
              <div
                className="panel panel-primary col-sm-6 mt-3"
                data-collapsed="0"
              >
                {" "}
                <div className="panel-heading">
                  <div className="panel-title">
                    <label>Country</label>
                  </div>{" "}
                </div>
                <div className="panel-body">
                  <p className="p1">Block the Audio for Selected Country:</p>
                  {/* <Multiselect
                  isObject={false}
                  // onRemove={(event) => { console.log(event) }}
                  // onSelect={(event) => { console.log(event) }}
                  options={artists}
                  onChange={e => setArtists(e.target.value)}
                  showCheckbox
                  value='1'
                /> */}
                  <Select
                    options={block_country?.map((option) => ({
                      value: option?.id,
                      label: option?.name,
                    }))}
                    className="form-control"
                    isMulti
                    value={selectedOptions}
                    onChange={handleSelectChange}
                  />
                </div>
              </div>

              <div className="col-sm-6 mt-3">
                <div className="">
                  <label className="m-0">Search Tags</label>

                  <div className="panel-body mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="search_tags"
                      onChange={(e) => setSearch_tags(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="panel panel-primary mt-3" data-collapsed="0">
              {" "}
              <div className="panel-heading">
                <div className="panel-title">
                  <label>Audio Details, Links, and Info</label>
                </div>{" "}
              </div>
              <div className="panel-body">
                <textarea
                  className="form-control"
                  name="details"
                  id="details"
                  onChange={(e) => setDetails(e.target.value)}
                ></textarea>
              </div>
            </div>

            <div className="panel panel-primary mt-3" data-collapsed="0">
              {" "}
              <div className="panel-heading">
                <div className="panel-title">
                  <label>Short Description</label>
                </div>{" "}
              </div>
              <div className="panel-body">
                <p className="p1">
                  Add a short description of the audio below:
                </p>
                <textarea
                  className="form-control"
                  name="description"
                  id="description"
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-6">
                <div className="panel panel-primary" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Cast and Crew</label>{" "}
                    </div>{" "}
                  </div>
                  <div className="panel-body">
                    <p>Add artists for the audio below:</p>
                    {/* <Multiselect
                  isObject={false}
                  // onRemove={(event) => { console.log(event) }}
                  // onSelect={(event) => { console.log(event) }}
                  options={artists}
                  onChange={e => setArtists(e.target.value)}
                  showCheckbox
                  value='1'
                /> */}

                    <Select
                      options={artists?.map((option) => ({
                        value: option?.id,
                        label: option?.artist_name,
                      }))}
                      className="form-control"
                      isMulti
                      value={selectedOptionsartists}
                      onChange={handleSelectChangeartists}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="panel panel-primary" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Album</label>
                    </div>{" "}
                  </div>
                  <div className="panel-body">
                    <p className="p1">Select a Audio Album Below:</p>
                    {/* <Multiselect
                  isObject={false}
                  // onRemove={(event) => { console.log(event) }}
                  // onSelect={(event) => { console.log(event) }}
                  options={audio_albums}
                  onChange={e => setAudio_albums(e.target.value)}
                  value='1'
                  showCheckbox
                /> */}
                    {/* <input
                      name="album_id"
                      onChange={(e) => setArtists(e.target.value)}
                    /> */}
                    {/* <Select
                      options={audio_albums?.map((option) => ({
                        value: option?.id,
                        label: option?.albumname,
                      }))}
                      className="form-control"
                      isMulti
                      value={selectedOptionsaudio_albums}
                      onChange={handleSelectChangeaudio_albums}
                    /> */}

                    <select
                      onChange={(e) => setAlbumset(e.target.value)}
                      className="form-control"
                    >
                      <option value="">Choose a Album</option>
                      {album?.map((item, key) => (
                        <option value={item.id}>{item.albumname}</option>
                      ))}
                    </select>
                    {/* <input
                      name="album_id"
                      className="form-control"
                      onChange={(e) => setAlbum_id(e.target.value)}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row p-0 mt-3 align-items-center">
              <div className="col-sm-6">
                <div className="panel panel-primary" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Category</label>
                    </div>{" "}
                    <div className="panel-options">
                      {" "}
                      <Link to="#" data-rel="collapse">
                        <i className="entypo-down-open"></i>
                      </Link>{" "}
                    </div>
                  </div>
                  <div className="panel-body">
                    <p className="p1">Select a Audio Category Below:</p>

                    {/* <Multiselect
                  isObject={false}
                  // onRemove={(event) => { console.log(event) }}
                  // onSelect={(event) => { console.log(event) }}
                  options={category}
                  onChange={e => setCategory(e.target.value)}
                  value='1'
                  showCheckbox
                /> */}

                    <Select
                      options={category?.map((option) => ({
                        value: option?.id,
                        label: option?.name,
                      }))}
                      className="form-control"
                      isMulti
                      value={selectedOptionscategory}
                      onChange={handleSelectChangecategory}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="panel panel-primary" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Audio Ratings</label>
                    </div>{" "}
                  </div>
                  <div className="panel-body">
                    <p className="p1"> IMDB Ratings 10 out of 10</p>
                    <select
                      className="form-control"
                      aria-label="Default select example"
                      onChange={(e) => setRating(e.target.value)}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-sm-6">
                <div className="panel panel-primary" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Language</label>
                    </div>{" "}
                  </div>
                  <div className="panel-body">
                    <p className="p1">Select a Audio Language Below:</p>
                    {/* <Multiselect
                  isObject={false}
                  // onRemove={(event) => { console.log(event) }}
                  // onSelect={(event) => { console.log(event) }}
                  options={language}
                  onChange={e => setLanguage(e.target.value)}
                  value='1'
                  showCheckbox
                /> */}
                    <Select
                      options={language?.map((option) => ({
                        value: option?.id,
                        label: option?.name,
                      }))}
                      className="form-control"
                      isMulti
                      value={selectedOptionslanguage}
                      onChange={handleSelectChangelanguage}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6 ">
                <div className="panel panel-primary" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Audio Year</label>
                    </div>{" "}
                  </div>
                  <div className="panel-body">
                    <p className="p1">Audio Released Year</p>
                    <input
                      className="form-control"
                      name="year"
                      id="year"
                      onChange={(e) => setYear(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="clear"></div>

            <div className="row mt-3 align-items-center">
              <div className="col-sm-6">
                <div className="panel panel-primary" data-collapsed="0">
                  <div className="panel-heading">
                    {" "}
                    <div className="panel-title">
                      <label> Duration</label>
                    </div>{" "}
                  </div>
                  <div className="panel-body">
                    <p className="p1">
                      Enter the audio duration in the following format (Hours :
                      Minutes : Seconds)
                    </p>
                    <input
                      className="form-control"
                      name="duration"
                      id="duration"
                      maxlength="12"
                      // onChange={(e) => setDuration(e.target.value)}
                      value={editUser.duration}
                      onChange={handleInput}
                    />
                  </div>
                </div>
              </div>

              {/* <div className="col-sm-6">
                <div className="panel panel-primary" data-collapsed="0">
                  <div className="panel-body">
                    <label className="m-0">User Access</label>
                    <p className="p1">
                      Who Is Allowed To View This Live Stream ?
                    </p>
                    <Select
                      options={userAccesses}
                      onChange={(e) => handleAccessChange(e)}
                      className="abcd form-control"
                    />
                  </div>
                </div>
              </div> */}

              <div className="col-sm-6">
                <label className="m-0">User Access</label>
                <p className="p1">Who Is Allowed To View This Live Stream ?</p>
                <select
                  className="form-control"
                  onChange={(e) => setUserAccess(e.target.value)}
                  onClick={(event) => {
                    setLivesource(event.target.value);
                  }}
                >
                  {user_access?.map((item, key) => (
                    <option value={item.role}>{item.name}</option>
                  ))}
                </select>
              </div>

              <div>{live_stream_source()}</div>

              <div className="row mt-4">
                <div className="col-sm-6">
                  <div className="row">
                    <div className="col-sm-9">
                      {" "}
                      <label className="p2" for="global_ppv">
                        Is this video Is Global PPV:
                      </label>
                    </div>
                    <div className="col-sm-3">
                      <label className="switch">
                        <input
                          name="ppv_status"
                          onChange={handleInput}
                          defaultChecked={
                            ppv_status.ppv_status == 1 ? true : false
                          }
                          checked={ppv_status.ppv_status == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="ppv_status"
                          onChange={handleInput}
                          value={ppv_status.ppv_status == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="panel panel-primary" data-collapsed="0">
                    <div className="panel-heading">
                      {" "}
                      <div className="panel-title">
                        <label> Status Settings</label>
                      </div>{" "}
                    </div>

                    <div className="row">
                      <div className="col-sm-9">
                        <label className="p2" for="featured">
                          Is this audio Featured:
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <label className="switch">
                          <input
                            name="featured"
                            onChange={handleInput}
                            defaultChecked={
                              featured.featured == 1 ? true : false
                            }
                            checked={featured.featured == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="featured"
                            onChange={handleInput}
                            value={featured.featured == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-9">
                        {" "}
                        <label className="p2" for="banner">
                          Is this Audio display in Banner:
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <label className="switch">
                          <input
                            name="banner"
                            onChange={handleInput}
                            defaultChecked={banner.banner == 1 ? true : false}
                            checked={banner.banner == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="banner"
                            onChange={handleInput}
                            value={banner.banner == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-9">
                        <label className="p2" for="active">
                          Is this audio Active:
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <label className="switch">
                          <input
                            name="active"
                            onChange={handleInput}
                            defaultChecked={active.active == 1 ? true : false}
                            checked={active.active == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="active"
                            onChange={handleInput}
                            value={active.active == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-2 p-2 d-flex justify-content-end">
              <input
                type="submit"
                value="Update"
                onClick={Audio}
                className="btn btn-primary"
              />
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid ">
          <div className="pt-3">
            <div className="row">
              <div className="col-sm-12">
                <div className="iq-card addaudio">
                  <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                      <h4 className="card-title">Add Audio</h4>
                    </div>
                  </div>
                  <hr />

                  <Stepper activeStep={activeStep} orientation="horizontal">
                    {steps?.map((step, index) => (
                      <Step key={step.label}>
                        <StepContent>
                          <Typography>{step.description}</Typography>
                          <div>
                            <div className="text-end mt-3">
                              {/* <button onClick={handleNext} className='btn btn-primary' hidden={index === 1}>
                                Proceed to Next
                              </button> */}
                              {/* <button
                                hidden={index === 0} className='btn btn-primary'
                                onClick={handleBack}
                              >
                                Back
                              </button> */}
                            </div>
                          </div>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Add_NewAudios;
