import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

const AllVideos = () => {
  const allvideos = [
    {
      title: "",
      rating: "",
      uploaded: "",
      type: "",
      status: "",
      views: "",
    },
  ];
  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="pt-3">
              <div className="admin-section-title">
                <div className="iq-card">
                  {/* <div className="row">
                    <div className="col-md-6">
                      <h4>
                        <i className="entypo-archive"></i> Video Lists
                      </h4>
                    </div>

                    <div className="col-md-6" align="right">
                      <Link
                        to="/admin/add-categories"
                        className="btn btn-primary"
                      >
                        <i className="fa fa-plus-circle"></i> Add Movie
                      </Link>
                    </div>
                  </div>
                  <hr /> */}

                  <div class="iq-card-header d-flex justify-content-between align-items-baseline">
                    <div class="iq-header-title">
                      <h4 class="card-title">Video Lists</h4>
                    </div>

                    <div class="iq-card-header-toolbar d-flex align-items-baseline">
                      <label class="p-2">Videos By CPP Users:</label>
                      <div class="form-group mr-2">
                        <select
                          id="cpp_user_videos"
                          name="cpp_user_videos"
                          class="form-control"
                        >
                          <option value="">Select Videos By CPP</option>
                          <option value="cpp_videos">
                            Videos ( Uploaded By CPP Users )
                          </option>
                        </select>
                      </div>
                      <div class="form-group mr-2">
                        <input
                          type="text"
                          name="search"
                          id="search"
                          class="form-control"
                          placeholder="Search Data"
                        />
                      </div>
                      <Link
                        to=""
                        className="btn btn-primary"
                      >
                        <i className="fa fa-plus-circle"></i> Add Movie
                      </Link>
                    </div>
                   
                  </div>
                  <hr />

                  <div
                    className="panel panel-primary category-panel"
                    data-collapsed="0"
                  >
                    <div className="panel-body">
                      <div
                        id="nestable"
                        className="nested-list dd with-margins"
                      >
                        <table
                          className="table table-bordered iq-card text-center"
                          id="categorytbl"
                        >
                          <tr className="table-header r1">
                            <th>TITLE</th>
                            <th>RATING</th>
                            <th>VIDEO UPLOADED BY</th>
                            <th>VIDEO TYPE</th>
                            <th>VIDEO ACCESS</th>
                            <th>STATUS</th>
                            <th>VIEWS</th>
                            <th>ACTION</th>
                          </tr>

                          {allvideos.map((item, key) => (
                            <tr>
                              {/* <td key={key}> {key + 1} </td> */}

                              <td className="title">{item.title}</td>
                              <td>{item.rating}</td>
                              <td>{item.uploaded}</td>
                              <td>{item.type}</td>
                              <td>{item.access}</td>

                              <td className="text-center">{item.status}</td>

                              <td>{item.views}</td>
                              <td>
                                <Link
                                  to={"/admin/editvideo/" + item.id}
                                  className="edit ms-2"
                                >
                                  <i
                                    className="fa fa-pencil-square"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                                <Link to="" className="delete ms-2">
                                  <span>
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </table>

                        <div className="row text-center d-flex">
                          <div className="col-lg-4">
                            <button type="button" className="btn btn-primary ">
                              {"<< Previous"}
                            </button>
                          </div>
                          <div className="col-lg-4">
                            <p className=""></p>
                          </div>
                          <div className="col-lg-4">
                            <button type="button" className="btn btn-primary">
                              {"Next >>"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <input type="hidden" id="_token" name="_token" value="update" />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AllVideos;
