import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";

const Edit_seriesseason = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    id: "",
    user_id: "",
    series_id: "",
    trailer: "",
    image: "",
    access: "",
    ppv_access: "",
    ppv_price: "",
    ios_ppv_price: "",
    ppv_interval: "",
    trailer_type: "",
    ios_product_id: "",
    uploaded_by: "",
  });

  const [trailer, setTrailer] = useState("");
  const [trailer_type, setTrailer_type] = useState("mp4");
  const [trailerimage, setTrailerImage] = useState("");

  // console.log(editUser)
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");
  const [Content_Partner_id, setContent_Partner_id] = useState("1");

  const [order, setOrder] = useState({});
  const [data, setData] = useState([]);
  const [publish, setPublish] = useState([]);
  const [order1, setOrder1] = useState({});
  const [order2, setOrder2] = useState({});
  const [language, setLanguage] = useState([]);

  const [category, setCategory] = useState([]);
  const [useraccess, setUseraccess] = useState([]);
  const [ios_product_id, setIos_product_id] = useState([]);

  // console.log(access_token)

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageSelect = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setSelectedImage(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const [selectedvideo, setSelectedVideo] = useState(null);

  const handleImageSelectvideo = (e) => {
    const file = e.target.files[0];
    setSelectedVideo(file);
  };

  const handleDropvideo = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setSelectedVideo(file);
  };

  const handleDragOvervideo = (e) => {
    e.preventDefault();
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const getUsers = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-season/create`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var InappPurchase = resData?.InappPurchase;
      setIos_product_id(InappPurchase);
      var result = resData?.user_access;
      setUseraccess(result);
      // console.log(InappPurchase);
      // console.log(InappPurchase)
      // console.log(InappPurchase)
    };
    getUsers();

    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-season/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.SeriesSeason[0];
      var result = resData?.user_access;
      setEditUser(res);

      // console.log(res);
      // console.log(res);
      // console.log(res);
      // console.log(result)
    };
    getUser();
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      id: editUser.id,
      user_id: editUser.user_id,
      series_id: editUser.series_id,
      trailer: editUser.trailer,
      image: editUser.image,
      access: editUser.access,
      ppv_price: editUser.ppv_price,
      ppv_interval: editUser.ppv_interval,
      trailer_type: editUser.trailer_type,
      ios_product_id: editUser.ios_product_id,
      uploaded_by: editUser.uploaded_by,
      Content_Partner_id: Content_Partner_id,
      ios_ppv_price: editUser.ios_ppv_price,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-season/update/` + id,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (res.status === 200) {
      setMessage(resjson.success);
      // alert('Success')
      setTimeout(() => {
        // navigate('/admin/series-list');
      }, 2000);

      const formDa = new FormData();

      formDa.append("season_id", id);
      formDa.append("trailer", selectedvideo);
      formDa.append("trailer_type", trailer_type);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-season/Trailer_upload`,
          formDa,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api', response);
          if (response.data.status === true) {
            var result = response.data;
            // console.log("result", result);
            alert("Added successfully  !");
          }
        });

      const formDat = new FormData();

      formDat.append("season_id", id);
      formDat.append("image", selectedImage);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-season/Image_upload`,
          formDat,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api', response);
          if (response.data.status === true) {
            var result = response.data;
            console.log("result", result);
            alert("Added successfully  !");
          }
        });
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="p-2">
              <div className="iq-card">
                <div className="modal-body">
                  <form
                    id="update-cat-form"
                    accept-charset="UTF-8"
                    action=""
                    method="post"
                    enctype="multipart/form-data"
                  >
                    {/* <div className="form-group ">
                      <label className="m-0 ">Season Thumbnail </label>
                      <p className="p1">(16:9 Ratio or 1080 X 1920px)</p>

                      <img
                        src={editUser?.image}
                        className="movie-img"
                        width="200"
                      />

                      <input
                        type="file"
                        multiple="true"
                        className="form-control "
                        name="trailerimage"
                        id="trailerimage"
                        onChange={(e) => setTrailerImage(e.target.files[0])}
                      />
                    </div> */}

                    <div className="form-group">
                      <label>Season Thumbnail</label>
                      <p className="p1">(16:9 Ratio or 1080 X 1920px)</p>
                      <div>
                        <img src={editUser?.image} width="200px" />
                      </div>
                      <div>
                        {selectedImage && (
                          <div className="text-center file_drag_content">
                            {/* <h4>Selected Image:</h4> */}
                            {/* <img
                              src={URL.createObjectURL(selectedImage)}
                              alt="Selected"
                              className="w-25"
                            /> */}
                          </div>
                        )}
                        <div
                          onDrop={handleDrop}
                          onDragOver={handleDragOver}
                          className="add_category_content  mt-3"
                        >
                          <input
                            type="file"
                            name="trailerimage"
                            id="trailerimage"
                            accept="image/*"
                            className="form-control"
                            hidden
                            onChange={handleImageSelect}
                          />
                          <p>Drag and drop an image here to upload.</p>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="m-0">Season Trailer :</label>
                      <div>
                        <media-player
                          width="200px"
                          title={editUser?.ppv_access}
                          src={editUser?.trailer}
                          poster=""
                          controls
                        >
                          <media-outlet width="200px"></media-outlet>
                        </media-player>
                      </div>
                      <div>
                        {selectedvideo && (
                          <div className="text-center file_drag_content ">
                            {/* <h4>Selected Image:</h4> */}

                            {/* <media-player
                          width="200px"
                          src={URL.createObjectURL(selectedvideo)}
                          poster=""
                          controls
                        >
                          <media-outlet width="200px"></media-outlet>
                        </media-player> */}

                            {/* <img
                              src={URL.createObjectURL(selectedvideo)}
                              alt="Selected"
                              className="w-25"
                            /> */}
                          </div>
                        )}
                        <div
                          onDrop={handleDropvideo}
                          onDragOver={handleDragOvervideo}
                          className="add_category_content mt-3"
                        >
                          <input
                            type="file"
                            name="trailerimage"
                            id="trailerimage"
                            accept="video/mp4,video/x-m4v,video/*"
                            className="form-control"
                            hidden
                            onChange={handleImageSelectvideo}
                          />
                          <p>Drag and drop an Video here to upload.</p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-group">
                      <label className="m-0">Season Trailer :</label>
                      <div>
                        <media-player
                          width="200px"
                          title={editUser?.access}
                          src={editUser?.trailer}
                          poster=""
                          controls
                        >
                          <media-outlet width="200px"></media-outlet>
                        </media-player>
                      </div>
                      <div className="form">
                        <input
                          type="file"
                          className="form-control"
                          accept="video/mp4,video/x-m4v,video/*"
                          name="trailer"
                          id="trailer"
                          onChange={(e) => setTrailer(e.target.files[0])}
                        />
                      </div>
                    </div> */}

                    <div className="form-group ">
                      <label className="m-0"> Choose User Access:</label>
                      <select
                        value={editUser?.access}
                        onChange={handleInput}
                        className="form-control"
                        id="access"
                        name="access"
                        
                      >
                        {useraccess?.map((item) => (
                          <option value={item?.value}>{item?.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group " id="ppv_price">
                      <label className="m-0">PPV Price:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="PPV Price"
                        name="ppv_price"
                        id="price"
                        onChange={handleInput}
                        value={editUser?.ppv_price}
                      />
                    </div>
                    <div className="form-group">
                      <label className="m-0">IOS PPV Price:</label>
                      <select
                        value={editUser?.ios_product_id}
                        name="ios_product_id"
                        className="form-control"
                        id="ios_product_id"
                        onChange={handleInput}
                      >
                        {ios_product_id?.map((item) => (
                          <option value={item?.product_id}>
                            {item?.plan_price}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group ">
                      <label className="m-0">PPV Interval:</label>
                      <p className="p1">
                        Please Mention How Many Episodes are Free:
                      </p>
                      <input
                        type="text"
                        id="ppv_interval"
                        name="ppv_interval"
                        value={editUser?.ppv_interval}
                        onChange={handleInput}
                        className="form-control"
                      />
                    </div>

                    <div className="modal-footer form-group">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        id="submit-update-cat"
                        onClick={handleUpdate}
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Edit_seriesseason;
