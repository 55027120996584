import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const Manage_Albums = () => {
  const [managealbum, setManagealbum] = useState([]);

  const [data, setData] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);

  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");
  const [Content_Partner_id, setContent_Partner_id] = useState("1");

  // console.log(allvideos);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audio-Album/List`,
        { Content_Partner_id: Content_Partner_id },
        {
          headers: headers,
        }
      )
      .then((response) => {
        var result = response?.data?.audio_albums?.data;
        setManagealbum(result);
        // console.log(result);
      })
      .catch((error) => console.log(error));
  }, []);

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audio-Album/Delete/` + id,
        {
          headers: headers,
        }
      )
      .then((response) => {
        // alert("Item Deleted Successfully !");
        //  navigate('/allvideo')
        window.location.reload();
      });
  };

  function handlePreClick() {
    // console.log('preview');

    axios
      .post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audio-Album/List?page=${
          page - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data);
        setPrevious(res?.data?.audio_albums?.data);
        setManagealbum(res?.data?.audio_albums?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpage() {
    // console.log('handlefirstpage');

    axios
      .post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audio-Album/List?page=${
          page - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.audio_albums.data);
        setPrevious(res?.data?.audio_albums?.data);
        setManagealbum(res?.data?.audio_albums?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClick() {
    // console.log('next');

    axios
      .post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audio-Album/List?page=${
          page + 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log('next', res.data.audio_albums);
        setNext(res?.data?.audio_albums?.data);
        setManagealbum(res?.data?.audio_albums?.data);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className=" pt-3">
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between align-items-baseline mb-4">
                      <div className="iq-header-title">
                        <h4 className="card-title">Audio Album List</h4>
                      </div>

                      <div className="iq-card-header-toolbar d-flex align-items-center">
                        <Link
                          to="/cpp/add-manage-albums"
                          className="btn btn-primary"
                        >
                          Add Audio Album
                        </Link>
                      </div>
                    </div>

                    {/* <div className="d-flex justify-content-between p-3">
                      <div className="d-flex">
                        <p>Show 10</p>
                        <p>&nbsp; &nbsp; entries</p>
                      </div>
                      <div className="d-flex">
                        <p>Search:</p>
                        <input
                          type="search"
                          id="gsearch"
                          name="gsearch"
                          className="border-0 search-rev"
                        />
                      </div>
                    </div> */}
                    <div className="iq-card-body p-0">
                      <div
                        id="nestable"
                        className="nested-list dd with-margins p-0"
                      >
                        <table
                          className="table-bordered table audio_table iq-card text-center p-0"
                          id="categorytbl"
                        >
                          <thead>
                            <tr className="r1">
                              <th>
                                <label>S.No</label>
                              </th>
                              <th>
                                <label>Name</label>
                              </th>
                              <th>
                                <label>Image</label>
                              </th>
                              <th>
                                <label>Action</label>
                              </th>
                            </tr>
                          </thead>

                          {managealbum?.map((item, key) => (
                            <tbody>
                              <tr>
                                <td key={key}> {key + 1} </td>
                                <td>{item?.albumname}</td>
                                <td>
                                  <img src={item?.image_url} width="150" />
                                </td>
                                <td>
                                  <Link
                                    to={"/cpp/edit-albums/" + item?.id}
                                    className="edit ms-1"
                                  >
                                    <i
                                      className="fa fa-pencil-square"
                                      aria-hidden="true"
                                    ></i>
                                  </Link>
                                  <Link to="" className="delete ms-1">
                                    <span
                                      onClick={() => deleteOperation(item?.id)}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </Link>
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>

                        <div className="row text-center d-flex">
                          <div className="col-lg-4">
                            <button
                              type="button"
                              className="btn btn-primary "
                              onClick={handlePreClick}
                            >
                              {"<< Previous"}
                            </button>
                          </div>
                          <div className="col-lg-4">
                            <p className="">{page}</p>
                          </div>
                          <div className="col-lg-4">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleNxtClick}
                            >
                              {"Next >>"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Manage_Albums;





// edit api multiselect full code final


// import React, { useState, useEffect, } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import Select from 'react-select';

// const Manage_Albums = () => {

//     const { id } = useParams();
  
//     const access_token = localStorage.getItem("access_token");
//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: "Bearer " + access_token,
//     "Content-Type": "application/json",
//     Accept: "application/json",
//     "Access-Control-Allow-Origin": "*",
//   };

//   const [editUser, setEditUser] = useState({
//     title: "",
//     id: "",
//     slug: "",
//     active: "",
//     status: "",
//     url_type: "",
//     mp4_url: "",
//     embed_url: "",
//     m3u_url: "",
//     live_stream_video: "",
//     rtmp_url: "",
//     hls_url: "",
//     Stream_key: "",
//     description: "",
//     details: "",
//     search_tags: "",
//     rating: "",
//     age_restrict: "",
//     duration: "",
//     featured: "",
//     banner: "",
//     year: "",
//     publish_status: "",
//     publish_type: "",
//     publish_time: "",
//     user_id: "",
//     uploaded_by: "",
//     access: "",
//     ppv_price: "",
//     ios_ppv_price: "",
//     enable_restream: "",
//     fb_restream_url: "",
//     fb_streamkey: "",
//     youtube_restream_url: "",
//     youtube_streamkey: "",
//     twitter_restream_url: "",
//     twitter_streamkey: "",
//     linkedin_restream_url: "",
//     linkedin_streamkey: "",
//     video_category_id: "",
//     language: "",
//     footer: "",
//     ads_position: "",
//     live_ads: "",
//     Video_thumbnail: "",
//     Player_thumbnail: "",
//     Video_TV_Thumbnail: "",
//   });
//   const [options, setOptions] = useState([]);
//   const [selectedValues, setSelectedValues] = useState([]);
//   const [formData, setFormData] = useState({
//     country: [],
//     // other form data properties...
//   });

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/Edit/${9}`, {headers:headers});
//       const data = response.data.selected_Audios_block_countries;

//       // Extract the selected data from the response
//       const selectedData = data;
//       const formattedOptions = selectedData.map(item => ({
//         value: item.category_id,
//         label: item.name,
//       }));

//       // Set the initial selected values
//       setSelectedValues(formattedOptions);
      
//       // Set the options for the multi-select component
//       setOptions(formattedOptions);
//     } catch (error) {
//       console.error(error);
//     }
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/create`, {headers:headers});
//       const data = response.data.Block_country;

//       // Extract the selected data from the response
//       const selectedData = data;
//       const formattedOptions = selectedData.map(item => ({
//         value: item.id,
//         label: item.name,
//       }));

      
//       // Set the options for the multi-select component
//       setOptions(formattedOptions);

//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const handleSelectChange = selectedOptions => {
//     const country = selectedOptions.map(option => option.value);
//     const editInputvalue = {
//       title: editUser.title,
//       slug: editUser.slug,
//       active: editUser.active,
//       status: editUser.status,
//       url_type: editUser.url_type,
//       mp4_url: editUser.mp4_url,
//       embed_url: editUser.embed_url,
//       m3u_url: editUser.m3u_url,
//       live_stream_video: editUser.live_stream_video,
//       rtmp_url: editUser.rtmp_url,
//       hls_url: editUser.hls_url,
//       Stream_key: editUser.Stream_key,
//       description: editUser.description,
//       details: editUser.details,
//       search_tags: editUser.search_tags,
//       rating: editUser.rating,
//       age_restrict: editUser.age_restrict,
//       duration: editUser.duration,
//       featured: editUser.featured,
//       banner: editUser.banner,
//       year: editUser.year,
//       publish_status: editUser.publish_status,
//       publish_type: editUser.publish_type,
//       publish_time: editUser.publish_time,
//       user_id: editUser.user_id,
//       uploaded_by: editUser.uploaded_by,
//       access: editUser.access,
//       ppv_price: editUser.ppv_price,
//       ios_ppv_price: editUser.ios_ppv_price,
//       enable_restream: editUser.enable_restream,
//       fb_restream_url: editUser.fb_restream_url,
//       fb_streamkey: editUser.fb_streamkey,
//       youtube_restream_url: editUser.youtube_restream_url,
//       youtube_streamkey: editUser.youtube_streamkey,
//       twitter_restream_url: editUser.twitter_restream_url,
//       twitter_streamkey: editUser.twitter_streamkey,
//       linkedin_restream_url: editUser.linkedin_restream_url,
//       linkedin_streamkey: editUser.linkedin_streamkey,
//       video_category_id: editUser.video_category_id,
//       footer: editUser.footer,
//       language: editUser.language,
//       live_ads: editUser.live_ads,
//       ads_position: editUser.ads_position,
//     };
//     setFormData({
//       ...formData,
//       ...editInputvalue,
//       country: country,
//       title: editUser.title,
//     });
//     setSelectedValues(selectedOptions);
//   };

//   const handleFormSubmit = async event => {
//     event.preventDefault();

//     try {
//       const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Channel/Backend/Livestream-update/` +
//       id, formData, {headers:headers});
//       // Handle the response as needed
//       console.log(response.data);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   return (
//     <div>
//       <h1>Edit Page</h1>
//       <form onSubmit={handleFormSubmit}>
//         <Select
//           options={options}
//           isMulti
//           onChange={handleSelectChange}
//           value={selectedValues}
//         />
//         <button type="submit">Submit</button>
//       </form>
//     </div>
//   );
// };

// export default Manage_Albums;

// edit api multiselect full code final