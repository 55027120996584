import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./Add_Manage_Series.css";

function Add_Manage_Series() {
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [image, setImage] = useState("");
  const [parent_id, setParent_id] = useState("");
  const [in_home, setIn_home] = useState("");
  const [footer, setFooter] = useState("");
  const [banner, setBanner] = useState("");
  const [banner_image, setBanner_image] = useState("");
  const [in_menu, setIn_menu] = useState("");
  const [uploaded_by, setUploaded_by] = useState("");

  // const [series_genre_id, setSeries_genre_id] = useState('');

  const [data, setData] = useState([]);

  const [category, setCategory] = useState([]);
  const [artist, setArtists] = useState([]);

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");
  const [Content_Partner_id, setContent_Partner_id] = useState("1");

  const handleInput = (e) => {
    if (e.target.checked === true) {
      setIn_home({ ...in_home, [e.target.name]: 1 });
      setFooter({ ...footer, [e.target.name]: 1 });
      setIn_menu({ ...in_menu, [e.target.name]: 1 });
    } else {
      setIn_home({ ...in_home, [e.target.name]: 0 });
      setFooter({ ...footer, [e.target.name]: 0 });
      setIn_menu({ ...in_menu, [e.target.name]: 0 });
    }
  };

  async function series_genre() {
    const editInputin_home = in_home.in_home;
    var datain_home = JSON.stringify(editInputin_home);
    var in_homedata = datain_home;

    const editInputfooter = footer.footer;
    var datafooter = JSON.stringify(editInputfooter);
    var footerdata = datafooter;

    const editInputin_menu = in_menu.in_menu;
    var datain_menu = JSON.stringify(editInputin_menu);
    var in_menudata = datain_menu;

    const formData = new FormData();

    formData.append("parent_id", parent_id);
    formData.append("name", name);
    formData.append("slug", slug);
    formData.append("in_home", in_homedata);
    formData.append("footer", footerdata);
    formData.append("in_menu", in_menudata);
    formData.append("uploaded_by", uploaded_by);
    formData.append("Content_Partner_id", Content_Partner_id);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-category/store`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        console.log("api", response);
        if (response.data.status === true) {
          var result = response.data;
          console.log("result", response);
          // navigate('/cpp/series-list')
          // alert("Added successfully  !")
          var SeriesGenre_id = response.data.SeriesGenre_id;
          // var resssss = response.data;
          // setSeries_genre_id(series_id)
          console.log("resssss", SeriesGenre_id);
          // console.log('series_id', series_id);

          const formDatas = new FormData();

          // formDatas.append('series_id', series_genre_id);
          formDatas.append("image", image);
          formDatas.append("banner_image", banner);
          formDatas.append("series_genre_id", SeriesGenre_id);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-category/Image-upload`,
              formDatas,
              { headers: headers }
            )
            .then((response) => {
              console.log("api", response);
              if (response.data.status === true) {
                var result = response.data;
                // console.log('result', response);
                // navigate('/cpp/all-artist')
                alert("Added successfully  !");
              } else {
                console.log("Error");
                alert("Enter Correct Details");
              }
            });
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/CPP/Backend/series-category/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.Publish_type)
        var result = response?.data?.Series_Genre;
        // var resultss = response.data.SeriesGenre[0].name
        setData(result);
        console.log('result', result)
        // console.log('SeriesGenre', result);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="AddManageSeries container-fluid">
            <div className="modal-content p-2 mt-3">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  New Series Genre
                </h5>
              </div>
              <div className="modal-body">
                {/* <input type="hidden" name="parent_id" onChange={e => setParent_id(e.target.value)} /> */}

                <div className="form-group ">
                  <label>Name:</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="form-control"
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter name"
                  />

                  <span className="text-red" role="alert">
                    <strong></strong>
                  </span>
                </div>

                <div className="mt-3 form-group">
                  <label>Slug:</label>
                  <input
                    type="text"
                    id="slug"
                    name="slug"
                    onChange={(e) => setSlug(e.target.value)}
                    className="form-control"
                    placeholder="Enter Slug"
                  />

                  <span className="text-red" role="alert">
                    <strong></strong>
                  </span>
                </div>
                {/* <input type="hidden" name="in_home" onChange={e => setIn_home(e.target.value)} />
								<input type="hidden" name="footer" onChange={e => setFooter(e.target.value)} /> */}

                <div className="col-lg-12 row mt-3 form-group">
                  <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                    <label>Display In Menu :</label>
                  </div>
                  <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                    <label className="switch">
                      <input
                        name="in_menu"
                        onChange={handleInput}
                        defaultChecked={in_menu?.in_menu === 1 ? true : false}
                        checked={in_menu?.in_menu === 1 ? true : false}
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="in_menu"
                        onChange={handleInput}
                        value={in_menu?.in_menu === 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                </div>
                {/* <div className="mt-3">
                  <label>Display In Menu :</label>
                  
                  <label className="switch">
                    <input
                      name="in_menu"
                      onChange={handleInput}
                      defaultChecked={in_menu?.in_menu === 1 ? true : false}
                      checked={in_menu?.in_menu === 1 ? true : false}
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="in_menu"
                      onChange={handleInput}
                      value={in_menu?.in_menu === 1 ? "1" : "0"}
                    ></span>
                  </label>
                </div> */}

                <div className="col-lg-12 row mt-3 form-group">
                  <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                    <label>Display In Home :</label>
                  </div>
                  <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                    <label className="switch">
                      <input
                        name="in_home"
                        onChange={handleInput}
                        defaultChecked={in_home?.in_home === 1 ? true : false}
                        checked={in_home?.in_home === 1 ? true : false}
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="in_home"
                        onChange={handleInput}
                        value={in_home?.in_home === 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                </div>

                {/* <div className="mt-3">
                  <label>Display In Home :</label>
                 

                  <label className="switch">
                    <input
                      name="in_home"
                      onChange={handleInput}
                      defaultChecked={in_home?.in_home === 1 ? true : false}
                      checked={in_home?.in_home === 1 ? true : false}
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="in_home"
                      onChange={handleInput}
                      value={in_home?.in_home === 1 ? "1" : "0"}
                    ></span>
                  </label>
                </div> */}

                <div className="col-lg-12 row mt-3 form-group">
                  <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                    <label>Display In Footer :</label>
                  </div>
                  <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                    <label className="switch">
                      <input
                        name="footer"
                        onChange={handleInput}
                        defaultChecked={footer?.footer === 1 ? true : false}
                        checked={footer?.footer === 1 ? true : false}
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="footer"
                        onChange={handleInput}
                        value={footer?.footer === 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                </div>

                {/* <div className="mt-3">
                  <label>Display In Footer :</label>
              
                  <label className="switch">
                    <input
                      name="footer"
                      onChange={handleInput}
                      defaultChecked={footer?.footer === 1 ? true : false}
                      checked={footer?.footer === 1 ? true : false}
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="footer"
                      onChange={handleInput}
                      value={footer?.footer === 1 ? "1" : "0"}
                    ></span>
                  </label>
                </div> */}

                <div className="mt-3 form-group">
                  <label>Image:</label>
                  <input
                    type="file"
                    className="form-control"
                    name="image"
                    onChange={(e) => setImage(e.target.files[0])}
                    id="image"
                  />
                </div>
                {/* <div className="mt-3 form-group">
                  <label>Banner Image:</label>
                  <input
                    type="file"
                    className="form-control"
                    name="banner"
                    onChange={(e) => setBanner(e.target.files[0])}
                    id="banner"
                  />
                </div> */}

                <div className=" mt-3 form-group">
                  <label>Category:</label>
                  <select
                    id="parent_id"
                    name="parent_id"
                    className="form-control"
                    onChange={(e) => setParent_id(e.target.value)}
                  >
                    <option value="">Select Category</option>
                    {data?.map((item) => (
                      <option value={item?.id}>{item?.name}</option>
                    ))}
                  </select>
                </div>

                <div className="modal-footer form-group">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={series_genre}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Add_Manage_Series;
