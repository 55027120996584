import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const Edit_Manage_VideoCategory = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    id: "",
    user_id: "",
    name: "",
    parent_id: "",
    image: "",
    order: "",
    slug: "",
    in_home: "",
    banner: "",
    banner_image: "",
    in_menu: "",
    home_genre: "",
  });

  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");
  const [Content_Partner_id, setContent_Partner_id] = useState("1");

  const [image, setImage] = useState("");
  const [banner_image, setBanner_image] = useState("");

  const [data, setData] = useState([]);

  const [category, setCategory] = useState([]);
  const [useraccess, setUseraccess] = useState([]);

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/videos-category/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.VideoCategory;
      setEditUser(res);
      //   setUseraccess(result);
      // console.log(res)
      // console.log(res)
      //   console.log(result)
      //   console.log(res);
    };
    getUser();

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/videos-category/create`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        var result = response?.data?.Video_Category;
        setCategory(result);
        // console.log(result)
        // console.log(result)
      })
      .catch((error) => console.log(error));
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 1 });
      setEditUser({
        ...editUser,
        [e.target.name]: e.target.value,
        [e.target.name]: 1,
      });
    } else {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 0 });
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      id: editUser.id,
      user_id: editUser.user_id,
      name: editUser.name,
      slug: editUser.slug,
      in_home: editUser.in_home,
      banner: editUser.banner,
      in_menu: editUser.in_menu,
      home_genre: editUser.home_genre,
      parent_id: editUser.parent_id,
      uploaded_by: editUser.uploaded_by,
      Content_Partner_id: Content_Partner_id,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/CPP/Backend/videos-category/update/` +
        id,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (res.status === 200) {
      setMessage(resjson.success);
      // alert('Success')
      setTimeout(() => {
        // navigate('/admin/series-list');
      }, 2000);

      const formDat = new FormData();

      formDat.append("image", image);
      formDat.append("banner_image", banner_image);
      formDat.append("VideoCategory_id", id);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/videos-category/image-upload`,
          formDat,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api', response);
          if (response.data.status == true) {
            var result = response.data;
            console.log("result", result);
            alert("Added successfully  !");
          }
        });
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="pt-3">
              <div className="admin-section-title">
                <div className="iq-card">
                  <div className="row">
                    <div className="col-md-6">
                      <h4>
                        <i className=""></i> Edit Video Categories
                      </h4>
                    </div>
                  </div>
                  <hr />

                  <div className="">
                    <div>
                      <div className="form-group ">
                        <label>Name:</label>

                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={editUser?.name}
                          onChange={handleInput}
                          className="form-control"
                          placeholder="Enter Name"
                        />
                      </div>

                      <div className="form-group ">
                        <label>Slug:</label>

                        <input
                          type="text"
                          id="slug"
                          name="slug"
                          value={editUser?.slug}
                          onChange={handleInput}
                          className="form-control"
                          placeholder="Enter Slug"
                        />
                      </div>

                      <div className="form-group ">
                        <label>Display In Home page:</label>
                        <label className="switch">
                          <input
                            name="in_home"
                            id="in_home"
                            onChange={handleInput}
                            defaultChecked={
                              editUser?.in_home == 1 ? true : false
                            }
                            checked={editUser?.in_home == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="in_home"
                            id="in_home"
                            onChange={handleInput}
                            value={editUser.in_home == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>

                      <div className="form-group ">
                        <label>Display In Menu :</label>

                        <label className="switch">
                          <input
                            name="in_menu"
                            id="in_menu"
                            onChange={handleInput}
                            defaultChecked={
                              editUser?.in_menu == 1 ? true : false
                            }
                            checked={editUser?.in_menu == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="in_menu"
                            id="in_menu"
                            onChange={handleInput}
                            value={editUser?.in_menu == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                      <div className="form-group ">
                        <label>Image:</label>
                        <div>
                          <img
                            src={editUser?.image_url}
                            alt="image"
                            width={200}
                            height={200}
                          />
                        </div>
                        <input
                          type="file"
                          multiple="true"
                          className="form-control"
                          name="image"
                          onChange={(e) => setImage(e.target.files[0])}
                          id="image"
                        />
                      </div>

                      <div className="col-md-12 mb-3">
                        <label>Category:</label>
                        <select
                          className="form-control"
                          name="parent_id"
                          id="parent_id"
                          value={editUser?.parent_id}
                          onChange={handleInput}
                        >
                          {category?.map((item) => (
                            <option value={item?.parent_id}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className=" text-end form-group">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          id="submit-new-cat"
                          onClick={handleUpdate}
                        >
                          Update Category
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Edit_Manage_VideoCategory;
