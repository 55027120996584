import React from 'react'
import Multiselect from "multiselect-react-dropdown";


const Edit_Manage_LiveEvent = () => {

    const options = [
        {
          value: 0,
          text: "Drama",
        },
        {
          value: 1,
          text: "News",
        },
        {
          value: 2,
          text: "Kids",
        },
        {
          value: 3,
          text: "Music",
        },
      ];
      
  return (
    <div>
        <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 p-2">
              <div className="card ">
                <div className="card-body">
                  <div className="container-fluid p-0">
                    <div className="p-3">
                      <div>
                        <h4 className="">Edit Live Events For Artist</h4>
                        <hr />

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Title</label>
                            <p className="">
                              Add the Live stream title in the textbox below:
                            </p>
                            <div>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Title"
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <label className="m-0">Slug</label>
                            <p className="">
                              Add the Live stream slug in the textbox below:
                            </p>
                            <div>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="slug"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start align-items-center">
                          <div className="col-md-6">
                            <label className="m-0">Video Image Cover</label>
                            <p className="p1">
                              Select The Video Image ( 9:16 Ratio Or 1080X1920px
                              ):
                            </p>
                            <div>
                              <input
                                type="file"
                                className="form-control"
                                placeholder="Live_stream_video"
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="row">
                              <label className="m-0">Player Image Cover</label>
                              <p className="">
                                Select The Video Image( 16:9 Ratio Or 1280X720px
                                ):
                              </p>
                              <div>
                                <input
                                  type="file"
                                  className="form-control"
                                  placeholder="player_image"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Video Source</label>
                            <p className="p1">
                              Select The Live Stream Source :
                            </p>
                            <div className="">
                              <div>
                                <select className="form-select url_type">
                                  <option defaultValue="0" selected>
                                    Choose URL Format
                                  </option>
                                  <option value="mp4" selected>
                                    mp4
                                  </option>
                                  <option value="Embed_url">Embed_url</option>
                                  <option value="Mp3">Mp3</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <label className="m-0">TV Image Cover</label>
                            <div className="new-video-upload" id="">
                              <p className="p1">
                                Select The Live Stream Image (1920 X 1080 Or
                                16:9 Ratio) :
                              </p>
                              <input
                                type="file"
                                className="form-control"
                                placeholder="Tv_live_image"
                              />
                            </div>
                            <div></div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Enable ReStream</label>
                            <div className="mt-1">
                              <label className="switch">
                                <input
                                  name="enable_restream"
                                  className="enable_restream"
                                  id="enable_restream"
                                  type="checkbox"
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                            <div>
                              {/* <select className="form-select url_type mt-3">
                                
                              
                                <option defaultValue="0">Choose Enable</option>
                                <option value="1" >Yes</option>
                                <option value="0">No</option>
                              </select> */}
                            </div>
                            <div></div>
                          </div>
                          <div className="col-sm-6">
                            <label className="m-0">Enable Tips</label>
                            <div className="mt-1">
                              <label className="switch">
                                <input
                                  name="enable_restream"
                                  className="enable_restream"
                                  id="enable_restream"
                                  type="checkbox"
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Search Tags</label>
                            <div className="">
                              <input
                                className="form-control me-2"
                                type="search"
                                placeholder="search_tags"
                                aria-label="Search"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <label className="m-0">Donations label</label>
                            <div className="">
                              <input
                                className="form-control me-2"
                                type="search"
                                placeholder="Donations label"
                                aria-label="Search"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3 text-start">
                          <div className="col-md-6">
                            <label className="m-0">Short Description</label>
                            <p className="">
                              Add a short description of the Livestream below:
                            </p>
                            <div className="">
                              <textarea
                                className="form-control"
                                placeholder="Description"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <label className="m-0">Enable Chat</label>
                            <div className="mt-1">
                              <label className="switch">
                                <input
                                  name="enable_restream"
                                  className="enable_restream"
                                  id="enable_restream"
                                  type="checkbox"
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">
                              Video Details, Links, and Info
                            </label>
                            <div className="">
                              <input
                                className="form-control me-2"
                                type="search"
                                placeholder=""
                                aria-label="Search"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6"></div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Category</label>
                            <p className="p1">
                              Select A Live Stream Category Below:
                            </p>

                            <div className="">
                              <div className="text-dark">
                                <Multiselect
                                  options={options}
                                  displayValue="text"
                                  showCheckbox
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <label className="m-0">Language</label>
                            <p className="p1">
                              Select A Live Stream Language Below:
                            </p>
                            <div className="">
                              <div className="text-dark">
                                <Multiselect
                                  options={options}
                                  displayValue="text"
                                  showCheckbox
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Video Ratings</label>
                            <p className="p1">
                              Livestream Ratings 10 Out Of 10
                            </p>

                            <div className="">
                              <div className="text-dark">
                                <Multiselect
                                  options={options}
                                  displayValue="text"
                                  showCheckbox
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <label className="m-0">Video Year</label>
                            <p className="p1">Video Released Year</p>
                            <div className="">
                              <div className="text-dark">
                                <div className="">
                                  <input
                                    className="form-control me-2"
                                    type="search"
                                    placeholder=""
                                    aria-label="Search"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Duration</label>
                            <p className="p1">
                              Enter The Video Duration In (HH : MM : SS)
                            </p>
                            <div>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="duration"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <label className="m-0">User Access</label>
                            <p className="p1">
                              Who Is Allowed To View This Video?
                            </p>
                            <div>
                              <select
                                id="in_name"
                                name="in_name"
                                className="form-control"
                              >
                                <option value="0">Guest (everyone)</option>
                                <option value="1">
                                  Subscriber (only paid subscription user)
                                </option>
                                <option value="0">
                                  PPV Users (Pay per movie)
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-sm-4">
                            <label className="m-0">Publish Type</label>
                            <div className="p2">
                              <div class="form-check  mt-2 ms-3">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                />
                                <label
                                  class="form-check-label"
                                  for="flexRadioDefault1"
                                >
                                  Publish Now
                                </label>
                              </div>
                              <div class="form-check  mt-2 ms-3">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                />
                                <label
                                  class="form-check-label"
                                  for="flexRadioDefault1"
                                >
                                  Publish Later
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-3"></div>

                          <div className="col-sm-5" id="publishlater">
                            <label className="m-0">Status Settings</label>
                            <div>
                              <p className="p2">
                                Is this Live stream Featured:
                                <label className="switch">
                                  <input
                                    name="featured"
                                    className="featured form-group"
                                    id="featured"
                                    type="checkbox"
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </p>
                            </div>
                            <div>
                              <p className="p2">
                                Is this Live stream Active:
                                <label className="switch">
                                  <input
                                    name="active"
                                    className="active form-group"
                                    id="active"
                                    type="checkbox"
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </p>
                            </div>

                            <div>
                              <p className="p2">
                                Is this Live stream display in Banner:
                                <label className="switch">
                                  <input
                                    name="banner"
                                    className="banner form-group"
                                    id="banner"
                                    type="checkbox"
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="text-end">
                          <button className="btn btn-primary">Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default Edit_Manage_LiveEvent


// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom'; // Assuming you're using React Router
// import Select from 'react-select';
// import axios from 'axios';


// function EditManageLiveEvent() {
//   const { id } = useParams(); // Retrieve the value from the URL parameter

//   const [options, setOptions] = useState([]);
//   const [selectedValues, setSelectedValues] = useState([]);
//   const [editUser, setEditUser] = useState({
//     id: ""
//   });

//     const access_token = localStorage.getItem("access_token");
//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: "Bearer " + access_token,
//     "Content-Type": "application/json",
//     Accept: "application/json",
//     "Access-Control-Allow-Origin": "*",
//   };


//   useEffect(() => {
//     axios.get(`${process.env.REACT_APP_Baseurl}/admin/videos-create`, {headers:headers})
//       .then(response => {
//         const apiOptions = response.data.Available_country.map(item => ({
//           value: item.id,
//           label: item.country_name
//         }));
//         setOptions(apiOptions);
//       })
//       .catch(error => {
//         console.error('Error fetching data:', error);
//       });

//     axios.get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/Livestream-edit/${id}`)
//       .then(response => {
//         const userData = response.data.selected_category;
//         setEditUser(userData);console.log(userData)
//         setSelectedValues(userData.codes);
//       })
//       .catch(error => {
//         console.error('Error fetching user data:', error);
//       });
//   }, [id]);

//   const handleSelectChange = selectedOptions => {
//     setSelectedValues(selectedOptions);
//   };

//   const handleSubmit = () => {
//     const selectedValuesArray = selectedValues.map(option => option.value);
//     axios.post('your-api-url', { id: id, codes: selectedValuesArray })
//       .then(response => {
//         console.log('POST request successful:', response.data);
//       })
//       .catch(error => {
//         console.error('Error updating code array:', error);
//       });
//   };

//   // useEffect(() => {
//   //   axios.get(`${process.env.REACT_APP_Baseurl}/admin/videos-create`, {headers:headers})
//   //     .then(response => {
//   //       const apiOptions = response.data.Available_country.map(item => ({
//   //         value: item.id,
//   //         label: item.country_name
//   //       }));
//   //       setOptions(apiOptions);
//   //     })
//   //     .catch(error => {
//   //       console.error('Error fetching data:', error);
//   //     });

//   //   axios.get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/Livestream-edit/${id}`)
//   //     .then(response => {
//   //       const userData = response.data.livestream_category;
//   //       setSelectedValues(userData);
//   //       console.log(userData)
//   //     })
//   //     .catch(error => {
//   //       console.error('Error fetching user data:', error);
//   //     });
//   // }, [id]);

//   // const handleSelectChange = selectedOptions => {
//   //   setSelectedValues(selectedOptions);
//   // };

//   // const handleSubmit = () => {
//   //   const selectedValuesArray = selectedValues.map(option => option.value);
//   //   axios.post('your-api-url', { codes: selectedValuesArray })
//   //     .then(response => {
//   //       console.log('POST request successful:', response.data);
//   //     })
//   //     .catch(error => {
//   //       console.error('Error updating code array:', error);
//   //     });
//   // };

//   return (
//     <div>
//       <Select
//         options={options}
//         isMulti
//         onChange={handleSelectChange}
//         value={selectedValues}
//       />
//       <button onClick={handleSubmit}>Submit</button>
//     </div>
//   );
// }
// export default EditManageLiveEvent;






// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom'; // Assuming you're using React Router
// import Select from 'react-select';
// import axios from 'axios';

// function EditManageLiveEvent() {
//   const [data1, setData1] = useState(null);
//   const [data2, setData2] = useState([]);
//   const [selectedValues, setSelectedValues] = useState([]);
//   const { id } = useParams();
  
//     const access_token = localStorage.getItem("access_token");
//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: "Bearer " + access_token,
//     "Content-Type": "application/json",
//     Accept: "application/json",
//     "Access-Control-Allow-Origin": "*",
//   };


//   useEffect(() => {

//     axios.get(`${process.env.REACT_APP_Baseurl}/admin/videos-create`, {headers:headers})
//       .then(response => {
//         setData2(response.data.user_access);
//         console.log(response.data.user_access)

//       })
//       .catch(error => {
//         console.error('Error fetching data from API2:', error);
//       });
      
//     // Fetch data from the first API using ID
//     axios.get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/Livestream-edit/${id}`, {headers:headers})
//       .then(response => {
//         setData1(response.data.user_access);
//         console.log(response.data.user_access)
//         console.log(response.data.user_access)
//         console.log(response.data.user_access, "user_access")
//       })
//       .catch(error => {
//         console.error('Error fetching data from API1:', error);
//       });

//     // Fetch data from the second API
    
//   }, []);

//   const handleSelectChange = selectedOptions => {
//     setSelectedValues(selectedOptions);
//   };

//   const handleSubmit = () => {
//     const selectedValuesArray = selectedValues.map(option => option.value);
//     axios.post('your-api-url', { data1: data1, data2: selectedValuesArray })
//       .then(response => {
//         console.log('POST request successful:', response.data);
//       })
//       .catch(error => {
//         console.error('Error sending POST request:', error);
//       });
//   };

//   return (
//     <div>
//       {/* Render data1 and data2 as needed */}
//       {data1 && (
//         <div>
//           <h1>{data1.title}</h1>
//           {/* Render other properties of data1 */}
//         </div>
//       )}

//       {data2 && (
//         <div>
//           <Select
//             options={data2}
//             isMulti
//             onChange={handleSelectChange}
//             value={selectedValues}
//           />
//           <button onClick={handleSubmit}>Submit</button>
//         </div>
//       )}
//     </div>
//   );
// }

// export default EditManageLiveEvent;













// edit api multiselect full code final


// import React, { useState, useEffect, } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import Select from 'react-select';

// const EditManageLiveEvent = () => {

//     const { id } = useParams();
  
//     const access_token = localStorage.getItem("access_token");
//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: "Bearer " + access_token,
//     "Content-Type": "application/json",
//     Accept: "application/json",
//     "Access-Control-Allow-Origin": "*",
//   };

//   const [editUser, setEditUser] = useState({
//     title: "",
//     id: "",
//     slug: "",
//     active: "",
//     status: "",
//     url_type: "",
//     mp4_url: "",
//     embed_url: "",
//     m3u_url: "",
//     live_stream_video: "",
//     rtmp_url: "",
//     hls_url: "",
//     Stream_key: "",
//     description: "",
//     details: "",
//     search_tags: "",
//     rating: "",
//     age_restrict: "",
//     duration: "",
//     featured: "",
//     banner: "",
//     year: "",
//     publish_status: "",
//     publish_type: "",
//     publish_time: "",
//     user_id: "",
//     uploaded_by: "",
//     access: "",
//     ppv_price: "",
//     ios_ppv_price: "",
//     enable_restream: "",
//     fb_restream_url: "",
//     fb_streamkey: "",
//     youtube_restream_url: "",
//     youtube_streamkey: "",
//     twitter_restream_url: "",
//     twitter_streamkey: "",
//     linkedin_restream_url: "",
//     linkedin_streamkey: "",
//     video_category_id: "",
//     language: "",
//     footer: "",
//     ads_position: "",
//     live_ads: "",
//     Video_thumbnail: "",
//     Player_thumbnail: "",
//     Video_TV_Thumbnail: "",
//   });
//   const [options, setOptions] = useState([]);
//   const [selectedValues, setSelectedValues] = useState([]);
//   const [formData, setFormData] = useState({
//     category_id: [],
//     // other form data properties...
//   });

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/Livestream-edit/${id}`, {headers:headers});
//       const data = response.data.selected_category;

//       // Extract the selected data from the response
//       const selectedData = data;
//       const formattedOptions = selectedData.map(item => ({
//         value: item.category_id,
//         label: item.name,
//       }));

//       // Set the initial selected values
//       setSelectedValues(formattedOptions);
      
//       // Set the options for the multi-select component
//       setOptions(formattedOptions);
//     } catch (error) {
//       console.error(error);
//     }
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/Livestream-Create`, {headers:headers});
//       const data = response.data.livestream_category;

//       // Extract the selected data from the response
//       const selectedData = data;
//       const formattedOptions = selectedData.map(item => ({
//         value: item.id,
//         label: item.name,
//       }));

      
//       // Set the options for the multi-select component
//       setOptions(formattedOptions);

//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const handleSelectChange = selectedOptions => {
//     const category_id = selectedOptions.map(option => option.value);
//     const editInputvalue = {
//       title: editUser.title,
//       slug: editUser.slug,
//       active: editUser.active,
//       status: editUser.status,
//       url_type: editUser.url_type,
//       mp4_url: editUser.mp4_url,
//       embed_url: editUser.embed_url,
//       m3u_url: editUser.m3u_url,
//       live_stream_video: editUser.live_stream_video,
//       rtmp_url: editUser.rtmp_url,
//       hls_url: editUser.hls_url,
//       Stream_key: editUser.Stream_key,
//       description: editUser.description,
//       details: editUser.details,
//       search_tags: editUser.search_tags,
//       rating: editUser.rating,
//       age_restrict: editUser.age_restrict,
//       duration: editUser.duration,
//       featured: editUser.featured,
//       banner: editUser.banner,
//       year: editUser.year,
//       publish_status: editUser.publish_status,
//       publish_type: editUser.publish_type,
//       publish_time: editUser.publish_time,
//       user_id: editUser.user_id,
//       uploaded_by: editUser.uploaded_by,
//       access: editUser.access,
//       ppv_price: editUser.ppv_price,
//       ios_ppv_price: editUser.ios_ppv_price,
//       enable_restream: editUser.enable_restream,
//       fb_restream_url: editUser.fb_restream_url,
//       fb_streamkey: editUser.fb_streamkey,
//       youtube_restream_url: editUser.youtube_restream_url,
//       youtube_streamkey: editUser.youtube_streamkey,
//       twitter_restream_url: editUser.twitter_restream_url,
//       twitter_streamkey: editUser.twitter_streamkey,
//       linkedin_restream_url: editUser.linkedin_restream_url,
//       linkedin_streamkey: editUser.linkedin_streamkey,
//       video_category_id: editUser.video_category_id,
//       footer: editUser.footer,
//       language: editUser.language,
//       live_ads: editUser.live_ads,
//       ads_position: editUser.ads_position,
//     };
//     setFormData({
//       ...formData,
//       ...editInputvalue,
//       category_id: category_id,
//       title: editUser.title,
//     });
//     setSelectedValues(selectedOptions);
//   };

//   const handleFormSubmit = async event => {
//     event.preventDefault();

//     try {
//       const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Channel/Backend/Livestream-update/` +
//       id, formData, {headers:headers});
//       // Handle the response as needed
//       console.log(response.data);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   return (
//     <div>
//       <h1>Edit Page</h1>
//       <form onSubmit={handleFormSubmit}>
//         <Select
//           options={options}
//           isMulti
//           onChange={handleSelectChange}
//           value={selectedValues}
//         />
//         <button type="submit">Submit</button>
//       </form>
//     </div>
//   );
// };

// export default EditManageLiveEvent;

// edit api multiselect full code final