import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-select";
import { TagsInput } from "react-tag-input-component";
import { AiFillYoutube } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import JoditEditor from "jodit-react";

const Add_NewLiveVideo = () => {
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [url_type, setUrl_type] = useState("");
  const [image, setImage] = useState("");
  const [mp4_url, setMp4_url] = useState("");
  const [embed_url, setEmbed_url] = useState("");
  const [m3u_url, setM3u_url] = useState("");
  const [live_stream_video, setLive_stream_video] = useState("");
  const [encode_stream_video, setEncode_stream_video] = useState("");
  const [details, setDetails] = useState("");
  const [rating, setRating] = useState("");
  const [year, setYear] = useState("");
  const [duration, setDuration] = useState("");
  const [access, setAccess] = useState("");
  const [publish_type, setPublish_type] = useState("");
  // console.log(publish_type, "pp");
  const [publish_time, setPublish_time] = useState("");
  const [user_access, setUser_access] = useState([]);
  const [inapppurchase, setInappPurchase] = useState([]);

  const [enable_restream, setEnable_restream] = useState("");
  // console.log(enable_restream, "ee");
  const [showModal, setShowModal] = useState(false);
  const [selectedButtons, setSelectedButtons] = useState([]);
  const [showInputs, setShowInputs] = useState(false);
  const [featured, setFeatured] = useState(0);
  // console.log(featured, "featured");

  const [banner, setBanner] = useState(0);
  // console.log(banner, "banner");
  const [active, setActive] = useState(0);
  const [player_image, setPlayer_image] = useState("");
  const [Tv_image, setTv_Image] = useState("");
  const [livestream_id, setLivestream_id] = useState("");
  const [search_tags, setSearch_tags] = useState("");
  // console.log(search_tags, "ser");
  const [ppv_price, setPpv_price] = useState("");
  const [ios_ppv_price, setIos_ppv_price] = useState("");
  // console.log(ios_ppv_price, "ios_ppv_price");
  const [fb_restream_url, setFb_restream_url] = useState("");
  const [youtube_restream_url, setYoutube_restream_url] = useState("");
  const [twitter_restream_url, setTwitter_restream_url] = useState("");
  const [fb_streamkey, setFb_streamkey] = useState("");
  const [youtube_streamkey, setYoutube_streamkey] = useState("");
  const [twitter_streamkey, setTwitter_streamkey] = useState("");
  const [description, setDescription] = useState("");

  const [livestream_source, setLivestream_source] = useState([]);
  // console.log(livestream_source);

  const [order, setOrder] = useState({});
  const [data, setData] = useState([]);
  const [publish, setPublish] = useState([]);
  const [order1, setOrder1] = useState({});
  const [order2, setOrder2] = useState({});
  const [order3, setOrder3] = useState({});
  const [order4, setOrder4] = useState({});

  const [artist, setArtist] = useState([]);
  const [art, setArt] = useState([]);
  const [cate, setCate] = useState([]);

  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [age_res, setAge_res] = useState([]);
  // console.log(age_res, "age_res");
  const [blockCountries, setBlockCountries] = useState([]);
  const [selectedBlockCountries, setSelectedBlockCountries] = useState([]);
  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);

  // const [secondOptions, setSecondOptions] = useState([]);
  // console.log(secondOptions, "live_ads");
  const [live_ads, setLive_ads] = useState("");
  // console.log(live_ads, "live_ads");

  const [userAccesses, setUserAccesses] = useState([]);
  const [userAccessesValue, setUserAccessesValue] = useState([]);
  const handleAccessChange = (e) => {
    setUserAccessesValue(e);
  };
  // console.log(userAccessesValue, "userAccess");
  // const [selectedUserAccess, setSelectedUserAccess] = useState(null);
  // console.log(selectedUserAccess, "ll");

  // console.log(language, "language");

  // Multiselect
  const [block_country, setBlock_Country] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [language, setLanguage] = useState([]);
  const [selectedOptionslanguage, setSelectedOptionslanguage] = useState([]);

  const [artists, setArtists] = useState([]);
  const [selectedOptionsartists, setSelectedOptionsartists] = useState([]);

  const [category, setCategory] = useState([]);
  const [selectedOptionscategory, setSelectedOptionscategory] = useState([]);

  const [audio_albums, setAudio_albums] = useState([]);
  const [selectedOptionsaudio_albums, setSelectedOptionsaudio_albums] =
    useState([]);

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-Create`,
        { headers: headers }
      );
      setBlock_Country(response.data.Block_Country);
      setLanguage(response.data.livestream_language);
      setArtists(response.data.livestream_artist);
      setUser_access(response.data.user_access);
      setInappPurchase(response.data.InappPurchase);
      setCategory(response.data.livestream_category);
      console.log(response.data.livestream_category);
      console.log(response.data.livestream_category);
      // console.log(response.data.audio_albums);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const handleSelectChangeaudio_albums = (selectedValues) => {
    setSelectedOptionsaudio_albums(selectedValues);
  };

  const handleSelectChangelanguage = (selectedValues) => {
    setSelectedOptionslanguage(selectedValues);
  };

  const handleSelectChangeartists = (selectedValues) => {
    setSelectedOptionsartists(selectedValues);
  };

  const handleSelectChangecategory = (selectedValues) => {
    setSelectedOptionscategory(selectedValues);
  };
  // Multiselect

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");
  const [Content_Partner_id, setContent_Partner_id] = useState("1");
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  // console.log(inputValue, "checkboxChecked");

  const handleCheckbox = () => {
    setEnable_restream(!enable_restream);
    if (!enable_restream) {
      setShowModal(true);
    } else {
      setShowInputs(false);
      setSelectedButtons([]);
    }
  };

  const handleButtonClick = (button) => {
    setSelectedButtons((prevSelectedButtons) => [
      ...prevSelectedButtons,
      button,
    ]);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSave = () => {
    setShowModal(false);
    setShowInputs(true);
  };

  const handlePublishType = (value) => {
    setPublish_type(value);
  };

  const handlePublishTime = (value) => {
    setPublish_time(value);
  };

  const handleFeaturedChange = (event) => {
    const value = event.target.checked ? 1 : 0;
    setFeatured(value);
  };

  const handleActiveChange = (event) => {
    const value = event.target.checked ? 1 : 0;
    setActive(value);
  };

  const handleBannerChange = (event) => {
    const value = event.target.checked ? 1 : 0;
    setBanner(value);
  };

  const contentFieldChanaged = (data) => {
    setDetails(data);
    // console.log("datasssssss", data);
  };

  async function stream() {
    const formData = new FormData();

    formData.append("title", title);
    formData.append("mp4_url", mp4_url);
    formData.append("slug", slug);
    formData.append("url_type", url_type);
    formData.append("embed_url", embed_url);
    formData.append("m3u_url", m3u_url);
    formData.append("live_stream_video", live_stream_video);
    formData.append("Encode_stream_video", encode_stream_video);
    formData.append("details", details);
    formData.append("rating", rating);
    formData.append("age_restrict", age_res?.value);

    formData.append("year", year);

    formData.append(
      "artist",
      JSON.stringify(selectedOptionsartists?.map((option) => option.value))
    );
    formData.append(
      "block_country",
      JSON.stringify(selectedOptions?.map((option) => option.value))
    );
    formData.append(
      "category",
      JSON.stringify(selectedOptionscategory?.map((option) => option.value))
    );
    formData.append(
      "language",
      JSON.stringify(selectedOptionslanguage?.map((option) => option.value))
    );
    formData.append("duration", duration);
    formData.append("access", userAccesses?.value);
    formData.append("publish_type", publish_type);
    formData.append("publish_time", publish_time);
    formData.append("enable_restream", enable_restream === true ? 1 : 0);
    formData.append("featured", featured);
    formData.append("banner", banner);
    formData.append("active", active);
    formData.append("search_tags", search_tags);
    formData.append("ppv_price", inputValue);
    formData.append("ios_ppv_price", ios_ppv_price?.value);

    formData.append("fb_restream_url", fb_restream_url);
    formData.append("youtube_restream_url", youtube_restream_url);
    formData.append("twitter_restream_url", twitter_restream_url);
    formData.append("fb_streamkey", fb_streamkey);
    formData.append("youtube_streamkey", youtube_streamkey);
    formData.append("twitter_streamkey", twitter_streamkey);
    formData.append("description", description);
    formData.append("Content_Partner_id", Content_Partner_id);
    formData.append("ads_position", selectedAdsPosition?.value);
    formData.append("live_ads", secondSelectOptions?.value);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-Store`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status === true) {
          var result = response.data;
          // console.log('result', response);

          var livestream_id = response.data.LiveStream_id;

          // setLivestream_id(livestream_id);
          console.log("livestream_id", livestream_id);

          const formDatas = new FormData();

          formDatas.append("livestream_id", livestream_id);
          formDatas.append("image", image);
          formDatas.append("player_image", player_image);
          formDatas.append("TV_image", Tv_image);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-image-upload/${livestream_id}`,
              formDatas,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api' , response);
              if (response.data.status === true) {
                var result = response.data;
                // console.log('result', response);
                // navigate('/admin/all-artist')
                alert("Added successfully  !");
              }
            });

          const formData1 = new FormData();

          formData1.append("livestream_id", livestream_id);
          formData1.append("live_stream_video ", live_stream_video);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-video-upload`,
              formData1,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api' , response);
              if (response.data.status === true) {
                var result = response.data;
                // console.log('result', response);
                // navigate('/admin/all-artist')
                alert("Added successfully  !");
              }
            });
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    getCategorys();
    // getArtists();
    // fetchLanguages();
    // fetchBlockCountries();
    // fetchAdsPositions();
    // fetchUserAccesses();
    globel();
    publishType();
  }, []);

  const getCategorys = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-Create`,
        {
          headers: headers,
        }
      );

      // console.log(response, "response"); // Log the response object
      const data = await response?.json();
      var livestream_source = data.Livestream_source;
      setLivestream_source(livestream_source);
      // console.log(livestream_source)
    } catch (error) {
      console.log("Error fetching category:", error);
    }
  };

  const fetchAdsPositions = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-Create`,
        {
          headers: headers,
        }
      );

      if (response.ok) {
        const data = await response?.json();
        const adsPositionOptions = data?.ads_position?.map((position) => ({
          value: position?.position,
          label: position?.name,
        }));
        setAdsPositions(adsPositionOptions);
      } else {
        throw new Error("Request failed with status: " + response?.status);
      }
    } catch (error) {
      console.log("Error fetching ads positions:", error);
    }
  };

  const globel = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-Create`,
        {
          headers: headers,
        }
      );

      if (response.ok) {
        const data = await response?.json();
        const globelset = data?.ppv_gobal_price;
        setInputValueAPI(globelset);
      } else {
        throw new Error("Request failed with status: " + response?.status);
      }
    } catch (error) {
      console.log("Error fetching user accesses:", error);
    }
  };

  const publishType = async () => {
    axios
      .get(`${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-Create`, {
        headers: headers,
      })
      .then((response) => {
        const result = response?.data?.Publish_type;
        setPublish(result);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (selectedAdsPosition !== "") {
      // console.log(selectedAdsPosition, "adsssssssssss");
      const formData = new FormData();
      formData.append("position", selectedAdsPosition?.value);

      axios

        .post(
          `${process.env.REACT_APP_Baseurl}/CPP/advertisement-tag-url`,
          formData,
          { headers: headers }
        )

        .then((response) => {
          const data = response?.data;
          // console.log(data, "exceptioned");

          const adsNames = data?.Advertisement?.map((position) => ({
            value: position?.id,
            label: position?.ads_name,
          }));

          setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition]);

  const [livesource, setLivesource] = useState("");

  function live_stream_source() {
    switch (livesource) {
      case "0":
        return <div></div>;
      case "mp4":
        return (
          <div className="mt-3">
            <label>Mp4/M3U8 URL</label>
            <input
              type="text"
              className="form-control mt-2"
              onChange={(e) => setMp4_url(e.target.value)}
              placeholder="Mp4_url"
            />
          </div>
        );
      case "embed":
        return (
          <div className="mt-3">
            <label>Embed Url</label>

            <input
              type="text"
              className="form-control mt-2"
              onChange={(e) => setEmbed_url(e.target.value)}
              placeholder="Embed_url"
            />
          </div>
        );
      case "live_stream_video":
        return (
          <div className="mt-3">
            <label>Live Stream Video</label>

            <input
              type="file"
              accept="video/mp4"
              className="form-control mt-2"
              onChange={(e) => setLive_stream_video(e.target.files[0])}
              placeholder="live_stream_video"
            />
          </div>
        );
      case "m3u_url":
        return (
          <div className="mt-3">
            <label>M3u_url</label>

            <input
              type="text"
              className="form-control mt-2"
              onChange={(e) => setM3u_url(e.target.value)}
              placeholder="m3u_url"
            />
          </div>
        );

      case "Encode_stream_video":
        return (
          <div className="mt-3">
            <label>Encode_stream_video</label>

            <input
              type="text"
              className="form-control mt-2"
              onChange={(e) => setEncode_stream_video(e.target.value)}
              placeholder="Encode_stream_video"
            />
          </div>
        );

      default:
        return null;
    }
  }

  const [enable_restreampublic, setEnable_restreampublic] = useState("");
  const [enablestreamvisiblepublic, setEnablestreamvisiblepublic] =
    useState("");

  function proceednextpublic() {
    switch (enable_restreampublic) {
      case "publish_now":
        return <div></div>;
      case "publish_later":
        return (
          <div className="mb-3">
            <label className="m-0">Publish Time</label>
            <input
              type="datetime-local"
              className="form-control"
              id="publish_time"
              name="publish_time"
              onChange={(e) => setPublish_time(e.target.value)}
            />
          </div>
        );
      default:
        return null;
    }
  }

  const [livesourceaccess, setLivesourceaccess] = useState("");

  function live_stream_sourceaccess() {
    switch (livesourceaccess) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div class="row mt-3 text-start ">
            <div class="col-sm-6">
              <label class="m-0">PPV Price</label>
              <div className="col-lg-12 row">
                <div className="col-8 col-lg-8">
                  <p class="p1">Apply PPV Price from Global Settings? </p>
                </div>
                <div className="col-4 col-lg-4">
                  <label className="switch">
                    <input
                      name="ppv_price"
                      defaultChecked={checkboxChecked}
                      onChange={handleCheckboxChange}
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="ppv_price"
                      checked={checkboxChecked}
                      onChange={handleCheckboxChange}
                    ></span>
                  </label>
                </div>
              </div>

              <div class="panel-body">
                <input
                  type="text"
                  class="form-control"
                  placeholder="PPV Price"
                  name="ppv_price"
                  id="price"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div class="col-sm-6">
              <label class="m-0"> IOS PPV Price</label>
              <p class="p1">Apply IOS PPV Price from Global Settings?</p>
              <div class="panel-body ppv_price_ios">
                {/* <Select
                                name="ios_ppv_price"
                                className="form-control"
                                options={ios_ppv_price}
                                onChange={(e) =>
                                  setIos_ppv_price(e.target.value)
                                }
                              /> */}

                <select
                  onChange={(e) => setIos_ppv_price(e.target.value)}
                  className="form-control"
                >
                  <option value="">Choose a PPV Price</option>
                  {inapppurchase?.map((item, key) => (
                    <option value={item.id}>{item.plan_price}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 p-2">
              <div className="card ">
                <div className="card-body">
                  <div className="container-fluid p-0">
                    <div className="p-3">
                      <div>
                        <h4 className="">Add New Live Stream Video</h4>
                        <hr />

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Title</label>
                            <p>Add The Video Title In The Textbox Below:</p>

                            <div>
                              <input
                                type="text"
                                id="title"
                                name="title"
                                className="form-control"
                                onChange={(e) => setTitle(e.target.value)}
                                placeholder="Title"
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <label className="m-0">Slug</label>
                            <p className="">
                              Add the Live stream slug in the textbox below:
                            </p>
                            <div>
                              <input
                                type="text"
                                id="slug"
                                name="slug"
                                className="form-control"
                                onChange={(e) => setSlug(e.target.value)}
                                placeholder="slug"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start align-items-center">
                          <div className="col-md-6">
                            <label className="m-0">Video Image Cover</label>
                            <p className="p1">
                              Select The Video Image (1080x1920)
                            </p>
                            <div>
                              <input
                                type="file"
                                className="form-control"
                                name="image"
                                id="image"
                                onChange={(e) => setImage(e.target.files[0])}
                                placeholder="Live_stream_video"
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="row">
                              <label className="m-0">Player Image Cover</label>
                              <p className="">
                                Select The Video Image(1280x720 Px ):
                              </p>
                              <div>
                                <input
                                  type="file"
                                  className="form-control"
                                  name="player_image"
                                  id="player_image"
                                  onChange={(e) =>
                                    setPlayer_image(e.target.files[0])
                                  }
                                  placeholder="player_image"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="row mt-3 text-start align-items-center">
                         
                          <div className="col-md-6">
                            <div className="row">
                              <label className="m-0">TV Live Image</label>
                              <p className="">
                                Select The TV Live Image(1280x720 Px ):
                              </p>
                              <div>
                                <input
                                  type="file"
                                  className="form-control"
                                  name="player_image"
                                  id="player_image"
                                  onChange={(e) =>
                                    setPlayer_image(e.target.files[0])
                                  }
                                  placeholder="player_image"
                                />
                              </div>
                            </div>
                          </div>
                        </div> */}

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Video Source</label>
                            <div className="">
                              <div>
                                <select
                                  className="form-control url_type"
                                  // onClick={(event) => {
                                  //   setLivesource(event.target.value);
                                  // }}

                                  onClick={(event) => {
                                    setLivesource(event.target.value);
                                    setUrl_type(event.target.value);
                                  }}
                                >
                                  <option value="" selected>
                                    Choose URL Format{" "}
                                  </option>
                                  {livestream_source?.map((item, key) => (
                                    <option value={item?.value}>
                                      {item?.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {live_stream_source()}
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="row">
                              <label className="m-0">TV Image Cover</label>
                              <p className="">
                                Select The TV Image(1280x720 Px ):
                              </p>
                              <div>
                                <input
                                  type="file"
                                  className="form-control"
                                  name="player_image"
                                  id="player_image"
                                  onChange={(e) =>
                                    setTv_Image(e.target.files[0])
                                  }
                                  placeholder="player_image"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Video Source</label>
                            <div className="">
                              <div>
                                <select
                                  className="form-control url_type"
                                  onClick={(event) => {
                                    setLivesource(event.target.value);
                                  }}
                                >
                                  <option defaultValue="0" selected>
                                    Choose URL Format
                                  </option>
                                  <option value="mp4" selected>
                                    mp4
                                  </option>
                                  <option value="Embed_url">Embed_url</option>
                                  <option value="Mp3">Mp3</option>
                                </select>
                              </div>
                              {live_stream_source()}
                            </div>
                          </div>
                        </div> */}

                        {/* <div className="row mt-3 text-start">
                          <div className="col-sm-12">
                            <label className="m-0">Live Stream URL</label>

                            <div className="">
                              <input
                                className="form-control me-2"
                                type="search"
                                placeholder="Mp4_url"
                                aria-label="Search"
                                name="mp4_url"
                                id="mp4_url"
                                onChange={(e) => setMp4_url(e.target.value)}
                              />
                            </div>
                          </div>
                        </div> */}
                        <div className="row mt-3 text-start">
                          <div className="col-sm-12">
                            <label className="m-0">Short Description</label>
                            <p className="">
                              Add a short description of the Livestream below:
                            </p>
                            <div className="">
                              <textarea
                                className="form-control"
                                id="description"
                                name="description"
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="Description"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-12">
                            <label className="m-0">
                              Video Details, Links, and Info
                            </label>
                            <div className="">
                              {/* <textarea
                                className="form-control"
                                id="details"
                                name="details"
                                onChange={(e) => setDetails(e.target.value)}
                                placeholder="setDetails"
                              /> */}

                              <JoditEditor
                                // ref={editor}
                                value={details}
                                onChange={(newContent) =>
                                  contentFieldChanaged(newContent)
                                }
                              />

                              {/* <JoditEditor
                              
                                value={details}
                                onChange={(newContent) =>
                                  contentFieldChanaged(newContent)
                                }
                              /> */}
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-sm-8">
                            <label className="m-0">Search Tags</label>
                            {/* <div className="">
                              <input
                                className="form-control me-2"
                                type="search"
                                placeholder="search_tags"
                                aria-label="Search"
                                name="search_tags"
                                id="search_tag"
                                onChange={(e) => setSearch_tags(e.target.value)}
                              />
                            </div> */}
                            <TagsInput
                              value={search_tags}
                              onChange={setSearch_tags}
                              name="search_tags"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title font-weight-bold">
                                  <label className="m-0">Cast and Crew</label>{" "}
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Add artists for the series below:
                                </p>

                                <Select
                                  options={artists?.map((option) => ({
                                    value: option?.id,
                                    label: option?.artist_name,
                                  }))}
                                  className="form-control"
                                  isMulti
                                  value={selectedOptionsartists}
                                  onChange={handleSelectChangeartists}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <label className="m-0">Contry</label>
                            <p className="p1">
                              Select A Live Stream Country Below:
                            </p>

                            <div className="">
                              <div className="text-dark">
                                <Select
                                  options={block_country?.map((option) => ({
                                    value: option?.id,
                                    label: option?.name,
                                  }))}
                                  className="form-control"
                                  isMulti
                                  value={selectedOptions}
                                  onChange={handleSelectChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Category</label>
                            <p className="p1">
                              Select A Live Stream Category Below:
                            </p>

                            <div className="">
                              <div className="text-dark">
                                {/* <MultiSelectDropdown
                                  options={category}
                                  onChange={handleCategory}
                                  className= 'form-control'
                                /> */}
                                <Select
                                  options={category?.map((option) => ({
                                    value: option?.id,
                                    label: option?.name,
                                  }))}
                                  className="form-control"
                                  isMulti
                                  value={selectedOptionscategory}
                                  onChange={handleSelectChangecategory}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <label className="m-0">Language</label>
                            <p className="p1">
                              Select A Live Stream Language Below:
                            </p>
                            <div className="">
                              <div className="text-dark">
                                {/* <MultiSelectDropdown
                                  options={language}
                                  onChange={handleLanguageChange}
                                /> */}
                                <Select
                                  options={language?.map((option) => ({
                                    value: option?.id,
                                    label: option?.name,
                                  }))}
                                  className="form-control"
                                  isMulti
                                  value={selectedOptionslanguage}
                                  onChange={handleSelectChangelanguage}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row text-start mt-4">
                          <div className="col-sm-6">
                            <label className="m-0">Video Ratings</label>
                            <p className="p1">
                              Live Stream Ratings 10 Out Of 10{" "}
                            </p>
                            <div className="form-control">
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                onChange={(e) => setRating(e.target.value)}
                              >
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <label className="m-0">Video Year</label>
                            <p className="p1">Video Created Year</p>
                            <div className="">
                              <input
                                type="text"
                                name="year"
                                className="form-control"
                                onChange={(e) => setYear(e.target.value)}
                                placeholder="Set Year"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Duration</label>
                            <p className="p1">
                              Enter The Live Stream Duration In (HH : MM : SS)
                            </p>
                            <div>
                              <input
                                type="text"
                                className="form-control"
                                onChange={(e) => setDuration(e.target.value)}
                                placeholder="Duration"
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <label className="m-0">User Access</label>
                            <p className="p1">
                              Enter The Live Stream User Access
                            </p>
                            <div className="">
                              <div>
                                <select
                                  className="form-control url_type"
                                  onClick={(event) => {
                                    setLivesourceaccess(event.target.value);
                                  }}
                                >
                                  {user_access?.map((item, key) => (
                                    <option value={item.value}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div>{live_stream_sourceaccess()}</div>
                        </div>
                        {userAccessesValue?.value === "ppv" ? (
                          <>
                            <div class="row mt-3 text-start ">
                              <div class="col-sm-4">
                                <label class="m-0">PPV Price</label>
                                <p class="p1">
                                  Apply PPV Price from Global Settings?
                                  <input
                                    type="checkbox"
                                    name="ppv_gobal_price"
                                    id="ppv_gobal_price"
                                    checked={checkboxChecked}
                                    onChange={handleCheckboxChange}
                                  />
                                </p>

                                <div class="panel-body">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="PPV Price"
                                    name="ppv_price"
                                    id="price"
                                    value={inputValue}
                                    onChange={handleInputChange}
                                  />
                                  <div class="clear"></div>
                                </div>
                              </div>

                              <div class="col-sm-4">
                                <label class="m-0"> IOS PPV Price</label>
                                <p class="p1">
                                  Apply IOS PPV Price from Global Settings?
                                </p>
                                <div class="panel-body form-control">
                                  <Select
                                    options={ios_ppv_price}
                                    // onChange={(e) => setIos_ppv_price(e)}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}

                        <div className="row">
                          <div className="col-sm-6 form-group">
                            <label className="mb-2">Publish Type</label>
                            <div>
                              <input
                                type="radio"
                                id="publish_now"
                                name="publish_type"
                                onClick={(event) => {
                                  setEnable_restreampublic(event.target.value);
                                }}
                                onChange={(e) =>
                                  setPublish_type(e.target.value)
                                }
                                value="publish_now"
                                checked="checked"
                              />{" "}
                              <span
                                onClick={(event) => {
                                  setEnable_restreampublic(event.target.value);
                                }}
                                onChange={(e) =>
                                  setPublish_type(e.target.value)
                                }
                              >
                                Publish Now
                              </span>
                            </div>
                            <div>
                              <input
                                type="radio"
                                id="publish_later"
                                value="publish_later"
                                name="publish_type"
                                onClick={(event) => {
                                  setEnable_restreampublic(event.target.value);
                                }}
                                onChange={(e) =>
                                  setPublish_type(e.target.value)
                                }
                              />{" "}
                              <span
                                value="publish_later"
                                name="publish_type"
                                onClick={(event) => {
                                  setEnable_restreampublic(event.target.value);
                                }}
                                onChange={(e) =>
                                  setPublish_type(e.target.value)
                                }
                              >
                                Publish Later
                              </span>{" "}
                            </div>
                          </div>

                          <div
                            className="col-sm-6 form-group"
                            id="publishlater"
                          >
                            <div
                              onClick={(event) => {
                                setEnablestreamvisiblepublic(
                                  event.target.value
                                );
                              }}
                            >
                              {proceednextpublic()}
                            </div>
                          </div>
                        </div>

                        {/* <div className="row mt-3 text-start">
                          <div className="col-sm-4">
                            <label className="m-0">Publish Type</label>

                            <div className="p2">
                              <div>
                                <input
                                  className="active"
                                  type="radio"
                                  id="publish_now"
                                  name="publish_type"
                                  value="1"
                                  checked={publish_type === "1"}
                                  onChange={() => handlePublishType("1")}
                                />
                                <label> Publish Now</label>
                              </div>
                              <div>
                                <input
                                  type="radio"
                                  id="publish_later"
                                  name="publish_type"
                                  value="0"
                                  checked={publish_type === "0"}
                                  onChange={() => handlePublishType("0")}
                                />
                                <label> Publish Later </label>
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-4">
                            {publish_type === "0" && (
                              <div>
                                <label>Publish Time:</label>
                                <input
                                  type="datetime-local"
                                  id="publish_time"
                                  name="publish_time"
                                  value={publish_time}
                                  onChange={(e) =>
                                    handlePublishTime(e.target.value)
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div> */}
                        <div className="text-end">
                          <button onClick={stream} className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Add_NewLiveVideo;
