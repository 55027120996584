import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const Manage_VideoCategories = () => {
  const [managevideocategories, setManagevideocategories] = useState([]);

  const [data, setData] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);

  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");
  const [Content_Partner_id, setContent_Partner_id] = useState("1");

  // console.log(allvideos);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/videos-category/List`,
        { Content_Partner_id: Content_Partner_id },
        {
          headers: headers,
        }
      )
      .then((response) => {
        var result = response?.data?.VideoCategory?.data;
        setManagevideocategories(result);
        // console.log(result);
        // console.log(result);
      })
      .catch((error) => console.log(error));
  }, []);

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/videos-category/delete/` +
          id,
        {
          headers: headers,
        }
      )
      .then((response) => {
        alert("Item Deleted Successfully !");
        window.location.reload();
      });
  };

  function handlePreClick() {
    // console.log('preview');

    axios
      .post(
        `${
          process.env.REACT_APP_Baseurl
        }/CPP/Backend/videos-category/List?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.series.data);
        setPrevious(res?.data?.VideoCategory?.data);
        setManagevideocategories(res?.data?.VideoCategory?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpage() {
    // console.log('handlefirstpage');

    axios
      .post(
        `${
          process.env.REACT_APP_Baseurl
        }/CPP/Backend/videos-category/List?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.series.data);
        setPrevious(res?.data?.VideoCategory?.data);
        setManagevideocategories(res?.data?.VideoCategory?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClick() {
    // console.log('next');

    axios
      .post(
        `${
          process.env.REACT_APP_Baseurl
        }/CPP/Backend/videos-category/List?page=${page + 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log('next', res.data.series.data);
        setNext(res?.data?.VideoCategory?.data);
        setManagevideocategories(res?.data?.VideoCategory?.data);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="pt-3">
              <div className="admin-section-title">
                <div className="iq-card">
                  <div className="row">
                    <div className="col-md-6">
                      <h4>
                        <i className="entypo-archive"></i> Video Categories
                      </h4>
                    </div>

                    <div className="col-md-6" align="right">
                      <Link
                        to="/cpp/add-manage-video-category"
                        className="btn btn-primary"
                      >
                        <i className="fa fa-plus-circle"></i> Add New
                      </Link>
                    </div>
                  </div>
                  <hr />

                  <div
                    className="panel panel-primary category-panel"
                    data-collapsed="0"
                  >
                    <div className="panel-heading">
                      <div className="panel-title">
                        <p>Organize the Categories below: </p>
                      </div>

                      <div className="panel-options">
                        <Link to="#" data-rel="collapse">
                          <i className="entypo-down-open"></i>
                        </Link>
                      </div>
                    </div>

                    <div className="panel-body">
                      <div
                        id="nestable"
                        className="nested-list dd with-margins"
                      >
                        <table
                          className="table table-bordered iq-card text-center"
                          id="categorytbl"
                        >
                          <tr className="table-header r1">
                            <th>
                              <label>Category Image</label>
                            </th>
                            <th>
                              <label>Video Category Name</label>
                            </th>
                            <th>
                              <label>Operation</label>
                            </th>
                          </tr>

                          {managevideocategories?.map((item, key) => (
                            <tr>
                              <td valign="bottom" className="">
                                <img
                                  src={item?.image_url}
                                  width={100}
                                  height={100}
                                />
                              </td>
                              <td valign="bottom">
                                <p>{item?.name}</p>
                              </td>
                              <td>
                                {/* <Link to="" className="view">
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                  ></i>
                                </Link> */}
                                <Link
                                  to={"/cpp/edit-categories/" + item?.id}
                                  className="edit ms-2"
                                >
                                  <i
                                    className="fa fa-pencil-square"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                                <Link
                                  to=""
                                  onClick={() => deleteOperation(item?.id)}
                                  className="delete ms-2"
                                >
                                  <span>
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </table>

                        <div className="row text-center d-flex">
                          <div className="col-lg-4">
                            <button
                              type="button"
                              className="btn btn-primary "
                              onClick={handlePreClick}
                            >
                              {"<< Previous"}
                            </button>
                          </div>
                          <div className="col-lg-4">
                            <p className="">{page}</p>
                          </div>
                          <div className="col-lg-4">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleNxtClick}
                            >
                              {"Next >>"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Manage_VideoCategories;
