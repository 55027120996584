import "./Sidebar.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../components/Images/flicklogo.png";

function Sidebar() {
  return (
    <section className="Sidebar">
      <div id="sidebar" className="sidebar">
      <Link className="" to="/admin">
          <img src={logo} alt="flogo" className="w-100 py-2" />
          {/* <div className="logo">
              {isLoading ? (
                <p>Loading...</p>
              ) : data1 ? (
                <div>
                  <p>
                    <img src={data1} className="w-100" />
                  </p>
                </div>
              ) : data2 ? (
                <p>
                  <img src={data2} className="w-100" />
                </p>
              ) : data3 ? (
                <p>
                  <img src={data3} className="w-100" />
                </p>
              ) : (
                <p></p>
              )}
            </div> */}
        </Link>
        
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <Link className="nav-link " to="/cpp/home">
              <i className="fa fa-tachometer"></i>
              <span>Dashboard</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link collapsed"
              data-bs-target="#Video-Management"
              data-bs-toggle="collapse"
              to="#"
            >
              <i className="fa fa-file-video-o"></i>
              <span>Video Management</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </Link>
            <ul
              id="Video-Management"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="/cpp/all-videos" className="nav-lists">
                  <i className="bi bi-circle"></i>
                  <span>All Video</span>
                </Link>
              </li>
              <li>
                <Link to="/cpp/add-newvideos" className="nav-lists">
                  <i className="bi bi-circle"></i>
                  <span>Add New Video</span>
                </Link>
              </li>
              <li>
                <Link to="/cpp/manage-video-category">
                  <i className="bi bi-circle"></i>
                  <span>Manage Video Categories</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <Link
              className="nav-link collapsed"
              data-bs-target="#Series-Episode"
              data-bs-toggle="collapse"
              to="#"
            >
              <i className="fa fa-file-video-o"></i>
              <span>Series & Episode</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </Link>
            <ul
              id="Series-Episode"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="/cpp/series-lists">
                  <i className="bi bi-circle"></i>
                  <span>Series List</span>
                </Link>
              </li>
              <li>
                <Link to="/cpp/add-new-series">
                  <i className="bi bi-circle"></i>
                  <span>Add Series List</span>
                </Link>
              </li>
              <li>
                <Link to="/cpp/manage-series-genre">
                  <i className="bi bi-circle"></i>
                  <span>Manage Series Genre</span>
                </Link>
              </li>
              {/* <li>
                <Link to="/cpp/add-series-genre">
                  <i className="bi bi-circle"></i>
                  <span>Add Manage Series Genre</span>
                </Link>
              </li> */}
            </ul>
          </li>

          <li className="nav-item">
            <Link
              className="nav-link collapsed"
              data-bs-target="#Audio-Management"
              data-bs-toggle="collapse"
              to="#"
            >
              <i className="fa fa-file-audio-o"></i>
              <span>Audio Management</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </Link>
            <ul
              id="Audio-Management"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="/cpp/audio-lists">
                  <i className="bi bi-circle"></i>
                  <span>Audio List</span>
                </Link>
              </li>
              <li>
                <Link to="/cpp/add-newaudio-lists">
                  <i className="bi bi-circle"></i>
                  <span>Add New Audio</span>
                </Link>
              </li>

              <li>
                <Link to="/cpp/manage-audio-category">
                  <i className="bi bi-circle"></i>
                  <span>Manage Audio Categories</span>
                </Link>
              </li>
              <li>
                <Link to="/cpp/manage-albums">
                  <i className="bi bi-circle"></i>
                  <span>Manage Albums</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <Link
              className="nav-link collapsed"
              data-bs-target="#Live-Stream"
              data-bs-toggle="collapse"
              to="#"
            >
              <i className="fa fa-video-camera"></i>
              <span>Manage Live Videos</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </Link>
            <ul
              id="Live-Stream"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="/cpp/all-livevideo">
                  <i className="bi bi-circle"></i>
                  <span>All Live Stream</span>
                </Link>
              </li>
              <li>
                <Link to="/cpp/add-new-livevideo">
                  <i className="bi bi-circle"></i>
                  <span>Add New Live Stream</span>
                </Link>
              </li>

              <li>
                <Link to="/cpp/manage-live-videocategory">
                  <i className="bi bi-circle"></i>
                  <span>Manage Live Stream Categories</span>
                </Link>
              </li>
              <li>
                <Link to="/cpp/manage-live-event">
                  <i className="bi bi-circle"></i>
                  <span>Live Event Artist</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <Link
              className="nav-link collapsed"
              data-bs-target="#Pages"
              data-bs-toggle="collapse"
              to="#"
            >
              <i className="fa fa-file-text"></i>
              <span>Pages</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </Link>
            <ul
              id="Pages"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="/cpp/all-pages">
                  <i className="bi bi-circle"></i>
                  <span>All Pages</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <Link
              className="nav-link collapsed"
              data-bs-target="#Analytics"
              data-bs-toggle="collapse"
              to="#"
            >
              <i className="fa fa-bar-chart"></i>
              <span>Analytics</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </Link>
            <ul
              id="Analytics"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to="/cpp/video-analytics">
                  <i className="bi bi-circle"></i>
                  <span>Video Analytics</span>
                </Link>
              </li>
              <li>
                <Link to="/cpp/video-view-region">
                  <i className="bi bi-circle"></i>
                  <span>View By Region</span>
                </Link>
              </li>
              <li>
                <Link to="/cpp/video-payout">
                  <i className="bi bi-circle"></i>
                  <span>Video payouts</span>
                </Link>
              </li>
              <li>
                <Link to="/cpp/live-stream-payout">
                  <i className="bi bi-circle"></i>
                  <span>LiveStream Payouts</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <Link className="nav-link collapsed" to="/cpp/about-myprofile">
              <i className="fa fa-user-plus"></i>
              <span>About My Profile</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </Link>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Sidebar;
