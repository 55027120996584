import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Select from "react-select";

function Edit_newaudio() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
    ppv_status: "",
    ppv_price: "",
    type: "",
    access: "",
    album_id: "",
    artists: "",
    rating: "",
    details: "",
    description: "",
    active: "",
    status: "",
    draft: "",
    featured: "",
    banner: "",
    duration: "",
    views: "",
    year: "",
    age_restrict: "",
    mp3_url: "",
    image: "",
    player_image: "",
    tv_image: "",
    search_tags: "",
    ios_ppv_price: "",
    uploaded_by: "",
    image_url: "",
    Player_thumbnail: "",
    TV_Thumbnail: "",
  });

  const [age_restrictdata, setAge_restrictdata] = useState([]);
  const [pre_ads_category, setPre_ads_category] = useState("");
  const [mid_ads_category, setMid_ads_category] = useState("");
  const [post_ads_category, setPost_ads_category] = useState("");

  const [user_access, setUser_access] = useState([]);
  const [inapppurchase, setInappPurchase] = useState("");

  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");
  const [Content_Partner_id, setContent_Partner_id] = useState("1");

  const [image, setImage] = useState("");
  const [player_image, setPlayer_image] = useState("");
  const [tv_image, setTv_image] = useState("");

  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);
  const [ios_ppv_price, setIos_ppv_price] = useState("");
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    console.log(selectedValue5); // Selected value
  };

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(editUser?.ppv_price);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // MULTI SELECT NEW
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  const [optionsartists, setOptionsartists] = useState([]);
  const [selectedValuesartists, setSelectedValuesartists] = useState([]);

  const [optionscategories, setOptionscategories] = useState([]);
  const [selectedValuescategories, setSelectedValuescategories] = useState([]);

  const [optionslanguages, setOptionslanguages] = useState([]);
  const [selectedValueslanguages, setSelectedValueslanguages] = useState([]);

  const [optionsAge_Restrict, setOptionsAge_Restrict] = useState([]);
  const [selectedValuesAge_Restrict, setSelectedValuesAge_Restrict] = useState(
    []
  );

  const editInputvalue = {
    title: editUser.title,
    slug: editUser.slug,
    active: editUser.active,
    status: editUser.status,
    url_type: editUser.url_type,
    // mp4_url: editUser,
    // mp4_url: editUser.mp4_url,
    ppv_status: editUser.ppv_status,
    embed_url: editUser.embed_url,
    // m3u_url: editUser,
    // m3u_url: editUser.m3u_url,
    live_stream_video: editUser.live_stream_video,
    // rtmp_url: editUser,
    // rtmp_url: editUser.rtmp_url,
    hls_url: editUser.hls_url,
    Stream_key: editUser.Stream_key,
    description: editUser.description,
    details: editUser.details,
    search_tags: editUser.search_tags,
    rating: editUser.rating,
    age_restrict: editUser.age_restrict,
    duration: editUser.duration,
    featured: editUser.featured,
    banner: editUser.banner,
    year: editUser.year,
    mp3_url: editUser.mp3_url,
    publish_status: editUser.publish_status,
    publish_type: editUser.publish_type,
    publish_time: editUser.publish_time,
    user_id: editUser.user_id,
    uploaded_by: editUser.uploaded_by,
    access: editUser.access,
    ppv_price: inputValue,
    ios_ppv_price: editUser.ios_ppv_price,
    enable_restream: editUser.enable_restream,
    fb_restream_url: editUser.fb_restream_url,
    fb_streamkey: editUser.fb_streamkey,
    Content_Partner_id: Content_Partner_id,
    youtube_restream_url: editUser.youtube_restream_url,
    youtube_streamkey: editUser.youtube_streamkey,
    twitter_restream_url: editUser.twitter_restream_url,
    twitter_streamkey: editUser.twitter_streamkey,
    linkedin_restream_url: editUser.linkedin_restream_url,
    linkedin_streamkey: editUser.linkedin_streamkey,
    video_category_id: editUser.video_category_id,
    footer: editUser.footer,
    language: editUser.language,
    live_ads: editUser.live_ads,
    ads_position: editUser.ads_position,
    album_id: editUser.album_id,
  };

  const [formData, setFormData] = useState({
    block_country: [],
    artists: [],
    categories: [],
    languages: [],
    Age_Restrict: [],
    // ...editInputvalue,
    // other form data properties...
  });

  // const [languages, setLanguages] = useState([]);
  // const [selectedLanguages, setSelectedLanguages] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/Edit/${id}`,
        { headers: headers }
      );
      const data = response?.data?.selected_Audios_block_countries;
      const dataartists = response?.data?.selected_Audios_artists;
      const datacategories = response?.data?.selected_Audios_categories;
      const datalanguages = response?.data?.selected_languages;
      const dataAge_Restrict = response?.data?.selected_Age_Restrict;

      var result = response?.data?.user_access;
      var InappPurchase = response?.data?.InappPurchase;
      setUser_access(result);
      setInappPurchase(InappPurchase);

      var res = response?.data?.audios[0];
      setEditUser(res);

      const selectedData = data;
      const formattedOptions = selectedData.map((item) => ({
        value: item.id,
        label: item.country_name,
      }));
      const formattedOptionsartists = dataartists?.map((item) => ({
        value: item.id,
        label: item.id,
      }));
      const formattedOptionscategories = datacategories?.map((item) => ({
        value: item.id,
        label: item.id,
      }));
      const formattedOptionslanguages = datalanguages?.map((item) => ({
        value: item.id,
        label: item.id,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict?.map((item) => ({
        value: item.id,
        label: item.id,
      }));

      // Set the initial selected values
      setSelectedValues(formattedOptions);
      setOptions(formattedOptions);

      setSelectedValuesartists(formattedOptionsartists);
      setOptionsartists(formattedOptionsartists);

      setSelectedValuescategories(formattedOptionscategories);
      setOptionscategories(formattedOptionscategories);

      setSelectedValueslanguages(formattedOptionslanguages);
      setOptionslanguages(formattedOptionslanguages);

      setSelectedValuesAge_Restrict(formattedOptionsAge_Restrict);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
    } catch (error) {
      console.error(error);
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/create`,
        { headers: headers }
      );
      const data = response?.data?.Block_country;
      const dataartists = response?.data?.artists;
      const datacategories = response?.data?.audio_categories;
      const datalanguages = response?.data?.languages;
      const dataAge_Restrict = response?.data?.Age_Restrict;

      var age_restrictdata = response?.data?.Age_Restrict;
      setAge_restrictdata(age_restrictdata);
      const globelset = response?.data?.ppv_gobal_price;
      setInputValueAPI(globelset);
      // console.log(data);
      // console.log(data);
      // Extract the selected data from the response
      const selectedData = data;
      const formattedOptions = selectedData.map((item) => ({
        value: item.id,
        label: item.country_name,
      }));
      const formattedOptionsartists = dataartists.map((item) => ({
        value: item.id,
        label: item.id,
      }));
      const formattedOptionscategories = datacategories.map((item) => ({
        value: item.id,
        label: item.id,
      }));
      const formattedOptionslanguages = datalanguages.map((item) => ({
        value: item.id,
        label: item.id,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict.map((item) => ({
        value: item.id,
        label: item.id,
      }));

      // Set the options for the multi-select component
      setOptions(formattedOptions);
      setOptionsartists(formattedOptionsartists);
      setOptionscategories(formattedOptionscategories);
      setOptionslanguages(formattedOptionslanguages);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
    } catch (error) {
      console.error(error);
    }
  };
  // const handleLanguageChange = (event) => {
  //   const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
  //   setSelectedLanguages(selectedOptions);
  // };

  const handleSelectChange = (selectedOptions) => {
    const block_country = selectedOptions.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      block_country: block_country,
    });

    setSelectedValues(selectedOptions);
  };
  const handleSelectChangeartists = (selectedOptionsartists) => {
    const artists = selectedOptionsartists?.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      artists: artists,
    });

    setSelectedValuesartists(selectedOptionsartists);
  };

  const handleSelectChangecategories = (selectedOptionscategories) => {
    const categories = selectedOptionscategories?.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      categories: categories,
    });

    setSelectedValuescategories(selectedOptionscategories);
  };

  const handleSelectChangelanguages = (selectedOptionslanguages) => {
    const languages = selectedOptionslanguages?.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      languages: languages,
    });

    setSelectedValueslanguages(selectedOptionslanguages);
  };
  const handleSelectChangeAge_Restrict = (selectedOptionsAge_Restrict) => {
    const Age_Restrict = selectedOptionsAge_Restrict?.map(
      (option) => option.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      Age_Restrict: Age_Restrict,
    });

    setSelectedValueslanguages(selectedOptionsAge_Restrict);
  };

  // MULTI SELECT NEW

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/Edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();

      // console.log(resData)
      // console.log(res);
      // console.log(res);
      // console.log(res);
    };
    getUser();
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 1 });
      setEditUser({
        ...editUser,
        [e.target.name]: e.target.value,
        [e.target.name]: 1,
      });
    } else {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 0 });
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      title: editUser.title,
      slug: editUser.slug,
      ppv_status: editUser.ppv_status,
      ppv_price: editUser.ppv_price,
      type: editUser.type,
      access: editUser.access,
      album_id: editUser.album_id,
      artists: editUser.artists,
      rating: editUser.rating,
      details: editUser.details,
      description: editUser.description,
      active: editUser.active,
      status: editUser.status,
      draft: editUser.draft,
      featured: editUser.featured,
      banner: editUser.banner,
      duration: editUser.duration,
      views: editUser.views,
      year: editUser.year,
      ads_position: editUser.ads_position,
      age_restrict: editUser.age_restrict,
      mp3_url: editUser.mp3_url,
      image: editUser.image,
      player_image: editUser.player_image,
      tv_image: editUser.tv_image,
      search_tags: editUser.search_tags,
      ios_ppv_price: editUser.ios_ppv_price,
      uploaded_by: editUser.uploaded_by,
      image_url: editUser.image_url,
      Player_thumbnail: editUser.Player_thumbnail,
      TV_Thumbnail: editUser.TV_Thumbnail,
      Content_Partner_id: Content_Partner_id,
      pre_ads_category: editUser.pre_ads_category,
      mid_ads_category: editUser.mid_ads_category,
      post_ads_category: editUser.post_ads_category,
      pre_ads: editUser.pre_ads,
      mid_ads: editUser.mid_ads,
      post_ads: editUser.post_ads,
      ads_tag_url_id: editUser.ads_tag_url_id,
      ...formData,
    };
    // console.log(editInputvalue);
    const res = await axios.post(
      `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/update/` + id,
      editInputvalue,
      { headers: headers }
    );

    // let resjson = await res.json();
    // console.log(resjson);

    if (res.status === 200) {
      setMessage(res.success);
      // alert('Success')

      var audio_id = res.audio_id;
      var resssss = res;
      // console.log("resssss", resssss);
      // console.log("audio_id", audio_id);

      const formDatas = new FormData();

      formDatas.append("audio_id", audio_id);
      formDatas.append("image", image);
      formDatas.append("player_image", player_image);
      formDatas.append("tv_image", tv_image);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/Image-upload`,
          formDatas,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api' , response);
          if (response.data.status === true) {
            var result = response.data;
            // console.log('result', response);
            // navigate('/admin/all-artist')
            alert("Added successfully  !");
          }
        });
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/create`, {
        headers: headers,
      })
      .then((response) => {
        var ads_category = response.data.ads_category;

        setPre_ads_category(ads_category);
        setMid_ads_category(ads_category);
        setPost_ads_category(ads_category);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (selectedAdsPosition !== "") {
      // console.log(selectedAdsPosition, "adsssssssssss");
      const formData = new FormData();
      formData.append("position", selectedAdsPosition?.value);

      axios

        .post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/video-advertisement-tag-url`,
          formData,
          { headers: headers }
        )

        .then((response) => {
          const data = response.data;
          // console.log(data, "exceptioned");

          const adsNames = data.Advertisement.map((position) => ({
            value: position?.id,
            label: position?.ads_name,
          }));

          setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition]);

  const [livesource, setLivesource] = useState("");

  function live_stream_source() {
    switch (livesource) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div class="row mt-3 text-start ">
            <div class="col-sm-6">
              <label class="m-0">PPV Price</label>
              <div className="col-lg-12 row">
                <div className="col-8 col-lg-8">
                  <p class="p1">Apply PPV Price from Global Settings? </p>
                </div>
                <div className="col-4 col-lg-4">
                  <label className="switch">
                    <input
                      name="ppv_price"
                      defaultChecked={checkboxChecked}
                      onChange={handleCheckboxChange}
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="ppv_price"
                      checked={checkboxChecked}
                      onChange={handleCheckboxChange}
                    ></span>
                  </label>
                </div>
              </div>

              <div class="panel-body">
                <input
                  type="text"
                  class="form-control"
                  placeholder="PPV Price"
                  name="ppv_price"
                  id="price"
                  // value={editUser?.ppv_price}
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div class="col-sm-6">
              <label class="m-0"> IOS PPV Price</label>
              <p class="p1">Apply IOS PPV Price from Global Settings?</p>
              <div class="panel-body ppv_price_ios">
                <select
                  name="ios_ppv_price"
                  id="ios_ppv_price"
                  onChange={handleInput}
                  className="form-control"
                  value={editUser?.ios_ppv_price}
                >
                  {/* <option value="">Choose a PPV Price</option> */}
                  {inapppurchase?.map((item, key) => (
                    <option value={item.id}>{item.plan_price}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="pt-3">
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between">
                      <div className="iq-header-title">
                        <h4 className="card-title">Edit Audio</h4>
                      </div>
                    </div>
                    <hr />
                    <div className="iq-card-body">
                      <form>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <div
                              className="panel panel-primary "
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Title</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Add the audio title in the textbox below:
                                </p>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="title"
                                  id="title"
                                  value={editUser?.title}
                                  placeholder="Audio Title"
                                  onChange={handleInput}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Slug</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">Add the Audio slug:</p>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="slug"
                                  id="slug"
                                  placeholder=""
                                  onChange={handleInput}
                                  value={editUser?.slug}
                                  readonly
                                />
                              </div>
                            </div>
                          </div>

                          {/* <div className="col-md-6">
														<div className="panel panel-primary" data-collapsed="0"> <div className="panel-heading">
															<div className="panel-title"><label>Created Date</label></div> <div className="panel-options"> <Link to="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
															<div className="panel-body" >
																<p className="p1">Select Date/Time Below</p>
																<input type="text" className="form-control" name="created_at" id="created_at" placeholder="" value="" />
															</div>
														</div>
													</div> */}
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className="panel panel-primary  p-0 mt-3"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Audio Image Cover</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Select the audio image ( 9:16 Ratio or
                                  1080X1920px ):
                                </p>
                                <img
                                  src={editUser?.image_url}
                                  className="audio-img"
                                  width={150}
                                  height={150}
                                />

                                <input
                                  type="file"
                                  multiple="true"
                                  className="form-control"
                                  name="image"
                                  id="image"
                                  onChange={(e) => setImage(e.target.files[0])}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className="panel panel-primary  p-0 mt-3"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Player Image Cover</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Select the audio image ( 16:9 Ratio or
                                  1280X720px ):
                                </p>
                                <img
                                  src={editUser?.Player_thumbnail}
                                  className="audio-img"
                                  width={150}
                                  height={150}
                                />

                                <input
                                  type="file"
                                  multiple="true"
                                  className="form-control"
                                  name="player_image"
                                  id="player_image"
                                  onChange={(e) =>
                                    setPlayer_image(e.target.files[0])
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className="panel panel-primary  p-0 mt-3"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Audio TV Imgae Cover</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <img src="" className="audio-img" width="200" />

                                <p className="p1">
                                  Select the audio TV Image ( 9:16 Ratio or
                                  1080X1920px ):
                                </p>
                                <img
                                  src={editUser?.TV_Thumbnail}
                                  className="audio-img"
                                  width={150}
                                  height={150}
                                />
                                <input
                                  type="file"
                                  multiple="true"
                                  className="form-control"
                                  name="tv_thumbnail"
                                  id="tv_thumbnail"
                                  onChange={(e) =>
                                    setTv_image(e.target.files[0])
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="panel panel-primary mt-3"
                          data-collapsed="0"
                        >
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Audio Details, Links, and Info</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <textarea
                              className="form-control"
                              onChange={handleInput}
                              value={editUser?.details}
                              name="details"
                              id="details"
                            ></textarea>
                          </div>
                        </div>

                        <div
                          className="panel panel-primary mt-3"
                          data-collapsed="0"
                        >
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Short Description</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Add a short description of the audio below:
                            </p>
                            <textarea
                              className="form-control"
                              name="description"
                              onChange={handleInput}
                              value={editUser?.description}
                              id="description"
                            ></textarea>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-sm-8 mt-3">
                            <div className="">
                              <label className="m-0">Search Tags</label>

                              <div className="panel-body">
                                <p className="p1">
                                  {" "}
                                  Search Tags for the below:
                                </p>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="tag-input1"
                                  onChange={handleInput}
                                  value={editUser?.search_tags}
                                  name="search_tags"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div
                            className="panel panel-primary col-sm-6  mt-3"
                            data-collapsed="0"
                          >
                            {" "}
                            <div className="panel-heading">
                              <div className="panel-title">
                                <label>Country</label>
                              </div>{" "}
                            </div>
                            <div className="panel-body">
                              <p className="p1">
                                Block the Audio for Selected Country:
                              </p>
                              <Select
                                options={options}
                                isMulti
                                className="form-control"
                                onChange={handleSelectChange}
                                value={selectedValues}
                              />
                            </div>
                          </div>

                          <div className="col-sm-6 mt-3">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Album</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Select a Audio Album Below:
                                </p>
                                <select
                                  id="album_id"
                                  name="album_id"
                                  className="form-control"
                                  onChange={handleInput}
                                >
                                  <option value={editUser?.album_id}>
                                    {editUser?.album_id}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6 mt-3">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              <div className="panel-heading">
                                <label className="p2" for="global_ppv">
                                  Age Restrict:
                                </label>
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Select a Age Restrict Below:
                                </p>
                                <select
                                  value={editUser?.age_restrict}
                                  className="form-control"
                                  id="age_restrict"
                                  name="age_restrict"
                                  onChange={handleInput}
                                >
                                  {/* <option selected value={editUser.ag}>{editUser.Age_Restrict}</option> */}
                                  {age_restrictdata?.map((item) => (
                                    <option selected value={item?.id}>
                                      {item?.slug}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 mt-3">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Artists</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Select a Audio Artists Below:
                                </p>
                                <Select
                                  options={optionsartists}
                                  isMulti
                                  className="form-control"
                                  onChange={handleSelectChangeartists}
                                  value={selectedValuesartists}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row p-0 mt-3 align-items-center">
                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Category</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Select a Audio Category Below:
                                </p>
                              </div>
                              <Select
                                options={optionscategories}
                                isMulti
                                className="form-control"
                                onChange={handleSelectChangecategories}
                                value={selectedValuescategories}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Audio Ratings</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1"> IMDB Ratings 10 out of 10</p>
                                <select
                                  value={editUser?.rating}
                                  className="form-control"
                                  name="rating"
                                  id="rating"
                                  aria-label="Default select example"
                                  onChange={handleInput}
                                >
                                  <option value="0">0</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                </select>
                                {/* <input className="form-control" name="rating" id="rating" value="" onkeyup="NumAndTwoDecimals(event , this);" /> */}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row ">
                          <div className="col-sm-6 mt-3">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Language</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Select a Audio Language Below:
                                </p>
                                <Select
                                  options={optionslanguages}
                                  isMulti
                                  className="form-control"
                                  onChange={handleSelectChangelanguages}
                                  value={selectedValueslanguages}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6  mt-3">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Audio Year</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">Audio Released Year</p>
                                <input
                                  className="form-control"
                                  name="year"
                                  id="year"
                                  onChange={handleInput}
                                  value={editUser?.year}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 align-items-center">
                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              <div className="panel-heading">
                                {" "}
                                <div className="panel-title">
                                  <label> Duration</label>
                                </div>
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Enter the audio duration in the following
                                  format (Hours : Minutes : Seconds)
                                </p>
                                <input
                                  className="form-control"
                                  name="duration"
                                  id="duration"
                                  maxlength="12"
                                  onChange={handleInput}
                                  value={editUser?.duration}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <label className="m-0">User Access</label>
                            <p className="p1">
                              Who Is Allowed To View This Live Stream ?
                            </p>
                            <select
                              onChange={handleInput}
                              name="access"
                              className="form-control"
                              value={editUser?.access}
                              onClick={(event) => {
                                setLivesource(event.target.value);
                              }}
                            >
                              {user_access?.map((item, key) => (
                                <option value={item.role}>{item.name}</option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div>{live_stream_source()}</div>

                        {/* <div className="mt-3 row ">
                          <div className="col-lg-6 ">
                            <div className="col-lg-12 row">
                              <div className="col-lg-10 col-10">
                                <label className="p2" for="global_ppv">
                                  Is this video Is Global PPV:
                                </label>
                              </div>
                              <div className="col-lg-2 col-2">
                                <label className="switch">
                                  <input
                                    name="ppv_status"
                                    id="ppv_status"
                                    onChange={handleInput}
                                    defaultChecked={
                                      editUser?.ppv_status === 1 ? true : false
                                    }
                                    checked={
                                      editUser?.ppv_status === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="ppv_status"
                                    id="ppv_status"
                                    onChange={handleInput}
                                    value={
                                      editUser?.ppv_status === 1 ? "1" : "0"
                                    }
                                  ></span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div>
                              <div className="panel-heading">
                                {" "}
                                <div className="panel-title">
                                  <label> Status Settings</label>
                                </div>{" "}
                              </div>

                              <div className="col-lg-12 row">
                                <div className="col-lg-10 col-10">
                                  <label className="p2" for="featured">
                                    Is this audio Featured:
                                  </label>
                                </div>
                                <div className="col-lg-2 col-2">
                                  <label className="switch">
                                    <input
                                      name="featured"
                                      id="featured"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.featured === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.featured === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="featured"
                                      id="featured"
                                      onChange={handleInput}
                                      value={
                                        editUser?.featured === 1 ? "1" : "0"
                                      }
                                    ></span>
                                  </label>
                                </div>
                              </div>

                              <div className="col-lg-12 row">
                                <div className="col-lg-10 col-10">
                                  <label className="p2" for="banner">
                                    Is this Audio display in Banner:
                                  </label>
                                </div>
                                <div className="col-lg-2 col-2">
                                  <label className="switch">
                                    <input
                                      name="banner"
                                      id="banner"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.banner === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.banner === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="banner"
                                      id="banner"
                                      onChange={handleInput}
                                      value={editUser?.banner === 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                </div>
                              </div>

                              <div className="col-lg-12 row">
                                <div className="col-lg-10 col-10">
                                  <label className="p2" for="active">
                                    Is this audio Active:
                                  </label>
                                </div>
                                <div className="col-lg-2 col-2">
                                  <label className="switch">
                                    <input
                                      name="active"
                                      id="active"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.active === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.active === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="active"
                                      id="active"
                                      onChange={handleInput}
                                      value={editUser?.active === 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                </div>
                              </div>

                              <div className="col-lg-12 row">
                                <div className="col-lg-10 col-10">
                                  <label className="p2" for="active">
                                    Is this audio Status:
                                  </label>
                                </div>
                                <div className="col-lg-2 col-2">
                                  <label className="switch">
                                    <input
                                      name="status"
                                      id="status"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.status === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.status === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="status"
                                      id="status"
                                      onChange={handleInput}
                                      value={editUser?.status === 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div className="row mt-4">
                          <div className="col-sm-6">
                            <div className="row">
                              <div className="col-sm-9">
                                {" "}
                                <label className="p2" for="global_ppv">
                                  Is this video Is Global PPV:
                                </label>
                              </div>
                              <div className="col-sm-3">
                                <label className="switch">
                                  <input
                                    name="ppv_status"
                                    onChange={handleInput}
                                    defaultChecked={
                                      editUser?.ppv_status == 1 ? true : false
                                    }
                                    checked={
                                      editUser?.ppv_status == 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="ppv_status"
                                    onChange={handleInput}
                                    value={
                                      editUser?.ppv_status == 1 ? "1" : "0"
                                    }
                                  ></span>
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              <div className="panel-heading">
                                {" "}
                                <div className="panel-title">
                                  <label> Status Settings</label>
                                </div>{" "}
                              </div>

                              <div className="row">
                                <div className="col-sm-9">
                                  <label className="p2" for="featured">
                                    Is this audio Featured:
                                  </label>
                                </div>
                                <div className="col-sm-3">
                                  <label className="switch">
                                    <input
                                      name="featured"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.featured == 1 ? true : false
                                      }
                                      checked={
                                        editUser?.featured == 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="featured"
                                      onChange={handleInput}
                                      value={
                                        editUser?.featured == 1 ? "1" : "0"
                                      }
                                    ></span>
                                  </label>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-sm-9">
                                  {" "}
                                  <label className="p2" for="banner">
                                    Is this Audio display in Banner:
                                  </label>
                                </div>
                                <div className="col-sm-3">
                                  <label className="switch">
                                    <input
                                      name="banner"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.banner == 1 ? true : false
                                      }
                                      checked={
                                        editUser?.banner == 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="banner"
                                      onChange={handleInput}
                                      value={editUser?.banner == 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-sm-9">
                                  <label className="p2" for="active">
                                    Is this audio Active:
                                  </label>
                                </div>
                                <div className="col-sm-3">
                                  <label className="switch">
                                    <input
                                      name="active"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.active == 1 ? true : false
                                      }
                                      checked={
                                        editUser?.active == 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="active"
                                      onChange={handleInput}
                                      value={editUser?.active == 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                </div>
                              </div>

                              {/* <div className="row">
                                <div className="col-sm-9">
                                  <label className="p2" for="status">
                                    Is this audio Status:
                                  </label>
                                </div>
                                <div className="col-sm-3">
                                  <label className="switch">
                                    <input
                                      name="status"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.status == 1 ? true : false
                                      }
                                      checked={
                                        editUser?.status == 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="status"
                                      onChange={handleInput}
                                      value={editUser?.status == 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                </div>
                              </div> */}
                              {/* <div className="row">
                                <div className="col-sm-9">
                                  <label className="p2" for="active">
                                    Is this audio Views:
                                  </label>
                                </div>
                                <div className="col-sm-3">
                                  <label className="switch">
                                    <input
                                      name="views"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.views == 1 ? true : false
                                      }
                                      checked={
                                        editUser?.views == 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="views"
                                      onChange={handleInput}
                                      value={editUser?.views == 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                </div>
                              </div> */}

                              {/* <div className="row">
                                <div className="col-sm-9">
                                  <label className="p2" for="active">
                                    Is this PPV Status:
                                  </label>
                                </div>
                                <div className="col-sm-3">
                                  <label className="switch">
                                    <input
                                      name="ppv_status"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.ppv_status == 1 ? true : false
                                      }
                                      checked={
                                        editUser?.ppv_status == 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="ppv_status"
                                      onChange={handleInput}
                                      value={
                                        editUser?.ppv_status == 1 ? "1" : "0"
                                      }
                                    ></span>
                                  </label>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="row mt-3 align-items-center ml-3">
                            <div className="col-sm-12">
                              <label className="switch" hidden>
                                <input
                                  name="draft"
                                  id="draft"
                                  onChange={handleInput}
                                  defaultChecked={
                                    editUser?.draft === 1 ? true : false
                                  }
                                  checked={editUser?.draft === 1 ? true : false}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="draft"
                                  id="draft"
                                  onChange={handleInput}
                                  value={editUser?.draft === 1 ? "1" : "0"}
                                ></span>
                              </label>

                              <label className="switch" hidden>
                                <input
                                  name="views"
                                  id="views"
                                  onChange={handleInput}
                                  defaultChecked={
                                    editUser?.views === 1 ? true : false
                                  }
                                  checked={editUser?.views === 1 ? true : false}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="views"
                                  id="views"
                                  onChange={handleInput}
                                  value={editUser?.views === 1 ? "1" : "0"}
                                ></span>
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="mt-2 p-2 d-flex justify-content-end">
                          <input
                            type="submit"
                            value="Update"
                            className="btn btn-primary "
                            onClick={handleUpdate}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Edit_newaudio;
