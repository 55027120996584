import "./Header.css";
import React, { useState, useEffect } from "react";
// import logo from '../Images/flicklogo.png'
import logo from "../../components/Images/flicklogo.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function Header() {
  const [terms, setTerms] = useState([]);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const access_token = localStorage.getItem("access_token");

  let username = localStorage.getItem("username");
  // console.log(userName);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // const [data1, setData1] = useState(null);
  // const [data2, setData2] = useState(null);
  // const [data3, setData3] = useState(null);
  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_Baseurl}/admin/Site-ThemeSetting/index`, {
  //       headers: headers,
  //     })
  //     .then((response) => {
  //       setData1(response?.data?.Site_theme_setting[0]?.dark_mode_logo);
  //       setData2(response?.data?.Site_theme_setting[0]?.light_mode_logo);
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //       setIsLoading(false);
  //     });
  //   axios
  //     .get(`${process.env.REACT_APP_Baseurl}/admin/setting/index`, {
  //       headers: headers,
  //     })
  //     .then((response) => {
  //       setData3(response?.data?.settings[0]?.logo);
  //       console.log(response?.data?.settings[0]?.logo)
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //       setIsLoading(false);
  //     });
  // }, []);

  const search = (id) => {
    axios
      .get("", { headers: headers })
      .then((response) => {
        var result = response.data.data.data;
        console.warn("result", result);
        setData(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function logout() {
    localStorage.clear();
    navigate("/");
    window.location.reload();
    setTimeout(() => {}, 500); // delay execution by 1 second (1000 milliseconds)
  }

  // const [theme, setTheme] = useState('light');
  // const toggleTheme = () => {
  //   if (theme === 'light') {
  //     setTheme('dark');
  //   } else {
  //     setTheme('light');
  //   }
  // };
  // useEffect(() => {
  //   document.body.className = theme;
  // }, [theme]);

  // <div className={`App ${theme}`}>
  //   <button onClick={toggleTheme}>Toggle Theme</button>
  // </div>
  const channel = () => {
    window.open("https://channel-flick.flicknexs.com/", "_blank");
  };
  const content = () => {
    window.open("https://cpp-flick.flicknexs.com/", "_blank");
  };
  const website = () => {
    window.open("https://demo-flick.flicknexs.com/", "_blank");
  };

  return (
    <section className="header">
      <header id="header" className="p-3 header d-flex align-items-center">
        <div className="d-flex align-items-center justify-content-between">
          {/* <Link className="logo d-flex align-items-center" to="/cpp/home">
            <img src={logo} alt="flogo" />
            <div className="logo">
              {isLoading ? (
                <p>Loading...</p>
              ) : data1 ? (
                <div>
                  <p>
                    <img src={data1} className="w-100" />
                  </p>
                </div>
              ) : data2 ? (
                <p>
                  <img src={data2} className="w-100" />
                </p>
              ) : data3 ? (
                <p>
                  <img src={data3} className="w-100" />
                </p>
              ) : (
                <p></p>
              )}
            </div>
          </Link> */}

          <i className="bi bi-list toggle-sidebar-btn"></i>
        </div>

        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li className="nav-item d-block d-lg-none">
              <Link className="nav-link nav-icon search-bar-toggle " to="#">
                <i className="bi bi-search"></i>
              </Link>
            </li>

            <li className="nav-item dropdown">
              <Link className="nav-link nav-icon" onClick={channel}>
                <button className="btn btn-primary">Channel Portal</button>
              </Link>
            </li>

            <li className="nav-item dropdown">
              <Link className="nav-link nav-icon" onClick={website}>
                <button className="btn btn-primary">
                  Website{" "}
                  <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                </button>
              </Link>
            </li>

            {/* <li className="nav-item dropdown">
              <Link className="nav-link nav-icon" to="#" data-bs-toggle="dropdown">
                <i className="fa fa-comments"></i>
                <span className="badge bg-success badge-number">3</span>
             </Link>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages">
                <li className="dropdown-header">
                  You have 3 new messages
                  <Link to="#"><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="message-item">
                  <Link to="#">
                    <img src="" alt="" className="rounded-circle" />
                    <div>
                      <h4>Maria Hudson</h4>
                      <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
                      <p>4 hrs. ago</p>
                    </div>
                 </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="message-item">
                  <Link to="#">
                    <img src="" alt="" className="rounded-circle" />
                    <div>
                      <h4>Anna Nelson</h4>
                      <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
                      <p>6 hrs. ago</p>
                    </div>
                 </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="message-item">
                  <Link to="#">
                    <img src="" alt="" className="rounded-circle" />
                    <div>
                      <h4>David Muldon</h4>
                      <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
                      <p>8 hrs. ago</p>
                    </div>
                 </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="dropdown-footer">
                  <Link to="#">Show all messages</a>
                </li>

              </ul>
  </li>*/}

            <li className="nav-item dropdown pe-3">
              <Link
                className="nav-link nav-profile d-flex align-items-center pe-0 "
                to="#"
                data-bs-toggle="dropdown"
              >
                <img src="" className="fa fa-user-circle" />
                <span className="d-none d-md-block dropdown-toggle ps-2">
                  {username}
                </span>
              </Link>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <Link
                    className="dropdown-item d-flex align-items-center"
                    to=""
                  >
                    <i className="bi bi-person"></i>
                    <li className="">
                      <Link className="dropdown-item " to="/">
                        My Profile
                      </Link>
                    </li>
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <Link
                    className="dropdown-item d-flex align-items-center"
                    to=""
                  >
                    <i className="bi bi-gear"></i>
                    <li>
                      <Link className="dropdown-item" to="/">
                        Account Settings
                      </Link>
                    </li>
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <Link
                    className="dropdown-item d-flex align-items-center"
                    to=""
                  >
                    <i className="bi bi-question-circle"></i>
                    <li>
                      <Link className="dropdown-item" to="/">
                        Need Help?
                      </Link>
                    </li>
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <Link className="dropdown-item" to="/" onClick={logout}>
                    <div className="dropdown-item d-flex align-items-center">
                      <i className="bi bi-box-arrow-right"></i>
                      <li> Logout </li>
                    </div>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>
    </section>
  );
}

export default Header;
