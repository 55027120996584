import React, { useState, useEffect } from "react";
import "./signin.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import Navbar from "./Header";

const Signin = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [inputField, setInputField] = useState({
    email: "",
    password: "",
  });
  const [errField, setErrField] = useState({
    emailerr: "",
    passworderr: "",
  });

  function handleChange(e) {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    if (localStorage.getItem("access_token_content") !== null) {
      navigate("/cpp/home");
    }
  }, []);

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // async function login() {
  //   // console.log(inputField);
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_Baseurl}/users/signin`,
  //       { email: inputField?.email, password: inputField?.password },
  //       { cors: "no-cors" },
  //       { credentials: "include" },
  //       {withCredential: false },
  //       { credentials: "same-origin" },
  //       { headers: headers }
  //     )
  //     .then((res) => {
  //       // console.log(res)
  //       let access_token_content = res.data.access_token_content;
  //       let username = res.data.user.username;
  //       let user_id = res.data.user.user_id;
  //       localStorage.setItem("access_token_content", access_token_content);
  //       localStorage.setItem("user_id", user_id);
  //       // localStorage.setItem("name", name)
  //       localStorage.setItem("username", username);
  //       // localStorage.setItem("user", inputField)
  //       navigate("/cpp/home");
  //       window.location.reload();
  //       setTimeout(() => {
  //         //   navigate('/admin/livestream');
  //       }, 2000);
  //     })
  //     .catch((err) => {
  //       alert("Please Enter Correct Details");
  //       console.log(err);
  //     });
  // }
  async function login() {
    // console.log(inputField);    
    axios.post(`${process.env.REACT_APP_Baseurl}/CPP-auth/sign-in`,
      { email: inputField?.email, password: inputField?.password }, headers)
      .then(res => {
        // console.log(res)
        let access_token_content = res.data.access_token;
        let expires_in = res.data.expires_in;
        let content_name = res.data.CPP_user.username;
        let user_id = res.data.CPP_user.id;
        // let role = res.data.Channel_user.role;
        let content_image = res.data.CPP_user.content_image;
        localStorage.setItem("access_token_content", access_token_content);
        localStorage.setItem("content_Partner_id", user_id);
        // localStorage.setItem("role", role);
        localStorage.setItem("content_name", content_name);
        localStorage.setItem("content_image", content_image);
        localStorage.setItem("content_expires_in", expires_in);
        window.location.reload();
        navigate("/cpp/home")
      })
      .catch(err => {
        alert('Please Enter Correct Details')
        console.log(err)
      })
  }

  const validForm = (e) => {
    e.preventDefault();

    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    let formIsValid = true;
    setErrField({
      emailerr: "",
      passworderr: "",
    });
    if (inputField?.email === "") {
      formIsValid = false;
      setErrField((prevState) => ({
        ...prevState,
        emailerr: "Please Enter Email*",
      }));
    } else if (!validateEmail(inputField?.email)) {
      formIsValid = false;
      setErrField((prevState) => ({
        ...prevState,
        emailerr: "Please Enter Valid Email ID",
      }));
    }

    if (inputField?.password === "") {
      formIsValid = false;
      setErrField((prevState) => ({
        ...prevState,
        passworderr: "Please Enter Password*",
      }));
    }

    if (inputField?.email !== "" && inputField?.password !== "") {
      login();
    }
    return formIsValid;
  };

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div>
      <Navbar />
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <form onSubmit={validForm}>
          <section className="signin mt-3">
            <div className="container">
              <div className="d-flex justify-content-center text-center">
                <div className="box-signin mt-4">
                  <h1 className="text-center mt-4">Sign In</h1>
                  <div>
                    <input
                      id="email"
                      name="email"
                      className="mt-4 w-75 p-2"
                      value={inputField?.email}
                      onChange={handleChange}
                      placeholder="Enter your Email"
                      type="email"
                      autoComplete="off"
                    />
                    {errField?.emailerr !== "" && (
                      <span className="siginerror">{errField?.emailerr}</span>
                    )}
                  </div>
                  <div>
                    <span
                      className="eyepasswordsignin"
                      aria-hidden="true"
                      onClick={togglePassword}
                    >
                      {" "}
                      {passwordType === "password" ? (
                        <i className="fa fa-eye-slash"></i>
                      ) : (
                        <i className="fa fa-eye"></i>
                      )}
                    </span>

                    <input
                      id="password"
                      name="password"
                      value={inputField?.password}
                      type={passwordType}
                      onChange={handleChange}
                      className="mt-4 w-75 p-2"
                      placeholder="Enter Your Password"
                      autoComplete="off"
                    />
                    {errField?.passworderr !== "" && (
                      <span className="siginerror">{errField?.passworderr}</span>
                    )}
                  </div>

                  <div className="Remember mt-4 text-start">
                    <input type="checkbox" id="scales" name="check" />
                    <label
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      Remember me
                    </label>
                  </div>

                  <button
                    type="submit"
                    className="btn btn mt-4 w-75 button"
                    onClick={validForm}
                  >
                    Sign In
                  </button>

                  <Link to="">
                    <h6 className="forgot mt-1">Forgot Password?</h6>
                  </Link>
                  <Link to="/signup" className="text-decoration-none">
                    <h6 className="accountsign mb-5 mt-4">
                      Don't have an account? <span>Sign Up?</span>
                    </h6>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </form>
      )}
    </div>
  );
};

export default Signin;
