import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const All_LiveVideo = () => {
  const [data, setData] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);
  const navigate = useNavigate();

  const [editUser, setEditUser] = useState({ status: "", livestream_id: "" });
  const [message, setMessage] = useState("");

  const access_token = localStorage.getItem("access_token");
  const [Content_Partner_id, setContent_Partner_id] = useState("1");

  // console.log(Channel_Partner_id)
  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // console.log(headers)

  useEffect(async (id) => {
    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-List`,
        { Content_Partner_id: Content_Partner_id },
        {
          headers: headers,
        }
      )
      .then((response) => {
        // console.log('api checks', response)
        var result = response?.data?.LiveStream?.data;
        var results = response?.data?.LiveStream?.data[0]?.id;
        setData(result);
        console.log(response.data)
      })
      .catch((error) => console.log(error));
  }, []);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };
  const handleUpdate = async (id, e) => {
    e.preventDefault();
    console.log(id);
    alert(id);
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
    const editInputvalue = {
      status: editUser.status,
      livestream_id: editUser.livestream_id,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/CPP/live-stream/livestream-slider/` +
        data.id,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Success");
      // window.location.reload();
      setTimeout(() => {}, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-destory/` + id,
        { headers: headers }
      )
      .then((response) => {
        alert("Item Deleted Successfully !");
        // navigate('/admin/livestream')
        window.location.reload();
      });
  };

  function handlePreClick() {
    // console.log('preview');

    axios
      .post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-List?page=${
          page - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data);
        setPrevious(res?.data?.LiveStream?.data);
        setData(res?.data?.LiveStream?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpage() {
    // console.log('handlefirstpage');

    axios
      .post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-List?page=${
          page - 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data);
        setPrevious(res?.data?.LiveStream?.data);
        setData(res?.data?.LiveStream?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClick() {
    // console.log('next');

    axios
      .post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-List?page=${
          page + 1
        }`,
        { headers: headers }
      )
      .then((res) => {
        // console.log("next", res?.data?.LiveStream);
        setNext(res?.data?.LiveStream?.data);
        setData(res?.data?.LiveStream?.data);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section all-live-stream container-fluid">
            <div className="col-lg-12 p-2">
              <div className="card">
                <div className="card-body">
                  <div className="row pt-3">
                    <div className="col-md-6">
                      <div className="moderator-section-title">
                        <h4>
                          <i className="entypo-globe"></i> Live Videos
                        </h4>
                      </div>
                    </div>
                    <div className="col-md-6" align="right">
                      <Link to="/cpp/add-new-livevideo">
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          <i className="fa fa-plus-circle"></i> Add New
                        </button>
                      </Link>
                    </div>
                  </div>

                  {/* <div className="d-flex justify-content-between p-3">
                    <div className="d-flex">
                      <p>Show 10</p>
                      <p>&nbsp; &nbsp; entries</p>
                    </div>
                    <div className="d-flex">
                      <p>Search:</p>
                      <input
                        type="search"
                        id="gsearch"
                        name="gsearch"
                        className="border-0 search-rev"
                      />
                    </div>
                  </div> */}

                  <Table className="mt-4 text-center">
                    <thead>
                      <tr className="title">
                        <th>S.No</th>
                        <th>Image</th>
                        <th>Title</th>
                        <th>User Name</th>
                        <th>Video Type</th>
                        <th>Video Access</th>
                        <th>Status</th>
                        <th>Stream Type</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    {data?.map((item, key) => (
                      <thead key={item?.id}>
                        <tr className="text-center">
                          <td key={key}> {key + 1} </td>
                          <td className="">
                            <img
                              src={item?.Video_thumbnail}
                              className="img-fluid img-thumbnail img-manage"
                              alt="image"
                            />
                          </td>
                          <td>{item?.title}</td>
                          <td>{item?.title}</td>
                          <td>{item?.publish_type}</td>
                          <td>{item?.access}</td>
                          <td className="text-center">
                            {item?.active == 0 || item?.active == null ? (
                              <td className="bg-warning video_active">
                                Inactive
                              </td>
                            ) : item?.active == 1 &&
                              item?.active == 1 &&
                              item?.active == 1 ? (
                              <td className="bg-success video_active">
                                Active
                              </td>
                            ) : (
                              <td className="bg-warning video_active">Draft</td>
                            )}
                          </td>
                          <td>{item?.url_type}</td>
                          <td>
                           
                            <Link
                              to={"/cpp/edit-livestream/" + item?.id}
                              className="edit ms-1"
                            >
                              <i
                                className="fa fa-pencil-square"
                                aria-hidden="true"
                              ></i>
                            </Link>
                            <Link to="" className="delete ms-1">
                              <span onClick={() => deleteOperation(item?.id)}>
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </Link>
                          </td>
                        </tr>
                      </thead>
                    ))}
                  </Table>

                  <div className="row d-flex text-center">
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={handlePreClick}
                      >
                        {"<< Previous"}
                      </button>
                    </div>
                    <div className="col-lg-4">
                      <p className="">{page}</p>
                    </div>
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleNxtClick}
                      >
                        {"Next >>"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default All_LiveVideo;
