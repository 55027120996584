import { Link, useNavigate } from "react-router-dom";
import JoditEditor from "jodit-react";
import React, { useState, useRef, useEffect } from "react";
import axios, { CancelToken, isCancel } from "axios";
import { ProgressBar } from "react-bootstrap";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Select from "react-select";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Creatable from "react-select/creatable";

const Add_NewVideo = () => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [file, setFile] = useState("");
  const [video_id, setVideo_id] = useState("");

  const [video_duration, setVideo_duration] = useState("");
  const [video_title, setVideo_title] = useState("");

  // console.log(video_id)
  // console.log(video_duration)
  // console.log(video_title)

  const [video_type, setVideo_type] = useState("");
  const [m3u8_url, setM3u8_url] = useState("");
  const [mp4_url, setMp4_url] = useState("");
  const [embed_code, setEmbed_code] = useState("");
  const [slug, setSlug] = useState("");
  const [type, setType] = useState("");
  const [active, setActive] = useState("");
  const [status, setStatus] = useState("");
  const [draft, setDraft] = useState("");
  const [rating, setRating] = useState("");
  const [details, setDetails] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [player_image, setPlayer_image] = useState("");
  const [video_tv_image, setVideo_tv_image] = useState("");
  const [enable_video_title_image, setEnable_video_title_image] = useState("");
  const [video_title_image, setVideo_title_image] = useState("");
  const [android_tv, setAndroid_tv] = useState("");
  const [video_gif, setVideo_gif] = useState("");
  const [search_tags, setSearch_tags] = useState("");
  const [global_ppv, setGlobal_ppv] = useState("");
  const [ios_ppv_price, setIos_ppv_price] = useState("");
  const [featured, setFeatured] = useState("");
  const [banner, setBanner] = useState("");
  const [enable, setEnable] = useState("");
  const [webm_url, setWebm_url] = useState("");
  const [ogg_url, setOgg_url] = useState("");
  const [disk, setDisk] = useState("");
  const [original_name, setOriginal_name] = useState("");
  const [path, setPath] = useState("");
  const [stream_path, setStream_path] = useState("");
  const [processed_low, setProcessed_low] = useState("");
  const [converted_for_streaming_at, setConverted_for_streaming_at] =
    useState("");
  const [views, setViews] = useState("");
  const [year, setYear] = useState("");
  const [Recommendation, setRecommendation] = useState("");
  const [country, setCountry] = useState("");
  const [publish_status, setPublish_status] = useState("");
  const [publish_type, setPublish_type] = useState("");
  const [publish_time, setPublish_time] = useState("");
  const [skip_recap, setSkip_recap] = useState("");
  const [skip_intro, setSkip_intro] = useState("");
  const [recap_start_time, setRecap_start_time] = useState("");
  const [recap_end_time, setRecap_end_time] = useState("");
  const [intro_start_time, setIntro_start_time] = useState("");
  const [intro_end_time, setIntro_end_time] = useState("");
  const [pdf_files, setPdf_files] = useState("");
  const [reels_thumbnail, setReels_thumbnail] = useState("");
  const [reelvideo, setReelvideo] = useState("");
  const [url, setUrl] = useState("");
  const [url_link, setUrl_link] = useState("");
  const [url_linktym, setUrl_linktym] = useState("");
  const [url_linksec, setUrl_linksec] = useState("");
  const [urlEnd_linksec, setUrlEnd_linksec] = useState("");
  const [trailer_type, setTrailer_type] = useState("");
  const [trailer, setTrailer] = useState("");
  const [trailer_description, setTrailer_description] = useState("");
  const [ads_status, setAds_status] = useState("");
  const [default_ads, setDefault_ads] = useState("");
  const [pre_ads_category, setPre_ads_category] = useState("");
  const [mid_ads_category, setMid_ads_category] = useState("");
  const [post_ads_category, setPost_ads_category] = useState("");
  const [pre_ads, setPre_ads] = useState("");
  const [mid_ads, setMid_ads] = useState("");
  const [post_ads, setPost_ads] = useState("");
  const [time_zone, setTime_zone] = useState("");
  const [home_genre, setHome_genre] = useState("");
  const [in_menu, setIn_menu] = useState("");
  const [footer, setFooter] = useState("");
  const [uploaded_by, setUploaded_by] = useState("");
  const [user_id, setUser_id] = useState("");

  const [user_access, setUser_access] = useState([]);
  const [useraccess, setUserAccess] = useState("");
  const [inapppurchase, setInappPurchase] = useState("");

  const [ads_tag_url_id, setAds_tag_url_id] = useState("");

  const [data, setData] = useState([]);
  const [publish, setPublish] = useState([]);
  const [audio_albums, setAudio_albums] = useState([]);
  const [order1, setOrder1] = useState({});
  const [age_restrictdata, setAge_restrictdata] = useState([]);

  const [age_restrict, setAge_restrict] = useState([]);

  // console.log(age_restrictdata)

  const [message, setMessage] = useState("");

  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");
  const [Content_Partner_id, setContent_Partner_id] = useState("1");

  const [editUser, setEditUser] = useState({ video_title: "", duration: "" });
  // console.log(editUser)

  // Multiselect
  const [block_country, setBlock_Country] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [available_country, setAvailable_country] = useState([]);
  const [
    selectedOptionsAvailable_country,
    setSelectedOptionsAvailable_country,
  ] = useState([]);

  const [related_videos, setRelated_videos] = useState([]);
  const [selectedOptionsRelated_videos, setSelectedOptionsRelated_videos] =
    useState([]);

  const [language, setLanguage] = useState([]);
  const [selectedOptionslanguage, setSelectedOptionslanguage] = useState([]);

  const [artists, setArtists] = useState([]);
  const [selectedOptionsartists, setSelectedOptionsartists] = useState([]);

  const [category, setCategory] = useState([]);
  const [selectedOptionscategory, setSelectedOptionscategory] = useState([]);

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/video-create`,
        { headers: headers }
      );
      setBlock_Country(response.data.Block_Country);
      setAvailable_country(response.data.Available_country);
      setRelated_videos(response.data.Related_videos);
      setLanguage(response.data.video_language);
      // setAge_Restrict(response.data.Age_Restrict);
      setArtists(response.data.video_artist);
      setCategory(response.data.video_category);
      setInputValueAPI(response.data.ppv_gobal_price);
      // setAudio_albums(response.data.audio_albums);
      // setAlbum(response?.data?.audio_albums);

      var age_restrictdata = response.data.Age_Restrict;

      setAge_restrictdata(age_restrictdata);

      var result = response?.data?.user_access;
      var InappPurchase = response?.data?.InappPurchase;
      setUser_access(result);
      setInappPurchase(InappPurchase);
      //  console.log(response.data);
      console.log(response.data.video_artist);
      // console.log(response);
      // console.log(response.data.ppv_gobal_price);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const handleSelectChangeRelated_videos = (selectedValues) => {
    setSelectedOptionsRelated_videos(selectedValues);
  };

  const handleSelectChangeAvailable_country = (selectedValues) => {
    setSelectedOptionsAvailable_country(selectedValues);
  };

  const handleSelectChangelanguage = (selectedValues) => {
    setSelectedOptionslanguage(selectedValues);
  };

  const handleSelectChangeartists = (selectedValues) => {
    setSelectedOptionsartists(selectedValues);
  };

  const handleSelectChangecategory = (selectedValues) => {
    setSelectedOptionscategory(selectedValues);
  };
  // Multiselect

  // const handleInput = (e) => {
  //   setEditUser({ ...editUser, [e.target.name]: e.target.value });

  //   if (e.target.checked == true) {
  //     setGlobal_ppv({ ...global_ppv, [e.target.name]: 1 });
  //     setFeatured({ ...featured, [e.target.name]: 1 });
  //     setBanner({ ...banner, [e.target.name]: 1 });
  //     setActive({ ...active, [e.target.name]: 1 });
  //     setStatus({ ...status, [e.target.name]: 1, [e.target.id]: 0 });
  //     setEnable_video_title_image({
  //       ...enable_video_title_image,
  //       [e.target.name]: 1,
  //     });
  //   } else {
  //     setGlobal_ppv({ ...global_ppv, [e.target.name]: 0 });
  //     setFeatured({ ...featured, [e.target.name]: 0 });
  //     setBanner({ ...banner, [e.target.name]: 0 });
  //     setActive({ ...active, [e.target.name]: 0 });
  //     setStatus({ ...status, [e.target.name]: 0, [e.target.id]: 0 });
  //     setEnable_video_title_image({
  //       ...enable_video_title_image,
  //       [e.target.name]: 0,
  //     });
  //   }
  // };

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
    setVideo_title({ ...video_title, [e.target.name]: e.target.value });
    setVideo_duration({ ...video_duration, [e.target.name]: e.target.value });

    if (e.target.checked == true) {
      setGlobal_ppv({ ...global_ppv, [e.target.name]: 1 });
      setViews({ ...views, [e.target.name]: 1 });
      setEnable_video_title_image({
        ...enable_video_title_image,
        [e.target.name]: 1,
      });
      setFeatured({ ...featured, [e.target.name]: 1 });
      setBanner({ ...banner, [e.target.name]: 1 });
      setActive({ ...active, [e.target.name]: 1 });
      setStatus({ ...status, [e.target.name]: 1, [e.target.id]: 0 });
    } else {
      setGlobal_ppv({ ...global_ppv, [e.target.name]: 0 });
      setViews({ ...views, [e.target.name]: 0 });
      setEnable_video_title_image({
        ...enable_video_title_image,
        [e.target.name]: 0,
      });
      setFeatured({ ...featured, [e.target.name]: 0 });
      setBanner({ ...banner, [e.target.name]: 0 });
      setActive({ ...active, [e.target.name]: 0 });
      setStatus({ ...status, [e.target.name]: 0, [e.target.id]: 0 });
    }
  };

  const [detal, setDetal] = useState();
  const contentFieldChanaged = (data) => {
    setDetal(data);
    // console.log("datasssssss", data);
  };
  const [trailerdec, setTrailerdec] = useState();
  const contentFieldChanagedtrailerdec = (data) => {
    setTrailerdec(data);
    // console.log("datasssssss", data);
  };
  const [descript, setDescript] = useState();
  const contentFieldChanageddescript = (data) => {
    setDescript(data);
    // console.log("datasssssss", data);
  };

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    console.log(selectedValue5); // Selected value
  };

  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setSelectedFile(selectedFile);
    uploadFile(selectedFile);
  };

  const uploadFile = (selectedFile) => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("Content_Partner_id", Content_Partner_id);

      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/video-upload-file`,
        { headers: headers }
      );
      xhr.setRequestHeader("Authorization", "Bearer " + access_token);

      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          setUploadProgress(progress);
        }
      });

      xhr.onreadystatechange = (res) => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          // Request completed
          if (xhr.status === 200) {
            // Successful response
            alert("Added successfully!");
            // Handle the response data here
            const responseData = JSON.parse(xhr.responseText);
            var videoid = responseData.video_id;
            var video_title = responseData.video_title;
            var duration = responseData.duration;
            setEditUser(responseData);
            setVideo_id(videoid);
            setVideo_duration(duration);
            setVideo_title(video_title);
            // console.log("Response videoid:", videoid);
            // console.log("Response video_title:", video_title);
            // console.log("Response duration:", duration);
            // console.log("Response data:", responseData);
          } else {
            // Error response
            console.error("Request failed with status:", xhr.status);
          }
        }
      };

      xhr.send(formData);
    }
  };

  async function urlsupload() {
    // console.log('append data', title, image, url_type, player_image, fb_streamkey, twitter_streamkey, youtube_streamkey, fb_restream_url, twitter_restream_url, youtube_restream_url, ppv_price, Tv_live_image, search_tags, embed_url, m3u_url, details, year, banner, active, publish_type, featured, enable_restream, duration, access, live_stream_video, mp4_url, rating, mp4_url, description, category, artist, language);

    const formData = new FormData();

    formData.append("video_type", video_type);
    formData.append("m3u8_url", m3u8_url);
    formData.append("mp4_url", mp4_url);
    formData.append("embed_code", embed_code);
    console.log("append data", formData);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/video-upload-url`,
        formData,
        {
          headers: headers,
        }
      )
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status == true) {
          var result = response.data;
          var result = response.data.video_id;
          // console.log('result', result);
          // console.log('result', result);
          setVideo_id(result);
          // navigate('/admin/livestream')
          alert("Added successfully  !");
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  const getFileSizeInMB = () => {
    if (selectedFile) {
      const fileSizeInBytes = selectedFile.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
      return fileSizeInMB.toFixed(2);
    }
    return null;
  };

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const cancelFileUpload = useRef(null);

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload.");
  };

  const [uploadPercentagefile, setUploadPercentagefile] = useState(0);
  const cancelFileUploadfile = useRef(null);

  const uploadFilefile = ({ target: { mp3_url } }) => {
    let data = new FormData();
    data.append("mp3_url", mp3_url);

    console.log("mp3_url", mp3_url);

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);

        if (percent < 100) {
          setUploadPercentagefile(percent);
        }
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel)
      ),
    };
    axios
      .post(`${process.env.REACT_APP_Baseurl}/admin/video-upload-file`, data, {
        headers: headers,
      })
      .then((res) => {
        console.log(res);
        if (res.data.status == true) {
          var data = res.data;
          var video_id = res.data.video_id;
          // console.log(data)
          // console.log(Audio_id)
          setVideo_id(video_id);
          setUploadPercentagefile(100);

          setTimeout(() => {
            setUploadPercentagefile(0);
          }, 1000);
        } else {
          setMessage("Some error Occured");
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err);

        if (isCancel(err)) {
          alert(err.message);
        }
        setUploadPercentagefile(0);
      });
  };

  const cancelUploadfile = () => {
    if (cancelFileUploadfile.current)
      cancelFileUploadfile.current("User has canceled the file upload.");
  };

  const editorvideodes = useRef(null);
  const contentFieldChanagedvideodes = (data) => {
    setDescription({ ...description, content: data });
  };

  const editorlinkanddes = useRef(null);
  const contentFieldChanagedlinkanddes = (data) => {
    setDetails({ ...details, content: data });
  };

  const editortrailerdes = useRef(null);
  const contentFieldChanagedtrailerdes = (data) => {
    setTrailer_description({ ...trailer_description, content: data });
  };

  const [selectedValue, setSelectedValue] = useState("");
  const [apiResponse, setApiResponse] = useState("");

  const handleSelectChangeurl = async (event) => {
    const { value } = event.target;
    setSelectedValue(value);

    const formData = new FormData();

    formData.append("value", value);

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/video-advertisement-tag-url`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        console.log("apiasas", response);
        if (response.data.status == true) {
          var result = response.data;

          console.log("api", result);
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  };

  async function videoupload() {
    const editorvideodes = description;
    var editorvideodesdata = JSON.stringify(editorvideodes);
    var editorvideodessubmit = editorvideodesdata;

    const editorlinkanddes = details;
    var editorlinkanddesdata = JSON.stringify(editorlinkanddes);
    var editorlinkanddessubmit = editorlinkanddesdata;

    const editortrailerdes = details;
    var editortrailerdesdata = JSON.stringify(editortrailerdes);
    var editortrailerdessubmit = editortrailerdesdata;

    const editInputvalue = active.active;
    var data = JSON.stringify(editInputvalue);
    var aactivedata = data;

    const editInputglobal_ppv = global_ppv.global_ppv;
    var dataglobal_ppv = JSON.stringify(editInputglobal_ppv);
    var global_ppvdata = dataglobal_ppv;

    const editInputfeatured = featured.featured;
    var datafeatured = JSON.stringify(editInputfeatured);
    var featureddata = datafeatured;

    const editInputbanner = banner.banner;
    var databanner = JSON.stringify(editInputbanner);
    var bannerdata = databanner;

    const editInputstatus = status.status;
    var datastatus = JSON.stringify(editInputstatus);
    var statusdata = datastatus;

    const editInputenable_video_title_image =
      enable_video_title_image.enable_video_title_image;
    var dataenable_video_title_image = JSON.stringify(
      editInputenable_video_title_image
    );
    var enable_video_title_imagedata = dataenable_video_title_image;

    const formData = new FormData();

    formData.append("video_id", video_id);
    formData.append("title", editUser.video_title);
    formData.append("slug", slug);
    formData.append("type", type);
    formData.append("active", aactivedata);
    formData.append("status", statusdata);
    formData.append("draft", draft);
    formData.append("rating", rating);
    formData.append("duration", editUser.duration);
    formData.append("details", JSON.stringify(detal));
    formData.append("description", JSON.stringify(descript));
    formData.append("android_tv", android_tv);
    formData.append("video_gif", video_gif);
    formData.append("skip_intro", skip_intro);

    formData.append("search_tags", search_tags);
    formData.append("access", useraccess);
    formData.append("global_ppv", global_ppvdata);
    formData.append("ppv_price", inputValue);
    formData.append("ios_ppv_price", ios_ppv_price);
    formData.append("featured", featureddata);
    formData.append("banner", bannerdata);
    formData.append("enable", enable);
    formData.append("webm_url", webm_url);
    formData.append("ogg_url", ogg_url);
    formData.append("disk", disk);
    formData.append("original_name", original_name);
    formData.append("path", path);
    formData.append("stream_path", stream_path);
    formData.append("processed_low", processed_low);
    formData.append("converted_for_streaming_at", converted_for_streaming_at);
    formData.append("views", views);
    formData.append("year", year);
    formData.append("Recommendation", Recommendation);
    formData.append("country", country);
    formData.append("publish_status", publish_status);
    formData.append("publish_type", publish_type);
    formData.append("publish_time", publish_time);
    formData.append("skip_recap", skip_recap);
    formData.append("recap_start_time", recap_start_time);
    formData.append("recap_end_time", recap_end_time);
    formData.append("intro_start_time", intro_start_time);
    formData.append("intro_end_time", intro_end_time);
    formData.append("pdf_files", pdf_files);
    formData.append("reels_thumbnail", reels_thumbnail);
    formData.append("enable_video_title_image", enable_video_title_imagedata);
    formData.append("reelvideo", reelvideo);
    formData.append("url_link", url);

    formData.append(
      "artist_id",
      JSON.stringify(selectedOptionsartists?.map((option) => option.value))
    );
    formData.append(
      "block_country_id",
      JSON.stringify(selectedOptions?.map((option) => option.value))
    );
    formData.append(
      "available_country_id",
      JSON.stringify(
        selectedOptionsAvailable_country?.map((option) => option.value)
      )
    );
    formData.append(
      "category_id",
      JSON.stringify(selectedOptionscategory?.map((option) => option.value))
    );
    formData.append(
      "languages_id",
      JSON.stringify(selectedOptionslanguage?.map((option) => option.value))
    );
    formData.append("age_restrict", age_restrict);
    formData.append(
      "related_videos",
      JSON.stringify(
        selectedOptionsRelated_videos?.map((option) => option.value)
      )
    );

    formData.append("url_linktym", url_linktym);
    formData.append("url_linksec", url_linksec);
    formData.append("urlEnd_linksec", urlEnd_linksec);
    formData.append("trailer_type", trailer_type);
    formData.append("trailer", trailer);
    formData.append("trailer_description", JSON.stringify(trailerdec));
    formData.append("ads_status", ads_status);
    formData.append("default_ads", default_ads);
    formData.append("pre_ads_category", pre_ads_category);
    formData.append("mid_ads_category", mid_ads_category);
    formData.append("post_ads_category", post_ads_category);
    formData.append("pre_ads", pre_ads);
    formData.append("mid_ads", mid_ads);
    formData.append("post_ads", post_ads);
    formData.append("time_zone", time_zone);
    formData.append("home_genre", home_genre);
    formData.append("in_menu", in_menu);
    formData.append("footer", footer);
    formData.append("uploaded_by", uploaded_by);
    formData.append("user_id", user_id);
    formData.append("ads_tag_url_id", ads_tag_url_id);
    formData.append("Content_Partner_id", Content_Partner_id);

    formData.append("ads_position", selectedAdsPosition?.value);
    formData.append("live_ads", secondSelectOptions?.value);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/video-store`,
        formData,
        {
          headers: headers,
        }
      )
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status == true) {
          var result = response.data;

          const formDatas = new FormData();

          formDatas.append("video_id", video_id);
          formDatas.append("image", image);
          formDatas.append("player_image", player_image);
          formDatas.append("video_tv_image", video_tv_image);
          formDatas.append("video_title_image", video_title_image);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/CPP/Backend/video-Image-upload`,
              formDatas,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api' , response);
              if (response.data.status == true) {
                var result = response.data;
                // console.log('result', response);
                // navigate('/admin/all-artist')
              }
            });

          const formDatass = new FormData();

          formDatass.append("video_id", video_id);
          formDatass.append("trailer", trailer);
          formDatass.append("trailer_type", trailer_type);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/CPP/Backend/video-Trailer-url`,
              formDatass,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api' , response);
              if (response.data.status == true) {
                var result = response.data;
                // console.log('result', response);
                // navigate('/admin/all-artist')
                // alert("Added successfully  !")
              }
            });

          const formD = new FormData();

          formD.append("video_id", video_id);
          formD.append("trailer", trailer);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/CPP/Backend/videos-Trailer-upload`,
              formD,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api' , response);
              if (response.data.status == true) {
                var result = response.data;
                // console.log('result', response);
                // navigate('/admin/all-artist')
              }
            });

          const formDa = new FormData();

          formDa.append("video_id", video_id);
          formDa.append("pdf_file", pdf_files);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/CPP/Backend/video-Epaper`,
              formDa,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api' , response);
              if (response.data.status == true) {
                var result = response.data;
                // console.log('result', response);
                // navigate('/admin/all-artist')
              }
            });
          alert("Added successfully  !");
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const fetchAdsPositions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/video-create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          const adsPositionOptions = data.ads_position.map((position) => ({
            value: position.position,
            label: position.name,
          }));
          console.log(adsPositionOptions);
          setAdsPositions(adsPositionOptions);
        } else {
          throw new Error("Request failed with status: " + response.status);
        }
      } catch (error) {
        console.log("Error fetching ads positions:", error);
      }
    };
    fetchAdsPositions();

    axios
      .get(`${process.env.REACT_APP_Baseurl}/CPP/Backend/video-create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.livestream_language)
        // var age_restrictdata = response.data.Age_Restrict;
        var ads_category = response.data.ads_category;

        // setAge_restrictdata(age_restrictdata);
        setPre_ads_category(ads_category);
        setMid_ads_category(ads_category);
        setPost_ads_category(ads_category);
        // console.log(response)
        // console.log(age_restrictdata)
        // console.log(user_access)
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (selectedAdsPosition !== "") {
      // console.log(selectedAdsPosition, "adsssssssssss");
      const formData = new FormData();
      formData.append("position", selectedAdsPosition?.value);

      axios

        .post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/video-advertisement-tag-url`,
          formData,
          { headers: headers }
        )

        .then((response) => {
          const data = response.data;
          // console.log(data, "exceptioned");

          const adsNames = data.Advertisement.map((position) => ({
            value: position.id,
            label: position.ads_name,
          }));

          setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition]);

  const [enable_restream, setEnable_restream] = useState("");
  const [enablestreamvisible, setEnablestreamvisible] = useState("");
  const [enable_restreamtrailer, setEnable_restreamtrailer] = useState("");
  const [enablestreamvisibletrailer, setEnablestreamvisibletrailer] =
    useState("");

  function proceednext() {
    switch (enable_restream) {
      case "0":
        return <div></div>;
      case "1":
        return (
          <div className="mt-5 mb-5 videoupload">
            <label>Upload Video </label>

            <div className=" text-center file-drag mb-5">
              <label id="label-file-upload" htmlFor="input-file-upload">
                <div
                  name="addvideo"
                  id="addvideo"
                  onChange={(event) => {
                    setEnable_restreamaddvideo(event.target.name);
                  }}
                >
                  <input
                    type="file"
                    accept="video/mp4"
                    id="input-file-upload"
                    name="addvideo"
                    multiple
                    className="form-control"
                    onChange={handleFileChange}
                    onClick={(event) => {
                      setEnable_restreamaddvideo(event.target.name);
                    }}
                  />
                  {/* <div>File Size: {getFileSizeInMB()} MB</div>
                    <div>Upload Progress: {uploadProgress}%</div> */}

                  {selectedFile && <div>File Size: {getFileSizeInMB()} MB</div>}
                  {selectedFile && (
                    <div>Upload Progress: {uploadProgress}%</div>
                  )}
                </div>

                {/* <div
                    name="addvideo"
                    id="addvideo"
                    onChange={(event) => {
                      setEnable_restreamaddvideo(event.target.name);
                    }}
                  >
                    <p>Drag and drop your file here or</p>
                    <p>
                      <input
                        type="file"
                        className="form-control"
                        onChange={uploadFile}
                        multiple
                        accept="video/mp4"
                        id="input-file-upload"
                        name="addvideo"
                        onClick={(event) => {
                          setEnable_restreamaddvideo(event.target.name);
                        }}
                      />
                    </p>
                  </div> */}
              </label>

              <div
                onClick={(event) => {
                  setEnablestreamvisibleaddvideo(event.target.value);
                }}
              >
                {proceednextaddvideo()}
              </div>

              {/* <input className="form-check-input ms-5" type="radio" name="addvideo" id="addvideo"
                  value='1' onClick={(event) => { setEnable_restreamaddvideo(event.target.name); }} />
                <label className="form-check-label ms-1" for="flexRadioDefault1">
                  Video Upload
                </label> */}

              {/* <div className='mb-5'>
                {uploadPercentage > 0 && (
                  <div className="row mt-3">
                    <div className="col pt-1">
                      <ProgressBar
                        now={uploadPercentage}
                        striped={true}
                        label={`${uploadPercentage}%`}
                      />
                    </div>
                    <div className="col-auto">
                      <span
                        className="text-primary cursor-pointer"
                        onClick={() => cancelUpload()}
                      >
                        Cancel
                      </span>
                    </div>
                  </div>
                )}
  
              </div> */}
            </div>
          </div>
        );
      case "m3u8_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> m3u8 URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="m3u8_trailer"
                    onChange={(e) => setM3u8_url(e.target.value)}
                    placeholder="m3u8 Trailer"
                  />
                </div>
                {/* <div className='mt-4 text-left'>
                  <button className='btn btn-primary' onClick={() => { mp3filesubmit(); handleNext(); }} >
                    Submit </button>
                </div> */}
              </div>
              <div className="mt-4 text-center">
                <Button
                  variant="contained"
                  onClick={() => {
                    urlsupload();
                    handleNext();
                  }}
                  className="btn btn-primary"
                >
                  Proceed to Next
                </Button>
              </div>
            </div>
          </div>
        );
      case "mp4_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> Mp4 File URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="live_mp3_url"
                    placeholder="Mp4 File URL"
                    onChange={(e) => setMp4_url(e.target.value)}
                  />
                </div>
                {/* <div className='mt-4 text-left'>
                  <button className='btn btn-primary' onClick={() => { mp4filesubmit(); }} >
                    Submit </button>
                </div> */}
              </div>
              <div className="mt-4 text-center">
                <Button
                  variant="contained"
                  onClick={() => {
                    urlsupload();
                    handleNext();
                  }}
                  className="btn btn-primary"
                >
                  Proceed to Next
                </Button>
              </div>
            </div>
          </div>
        );
      case "embed":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> Embed URL: </label>
                  <div>
                    <p>
                      Example URL Format : (
                      Https://Www.Youtube.Com/Embed/*Xxxxxxxxx*/ ){" "}
                    </p>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name="embed_trailer"
                    placeholder="Embed Trailer"
                    onChange={(e) => setEmbed_code(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 text-center">
              <Button
                variant="contained"
                onClick={() => {
                  urlsupload();
                  handleNext();
                }}
                className="btn btn-primary"
              >
                Proceed to Next
              </Button>
            </div>
          </div>
        );

      default:
        return null;
    }
  }
  const [enable_restreampublic, setEnable_restreampublic] = useState("");
  const [enablestreamvisiblepublic, setEnablestreamvisiblepublic] =
    useState("");

  function proceednextpublic() {
    switch (enable_restreampublic) {
      case "publish_now":
        return <div></div>;
      case "publish_later":
        return (
          <div className="mb-3">
            <label className="m-0">Publish Time</label>
            <input
              type="datetime-local"
              className="form-control"
              id="publish_time"
              name="publish_time"
              onChange={(e) => setPublish_time(e.target.value)}
            />
          </div>
        );
      default:
        return null;
    }
  }

  const [enable_restreamaddvideo, setEnable_restreamaddvideo] = useState("");
  const [enablestreamvisibleaddvideo, setEnablestreamvisibleaddvideo] =
    useState("");

  function proceednextaddvideo() {
    switch (enable_restreamaddvideo) {
      case "addvideo":
        return (
          <div>
            <div className="mb-12">
              {uploadPercentage > 0 && (
                <div className="row mt-3">
                  <div className="col pt-1">
                    <ProgressBar
                      now={uploadPercentage}
                      striped={true}
                      label={`${uploadPercentage}%`}
                    />
                  </div>
                  <div className="col-auto">
                    <span
                      className="text-primary cursor-pointer"
                      onClick={() => cancelUpload()}
                    >
                      Cancel
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className="text-center mt-2 mb-5">
              <button onClick={handleNext} className="btn btn-primary">
                Proceed to Next{" "}
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  function proceednexttrailer() {
    switch (enable_restreamtrailer) {
      case "0":
        return <div></div>;
      case "1":
        return (
          <div className="col-6">
            <label className="mb-1"> Upload Trailer : </label>
            <div className=" text-center file-drag mt-2">
              <label id="label-file-upload" htmlFor="input-file-upload">
                <div>
                  <p>Drag and drop your file here or</p>
                  <p>
                    <input
                      type="file"
                      className="form-control"
                      onChange={(e) => setTrailer(e.target.files[0])}
                      multiple
                      accept="video/mp4"
                      id="input-file-upload"
                    />
                  </p>
                </div>
                <br />
              </label>
            </div>
          </div>
        );
      case "m3u8_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-6 ">
                <div className="">
                  <label className="mb-1">Trailer m3u8 URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="mp3_url"
                    onChange={(e) => setTrailer(e.target.value)}
                    placeholder="m3u8 URL"
                  />
                </div>
                {/* <div className='mt-4 text-left'>
                  <button className='btn btn-primary' onClick={() => { mp3filesubmit(); handleNext(); }} >
                    Submit </button>
                </div> */}
              </div>
            </div>
          </div>
        );
      case "mp4_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-6 ">
                <div className="">
                  <label className="mb-1"> Trailer Mp4 File URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="mp4_trailer"
                    placeholder="mp4 Trailer"
                    onChange={(e) => setTrailer(e.target.value)}
                  />
                </div>
                {/* <div className='mt-4 text-left'>
                  <button className='btn btn-primary' onClick={() => { mp4filesubmit(); }} >
                    Submit </button>
                </div> */}
              </div>
            </div>
          </div>
        );
      case "embed_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-6 ">
                <div className="">
                  <label className="mb-1"> Trailer Embed URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="live_mp3_url"
                    placeholder="Embed URL"
                    onChange={(e) => setTrailer(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  }

  const [livesource, setLivesource] = useState("");

  function live_stream_source() {
    switch (livesource) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div class="row mt-3 text-start ">
            <div class="col-sm-6">
              <label class="m-0">PPV Price</label>
              <div className="col-lg-12 row">
                <div className="col-8 col-lg-8">
                  <p class="p1">Apply PPV Price from Global Settings? </p>
                </div>
                <div className="col-4 col-lg-4">
                  <label className="switch">
                    <input
                      name="ppv_price"
                      defaultChecked={checkboxChecked}
                      onChange={handleCheckboxChange}
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="ppv_price"
                      checked={checkboxChecked}
                      onChange={handleCheckboxChange}
                    ></span>
                  </label>
                </div>
              </div>

              <div class="panel-body">
                <input
                  type="text"
                  class="form-control"
                  placeholder="PPV Price"
                  name="ppv_price"
                  id="price"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div class="col-sm-6">
              <label class="m-0"> IOS PPV Price</label>
              <p class="p1">Apply IOS PPV Price from Global Settings?</p>
              <div class="panel-body ppv_price_ios">
                {/* <Select
                                name="ios_ppv_price"
                                className="form-control"
                                options={ios_ppv_price}
                                onChange={(e) =>
                                  setIos_ppv_price(e.target.value)
                                }
                              /> */}

                <select
                  onChange={(e) => setIos_ppv_price(e.target.value)}
                  className="form-control"
                >
                  <option value="">Choose a PPV Price</option>
                  {inapppurchase?.map((item, key) => (
                    <option value={item.id}>{item.plan_price}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  const steps = [
    {
      label: "Add New Video",
      description: (
        <div>
          <div
            onClick={(event) => {
              setEnablestreamvisible(event.target.value);
            }}
          >
            {proceednext()}
          </div>
          <div>
            {/* <input type='checkbox' value='1' onClick={(event) => {setEnable_restream(event.target.value); }} />
        <input type='checkbox' value='2' onClick={(event) => {setEnable_restream(event.target.value); }} />
        <input type='checkbox' value='3' onClick={(event) => {setEnable_restream(event.target.value); }} /> */}

            <div className="row d-flex mt-3">
              <div className="col-md-10 offset-md-2 mt-3">
                <input
                  className="form-check-input ms-3"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  value="1"
                  onClick={(event) => {
                    setEnable_restream(event.target.value);
                  }}
                />
                <label
                  className="form-check-label ms-1"
                  for="flexRadioDefault1"
                >
                  Video Upload
                </label>

                <input
                  className="form-check-input ms-5"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="m3u8_url"
                  onClick={(event) => {
                    setEnable_restream(event.target.value);
                  }}
                  onChange={(e) => setVideo_type(e.target.value)}
                />
                <label
                  className="form-check-label ms-1"
                  for="flexRadioDefault2"
                >
                  m3u8 Url
                </label>

                <input
                  className="form-check-input ms-5"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault3"
                  value="mp4_url"
                  onClick={(event) => {
                    setEnable_restream(event.target.value);
                  }}
                  onChange={(e) => setVideo_type(e.target.value)}
                />
                <label
                  className="form-check-label ms-1"
                  for="flexRadioDefault3"
                >
                  Video mp4
                </label>

                <input
                  className="form-check-input ms-5"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault3"
                  value="embed"
                  onClick={(event) => {
                    setEnable_restream(event.target.value);
                  }}
                  onChange={(e) => setVideo_type(e.target.value)}
                />
                <label
                  className="form-check-label ms-1"
                  for="flexRadioDefault3"
                >
                  Embed Code
                </label>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      label: "Video Details",
      description: (
        <div className="mt-3">
          <h4>Video Information:</h4>
          <div className="container-fluid">
            <div className="row ">
              <div className="col-sm-6 form-group">
                <label className="m-0">Title :</label>
                {/* <input type="text" className="form-control" name="video_title" id="video_title" placeholder="Title"
                  // onChange={e => setTitle(e.target.value)} 
                  value={editUser.video_title} onChange={handleInput}/> */}

                <input
                  type="text"
                  className="form-control"
                  placeholder="Video Title"
                  name="video_title"
                  id="video_title"
                  value={editUser.video_title}
                  onChange={handleInput}
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">
                  Video Slug
                  <Link
                    className=""
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Please enter the URL Slug"
                    data-original-title="this is the tooltip"
                    to="#"
                  >
                    <i className="las la-exclamation-circle"></i>
                  </Link>
                  :
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="slug"
                  id="slug"
                  placeholder="Video Slug"
                  // value={editUser.video_title}
                  onChange={(e) => setSlug(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 form-group">
                <label className="m-0">Age Restrict :</label>
                <select
                  className="form-control"
                  id="age_restrict"
                  name="age_restrict"
                  onChange={(e) => setAge_restrict(e.target.value)}
                >
                  {age_restrictdata.map((item) => (
                    <option selected value={item.id}>
                      {item.slug}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-6 form-group ">
                <label className="m-0">Rating:</label>
                <select
                  className="form-control"
                  aria-label="Default select example"
                  onChange={(e) => setRating(e.target.value)}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 form-group">
                <label className="m-0">Video Description:</label>
                {/* <textarea rows="5" className="form-control mt-2" name="description" id="summary-ckeditor"
                  placeholder="Description" onChange={e => setDescription(e.target.value)}></textareLink> */}
                {/* <JoditEditor
                  ref={editorvideodes}
                  value={description.content}
                  onChange={(newContent) =>
                    contentFieldChanagedvideodes(newContent)
                  }
                /> */}

                <JoditEditor
                  // ref={editor}
                  value={descript}
                  onChange={(newContent) =>
                    contentFieldChanageddescript(newContent)
                  }
                />
              </div>
              <div className="col-12 form-group">
                <label className="m-0">Links &amp; Details:</label>
                {/* <textarea rows="5" className="form-control mt-2" name="details" id="links-ckeditor"
                  placeholder="Link , and details" onChange={e => setDetails(e.target.value)} ></textareLink> */}
                {/* <JoditEditor
                  ref={editorlinkanddes}
                  value={details.content}
                  onChange={(newContent) =>
                    contentFieldChanagedlinkanddes(newContent)
                  }
                /> */}
                <JoditEditor
                  // ref={editor}
                  value={detal}
                  onChange={(newContent) => contentFieldChanaged(newContent)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 form-group">
                <label className="m-0">
                  Skip Intro Time <small>(Please Give In Seconds)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="skip_intro"
                  name="skip_intro"
                  onChange={(e) => setSkip_intro(e.target.value)}
                />
                {/* <span><p id="error_skip_intro_time">* Fill Skip Intro Time </p></span> */}
              </div>
              <div className="col-sm-4 form-group">
                <label className="m-0">
                  Intro Start Time <small>(Please Give In Seconds)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="intro_start_time"
                  name="intro_start_time"
                  onChange={(e) => setIntro_start_time(e.target.value)}
                />
                {/* <span><p id="error_intro_start_time" >* Fill Intro Start Time </p></span> */}
              </div>
              <div className="col-sm-4 form-group">
                <label className="m-0">
                  Intro End Time <small>(Please Give In Seconds)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="intro_end_time"
                  name="intro_end_time"
                  onChange={(e) => setIntro_end_time(e.target.value)}
                />
                {/* <span><p id="error_intro_end_time" >* Fill Intro End Time </p></span> */}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 form-group">
                <label className="m-0">
                  Skip Recap <small>(Please Give In Seconds)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="skip_recap"
                  name="skip_recap"
                  onChange={(e) => setSkip_recap(e.target.value)}
                />
                {/* <span><p id="error_skip_recap_time" >* Fill Skip Recap Time </p></span> */}
              </div>
              <div className="col-sm-4 form-group">
                <label className="m-0">
                  Recap Start Time <small>(Please Give In Seconds)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="recap_start_time"
                  name="recap_start_time"
                  onChange={(e) => setRecap_start_time(e.target.value)}
                />
                {/* <span><p id="error_recap_start_time"  >* Fill Recap Start Time </p></span> */}
              </div>
              <div className="col-sm-4 form-group">
                <label className="m-0">
                  Recap End Time <small>(Please Give In Seconds)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="recap_end_time"
                  name="recap_end_time"
                  onChange={(e) => setRecap_end_time(e.target.value)}
                />
                {/* <span><p id="error_recap_end_time"  >* Fill Recap End Time </p></span> */}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 form-group">
                <label className="m-0">Video Duration:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Video Duration"
                  name="duration"
                  id="duration"
                  value={editUser.duration}
                  onChange={handleInput}
                />
              </div>
              <div className="col-sm-4 form-group">
                <label className="m-0">Year:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Release Year"
                  name="year"
                  id="year"
                  onChange={(e) => setYear(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 form-group">
                <label className="mb-2">Publish Type</label>
                <div>
                  <input
                    type="radio"
                    id="publish_now"
                    name="publish_type"
                    onClick={(event) => {
                      setEnable_restreampublic(event.target.value);
                    }}
                    onChange={(e) => setPublish_type(e.target.value)}
                    value="publish_now"
                    checked="checked"
                  />{" "}
                  <span>Publish Now</span>
                </div>
                <div>
                  <input
                    type="radio"
                    id="publish_later"
                    value="publish_later"
                    name="publish_type"
                    onClick={(event) => {
                      setEnable_restreampublic(event.target.value);
                    }}
                    onChange={(e) => setPublish_type(e.target.value)}
                  />{" "}
                  <span
                    value="publish_later"
                    name="publish_type"
                    onClick={(event) => {
                      setEnable_restreampublic(event.target.value);
                    }}
                    onChange={(e) => setPublish_type(e.target.value)}
                  >
                    Publish Later
                  </span>{" "}
                </div>
              </div>

              <div className="col-sm-6 form-group" id="publishlater">
                <div
                  onClick={(event) => {
                    setEnablestreamvisiblepublic(event.target.value);
                  }}
                >
                  {proceednextpublic()}
                </div>
              </div>
            </div>
          </div>
          <div className="text-end mt-3">
            {/* <Button
              variant="contained"
              onClick={() => {
                videoupload();
              }}
              className="btn btn-primary ms-2"
            >
              Upload Video
            </Button> */}
            <Button onClick={handleBack} className="btn btn-dark ms-2">
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              className="btn btn-primary ms-2"
            >
              Next
            </Button>
          </div>
        </div>
      ),
    },
    {
      label: "Category",
      description: (
        <div className="container-fluid">
          <div className="form-card mt-3">
            <div className="row">
              <div className="col-sm-6 form-group ">
                <label className="m-0">Select Video Category :</label>

                <div>
                  <Select
                    options={category?.map((option) => ({
                      value: option?.id,
                      label: option?.name,
                    }))}
                    className="form-control"
                    isMulti
                    value={selectedOptionscategory}
                    onChange={handleSelectChangecategory}
                  />
                </div>
                {/* <span><p id="error_video_Category"  >* Choose the Video Category </p></span> */}
              </div>
              <div className="col-sm-6 form-group">
                <div className="panel panel-primary" data-collapsed="0">
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label className="m-0">
                        Cast and Crew :{" "}
                        <small>( Add artists for the video below )</small>
                      </label>
                    </div>
                    <div className="panel-options">
                      <Select
                        options={artists?.map((option) => ({
                          value: option?.id,
                          label: option?.artist_name,
                        }))}
                        className="form-control"
                        isMulti
                        value={selectedOptionsartists}
                        onChange={handleSelectChangeartists}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 form-group ">
                <label className="m-0">Choose Language:</label>
                <div>
                  <Select
                    options={language?.map((option) => ({
                      value: option?.id,
                      label: option?.name,
                    }))}
                    className="form-control"
                    isMulti
                    value={selectedOptionslanguage}
                    onChange={handleSelectChangelanguage}
                  />
                </div>

                {/* <span><p id="error_language"  >* Choose the Language </p></span> */}
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">
                  E-Paper: <small>(Upload your PDF file)</small>
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="pdf_file"
                  accept="application/pdf"
                  onChange={(e) => setPdf_files(e.target.files[0])}
                  multiple
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">Reels Videos: </label>
                <input
                  type="file"
                  className="form-control"
                  name="reels_videos[]"
                  accept="video/mp4,video/x-m4v,video/*"
                  onChange={(e) => setReelvideo(e.target.files[0])}
                  multiple
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">
                  Reels Thumbnail: <small>(9:16 Ratio or 720X1080px)</small>
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="reels_thumbnail"
                  onChange={(e) => setReels_thumbnail(e.target.files[0])}
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">URL Link </label>
                <input
                  type="text"
                  className="form-control"
                  name="url_link"
                  onChange={(e) => setUrl(e.target.value)}
                  id="url_link"
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">
                  URL Start Time <small>Format (HH:MM:SS)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="url_linktym"
                  onChange={(e) => setUrl_linktym(e.target.value)}
                  id="url_linktym"
                />
              </div>
            </div>
            {/* <div className="row mt-5">
              <div className="panel panel-primary" data-collapsed="0">
                <div className="panel-heading col-sm-12">
                  <div className="panel-title" > <label className="m-0">Subtitles (srt or txt) :</label>
                  </div>
                  <div className="panel-options">
                    <Link to="#" data-rel="collapse"><i className="entypo-down-open"></i></Link>
                  </div>
                </div>
                <div className="panel-body row" >
  
                  <div className="col-sm-6 form-group" >
                    <div className="align-items-center" >
                      <label for="embed_code"  >Upload Subtitle English </label>
                      <input className="mt-1" type="file" name="subtitle_upload[]" id="subtitle_upload_{{ $subtitle->short_code }}" />
                      <input className="mt-1" type="hidden" name="sub_language[]" value="{{ $subtitle->language }}" />
                    </div>
                  </div>
                  <div className="col-sm-6 form-group" >
                    <div className="align-items-center" >
                      <label for="embed_code"  >Upload Subtitle German </label>
                      <input className="mt-1" type="file" name="subtitle_upload[]" id="subtitle_upload_{{ $subtitle->short_code }}" />
                      <input className="mt-1" type="hidden" name="sub_language[]" value="{{ $subtitle->language }}" />
                    </div>
                  </div>
                  <div className="col-sm-6 form-group" >
                    <div className="align-items-center" >
                      <label for="embed_code"  >Upload Subtitle Spanish </label>
                      <input className="mt-1" type="file" name="subtitle_upload[]" id="subtitle_upload_{{ $subtitle->short_code }}" />
                      <input className="mt-1" type="hidden" name="sub_language[]" value="{{ $subtitle->language }}" />
                    </div>
                  </div>
                  <div className="col-sm-6 form-group" >
                    <div className="align-items-center" >
                      <label for="embed_code"  >Upload Subtitle Hindi </label>
                      <input className="mt-1" type="file" name="subtitle_upload[]" id="subtitle_upload_{{ $subtitle->short_code }}" />
                      <input className="mt-1" type="hidden" name="sub_language[]" value="{{ $subtitle->language }}" />
                    </div>
                  </div>
  
                </div>
              </div>
            </div> */}
          </div>
          <div className="text-end mt-3">
            {/* <Button
              variant="contained"
              onClick={() => {
                videoupload();
              }}
              className="btn btn-primary ms-2"
            >
              Upload Video
            </Button> */}
            <Button onClick={handleBack} className="btn btn-dark ms-2">
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              className="btn btn-primary ms-2"
            >
              Next
            </Button>
          </div>
        </div>
      ),
    },
    {
      label: "User Video Access",
      description: (
        <div className="form-card mt-3">
          <div className="row">
            <div className="col-sm-12">
              <h5 className="fs-title">Geo-location for Videos</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group ">
              <label className="m-0">Block Country </label>
              <p className="p1">
                ( Choose the countries for block the videos )
              </p>

              <div>
                <Select
                  options={block_country?.map((option) => ({
                    value: option?.id,
                    label: option?.name,
                  }))}
                  className="form-control"
                  isMulti
                  value={selectedOptions}
                  onChange={handleSelectChange}
                />
              </div>
            </div>

            <div className="col-sm-6 form-group">
              <label className="m-0"> Country </label>
              <p className="p1">( Choose the countries videos )</p>
              <div>
                <Select
                  options={available_country?.map((option) => ({
                    value: option?.id,
                    label: option?.name,
                  }))}
                  className="form-control"
                  isMulti
                  value={selectedOptionsAvailable_country}
                  onChange={handleSelectChangeAvailable_country}
                />
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-sm-6 form-group">
              <label for="">Search Tags </label>
              <input
                type="text"
                id="exist-values"
                className="tagged form-control"
                data-removeBtn="true"
                name="searchtags"
                onChange={(e) => setSearch_tags(e.target.value)}
              />
            </div>

            <div className="col-sm-6 form-group ">
              <label className="m-0">Related Videos:</label>
              <div>
                <Select
                  options={related_videos?.map((option) => ({
                    value: option?.id,
                    label: option?.title,
                  }))}
                  className="form-control"
                  isMulti
                  value={selectedOptionsRelated_videos}
                  onChange={handleSelectChangeRelated_videos}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <label className="m-0">User Access</label>
              <p className="p1">Who Is Allowed To View This Live Stream ?</p>
              <select
                className="form-control"
                onChange={(e) => setUserAccess(e.target.value)}
                onClick={(event) => {
                  setLivesource(event.target.value);
                }}
              >
                {user_access?.map((item, key) => (
                  <option value={item.role}>{item.name}</option>
                ))}
              </select>
            </div>
          </div>

          <div>{live_stream_source()}</div>

          <div className="row">
            <div className="col-sm-6 form-group mt-3" id="ppv_price">
              <div className="row col-lg-12">
                <div className="col-8 col-sm-8 col-md-8 col-l-8">
                  <label for="global_ppv">Is this video Is Global PPV:</label>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-l-4 text-end">
                  <label className="switch">
                    <input
                      name="global_ppv"
                      id="global_ppv"
                      onChange={handleInput}
                      defaultChecked={global_ppv.global_ppv == 1 ? true : false}
                      checked={global_ppv.global_ppv == 1 ? true : false}
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="global_ppv"
                      id="global_ppv"
                      onChange={handleInput}
                      value={global_ppv.global_ppv == 1 ? "1" : "0"}
                    ></span>
                  </label>
                </div>
              </div>

              {/* <input type="text" name="global_ppv" onChange={e => setGlobal_ppv(e.target.value)} id="global_ppv" /> */}
            </div>
            <div className="col-sm-6 mt-3">
              <div className="panel panel-primary" data-collapsed="0">
                <div className="panel-heading">
                  <div className="panel-title">
                    <label>
                      <h3 className="fs-title">Status Settings</h3>
                    </label>
                  </div>
                </div>
                <div className="panel-body">
                  <div className="row col-lg-12">
                    <div className="col-8 col-sm-8 col-md-8 col-l-8">
                      <label for="featured">
                        Enable this video as Featured:
                      </label>
                    </div>
                    <div className="col-4 col-sm-4 col-md-4 col-l-4 text-end">
                      <label className="switch">
                        <input
                          name="featured"
                          id="featured"
                          onChange={handleInput}
                          defaultChecked={featured.featured == 1 ? true : false}
                          checked={featured.featured == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="featured"
                          id="featured"
                          onChange={handleInput}
                          value={featured.featured == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="row col-lg-12">
                    <div className="col-8 col-sm-8 col-md-8 col-l-8">
                      <label for="active">Enable this Video:</label>
                    </div>
                    <div className="col-4 col-sm-4 col-md-4 col-l-4 text-end">
                      <label className="switch">
                        <input
                          name="active"
                          id="active"
                          onChange={handleInput}
                          defaultChecked={active.active == 1 ? true : false}
                          checked={active.active == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="active"
                          id="active"
                          onChange={handleInput}
                          value={active.active == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="row col-lg-12">
                    <div className="col-8 col-sm-8 col-md-8 col-l-8">
                      <label for="banner">Enable this Video as Slider:</label>
                    </div>
                    <div className="col-4 col-sm-4 col-md-4 col-l-4 text-end">
                      <label className="switch">
                        <input
                          name="banner"
                          onChange={handleInput}
                          defaultChecked={banner.banner == 1 ? true : false}
                          checked={banner.banner == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="banner"
                          onChange={handleInput}
                          value={banner.banner == 1 ? "on" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="row col-lg-12">
                    <div className="col-8 col-sm-8 col-md-8 col-l-8">
                      <label for="status">Enable this Video as Status:</label>
                    </div>
                    <div className="col-4 col-sm-4 col-md-4 col-l-4 text-end">
                      <label className="switch">
                        <input
                          name="status"
                          id="status"
                          onChange={handleInput}
                          defaultChecked={status.status == 1 ? true : false}
                          checked={status.status == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="status"
                          id="status"
                          onChange={handleInput}
                          value={status.status == 1 ? "on" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-end mt-3">
            {/* <Button
              variant="contained"
              onClick={() => {
                videoupload();
              }}
              className="btn btn-primary ms-2"
            >
              Upload Video
            </Button> */}
            <Button onClick={handleBack} className="btn btn-dark ms-2">
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              className="btn btn-primary ms-2"
            >
              Next
            </Button>
          </div>
        </div>
      ),
    },
    {
      label: "Upload Image & Trailer",
      description: (
        <div className="form-card mt-3">
          <div className="row">
            <div className="col-7">
              <h3 className="fs-title">Image Upload:</h3>
            </div>
            <div className="col-5"></div>
          </div>

          <div className="row">
            <div className="col-sm-6 form-group">
              <label className="mb-1">
                Video Thumbnail <span>(9:16 Ratio or 1080X1920px)</span>
              </label>
              <br />
              <input
                type="file"
                className="form-control"
                name="image"
                id="image"
                onChange={(e) => setImage(e.target.files[0])}
              />
              <span>
                <p id="image_error_msg">
                  * Please upload an image with 1080 x 1920 pixels dimension or
                  ratio 9:16{" "}
                </p>
              </span>
            </div>

            <div className="col-sm-6 form-group">
              <label className="mb-1">
                Player Thumbnail <span>(16:9 Ratio or 1280X720px)</span>
              </label>
              <br />
              <input
                type="file"
                className="form-control"
                name="player_image"
                id="player_image"
                onChange={(e) => setPlayer_image(e.target.files[0])}
              />
              <span>
                <p id="player_image_error_msg">
                  * Please upload an image with 1280 x 720 pixels dimension or
                  ratio 16:9{" "}
                </p>
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 form-group">
              <label className="mb-1"> Video TV Thumbnail </label>
              <br />
              <input
                type="file"
                className="form-control"
                name="video_tv_image"
                id="video_tv_image"
                onChange={(e) => setVideo_tv_image(e.target.files[0])}
              />
              <span>
                <p id="tv_image_image_error_msg">
                  * Please upload an image with 1920 x 1080 pixels dimension or
                  16:9 ratio{" "}
                </p>
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 form-group">
              <label className="mb-1"> Video Title Thumbnail </label>
              <br />
              <input
                type="file"
                className="form-control"
                name="video_title_image"
                id="video_title_image"
                onChange={(e) => setVideo_title_image(e.target.files[0])}
              />
            </div>

            <div className="col-sm-6 form-group">
              <label className="mb-1">Enable Video Title Thumbnail</label>
              <br />
              <div className="mt-1">
                {/* <label className="switch"> */}
                <label className="switch">
                  <input
                    name="enable_video_title_image"
                    onChange={handleInput}
                    defaultChecked={
                      enable_video_title_image.enable_video_title_image == 1
                        ? true
                        : false
                    }
                    checked={
                      enable_video_title_image.enable_video_title_image == 1
                        ? true
                        : false
                    }
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    name="enable_video_title_image"
                    onChange={handleInput}
                    value={
                      enable_video_title_image.enable_video_title_image == 1
                        ? "1"
                        : "0"
                    }
                  ></span>
                </label>

                {/* <input name="enable_video_title_image" className="" id="enable_video_title_image" type="text"
                  onChange={e => setEnable_video_title_image(e.target.value)} /> */}
                {/* <span className="slider round"></span> */}
                {/* </label> */}
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-7">
              <h5 className="fs-title">Trailer Upload:</h5>
            </div>

            <div className="col-sm-6">
              <label className="m-0">Video Trailer Type:</label>
              <select
                className="trailer_type form-control"
                name="trailer_type"
                id="trailer_type"
                onChange={(e) => setTrailer_type(e.target.value)}
                onClick={(event) => {
                  setEnable_restreamtrailer(event.target.value);
                }}
              >
                <option value="null"> Select the Video Trailer Type </option>
                <option value="1"> Video Upload </option>
                <option value="m3u8_url">m3u8 Url </option>
                <option value="mp4_url">mp4 Url</option>
                <option value="embed_url">Embed Code</option>
              </select>
            </div>
          </div>

          <div>
            <div
              onClick={(event) => {
                setEnablestreamvisibletrailer(event.target.value);
              }}
            >
              {proceednexttrailer()}
            </div>
          </div>

          {/* <div className="row trailer_m3u8_url">
            <div className="col-sm-6 form-group" >
              <label className="m-0"> Trailer m3u8 Url :</label>
              <input type="text" className="form-control" name="m3u8_trailer" id="" value="" />
            </div>
          </div>
  
          <div className="row trailer_mp4_url">
            <div className="col-sm-6 form-group" >
              <label className="m-0"> Trailer mp4 Url :</label>
              <input type="text" className="form-control" name="mp4_trailer" id="" value="" />
            </div>
          </div>
  
          <div className="row trailer_embed_url">
            <div className="col-sm-6 form-group" >
              <label className="m-0">Trailer Embed Code :</label>
              <input type="text" className="form-control" name="embed_trailer" id="" value="" />
            </div>
          </div> */}

          {/* <div className="row trailer_video_upload">
            <div className="col-sm-8 form-group">
              <label className="m-0">Upload Trailer :</label><br />
              <div className="new-video-file form_video-upload" >
                <input type="file" accept="video/mp4,video/x-m4v,video/*" name="trailer" id="trailer" />
                <p >Drop and drag the video file</p>
              </div>
              <span id="remove" className="danger">Remove</span>
            </div>
  
            <div className="col-sm-4 form-group">
  
              <video width="200" height="200" controls>
  
              </video>
  
            </div>
          </div> */}

          <div className="row">
            <div className="col-sm-8  form-group">
              <label className="m-0">Trailer Description:</label>
              {/* <textarea rows="5" className="form-control mt-2" name="trailer_description" id="trailer-ckeditor"
                onChange={e => setTrailer_description(e.target.value)} placeholder="Trailer Description">
              </textareLink> */}

              {/* <JoditEditor
                ref={editortrailerdes}
                value={trailer.content}
                onChange={(newContent) =>
                  contentFieldChanagedtrailerdes(newContent)
                }
              /> */}

              <JoditEditor
                // ref={editor}
                value={trailerdec}
                onChange={(newContent) =>
                  contentFieldChanagedtrailerdec(newContent)
                }
              />
            </div>
          </div>
          <div className="text-end mt-3">
            {/* <Button
              variant="contained"
              onClick={() => {
                videoupload();
              }}
              className="btn btn-primary ms-2"
            >
              Upload Video
            </Button> */}
            <Button onClick={handleBack} className="btn btn-dark ms-2">
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              className="btn btn-primary ms-2"
            >
              Next
            </Button>
          </div>
        </div>
      ),
    },
    {
      label: "ADS Management",
      description: (
        <div className="form-card mt-3">
          <div className="row p-0 m-0">
            <div className="col-7">
              <h4 className="fs-title">ADS Management:</h4>
            </div>

            <div className="row text-start mt-4">
              <div className="col-sm-6">
                <label className="m-0">Choose Ads Position</label>

                <div className="">
                  {/* <AddLiveStreamPosition
                              options={adsPositions}
                              onChange={handleAdsPositionChange}
                            /> */}

                  <Select
                    options={adsPositions}
                    onChange={(e) => handleAdsPositionChange(e)}
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <label className="m-0">Choose Advertisement</label>

                <div className="">
                  <Select
                    options={secondSelectOptions}
                    onChange={(e) => setSecondSelectOptions(e)}
                  />
                </div>
              </div>
            </div>

            {/* <div className="row col-lg-12">
            <div className="col-lg-6 col-sm-3 form-group mt-1">
              <label className="">Choose Ads Position</label>
              <select
                className="form-control"
                name="mid_ads_category"
                id="mid_ads_category"
              >
                <option value="item.id">tem.name</option>
              </select>
            </div>
            <div className="col-lg-6 col-sm-3 form-group mt-3">
              <label className="">Choose Advertisement</label>
              <select
                className="form-control"
                name="mid_ads_category"
                id="mid_ads_category"
              >
                <option value="item.id">tem.name</option>
              </select>
            </div>
          </div> */}

            {/* <div className="col-sm-3 form-group mt-3">
            <label className="">
              Choose Pre-Ad <br /> Category
            </label>
            <select
              className="form-control"
              name="pre_ads_category"
              id="pre_ads_category"
              value={selectedValue}
              onChange={handleSelectChangeurl}
            > */}
            {/* {pre_ads_category.map((item, key) =>
            <option value=" ">Select Pre-Ad Category</option>
            )} */}
            {/* {pre_ads_category.map((item, key) =>
              <option value={item.id}>{item.name}</option>
            )} */}
            {/* </select>
          </div> */}

            {/* <div className="col-sm-3 form-group mt-3">
            <label className="">
              Choose Mid-Ad <br /> Category
            </label>
            <select
              className="form-control"
              name="mid_ads_category"
              id="mid_ads_category"
            > */}
            {/* {mid_ads_category.map((item, key) =>
              <option value={item.id}>{item.name}</option>
            )} */}
            {/* </select>
          </div> */}

            {/* <div className="col-sm-3 form-group mt-3">
            <label className="">
              Choose Post-Ad <br /> Category
            </label>
            <select
              className="form-control"
              name="post_ads_category"
              id="post_ads_category"
            > */}
            {/* {post_ads_category.map((item, key) =>
              <option value={item.id}>{item.name}</option>
            )} */}
            {/* </select>
          </div> */}

            {/* <div className="col-sm-3 form-group mt-3">
            <label className="">Choose Tag-url Ads Position</label>
            <select
              className="form-control"
              name="tag_url_ads_position"
              id="tag_url_ads_position"
            > */}
            {/* {post_ads_category.map((item, key) =>
              <option value={item.id}>{item.name}</option>
            )} */}
            {/* </select>
          </div> */}

            {/* <div className="col-sm-3 form-group mt-3" id="pre_ads_div">
            <label className="">Choose Pre-Ad </label>
            <select className="form-control" name="pre_ads" id="pre_ads">
              <option value=" ">Please Choose Above Cataegory </option>
            </select>
          </div> */}

            {/* <div className="col-sm-3 form-group mt-3" id="mid_ads_div">
            <label className="">Choose Mid-Ad </label>
            <select className="form-control" name="mid_ads" id="mid_ads">
              <option value=" ">Please Choose Above Cataegory </option>
            </select>
          </div> */}

            {/* <div className="col-sm-3 form-group mt-3" id="post_ads_div">
            <label className="">Choose Post-Ad </label>
            <select className="form-control" name="post_ads" id="post_ads">
              <option value=" ">Please Choose Above Cataegory</option>
            </select>
          </div> */}

            {/* <div className="col-sm-3 form-group mt-3" id="ads_tag_url_id_div">
            <label className="">Choose Tag-url Ads </label>
            <select
              className="form-control"
              name="ads_tag_url_id"
              id="ads_tag_url_id"
            >
              <option value=" ">Please Choose Above Cataegory </option>
            </select>
          </div>

          <div className="col-sm-4 form-group mt-3" id="post_ads_div">
            <label className="">Choose Post-Ad </label>
            <input onChange={(e) => setAds_tag_url_id(e.target.value)} />
          </div> */}

            {/* <div className="col-sm-6 form-group mt-3">
          <label className="">Choose Ad Name</label>
          <select className="form-control" name="ads_id">
            <option value="0">Select Ads</option>

          </select>
        </div> */}

            {/* <div className="col-sm-6 form-group mt-3">
          <label className="">Default Ads</label>
          <label className="switch">
            <input name="default_ads" type="checkbox" />
            <span className="slider round"></span>
          </label>
        </div> */}

            {/* <div className="col-sm-6 form-group mt-3">
          <label className="">Choose Ad Roll</label>
          <select className="form-control" name="ad_roll">
            <option value="0">Select Ad Roll</option>
            <option value="1">Pre</option>
            <option value="2">Mid</option>
            <option value="3">Post</option>
          </select>
        </div> */}
          </div>
          <div className="text-end mt-3">
            <Button onClick={handleBack} className="btn btn-dark">
              Back
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                videoupload();
              }}
              className="btn btn-primary ms-2"
            >
              Submit
            </Button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid ">
          <div className="pt-3">
            <div className="row">
              <div className="col-sm-12">
                <div className="iq-card addvideo">
                  <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                      <h4 className="card-title">Add Video</h4>
                    </div>
                  </div>
                  <hr />

                  <Box>
                    <Stepper activeStep={activeStep} nonLinear>
                      {steps.map((step, index) => (
                        <Step key={step.label}>
                          <StepLabel
                            optional={
                              index === 5 ? (
                                <Typography variant="caption"></Typography>
                              ) : null
                            }
                          >
                            {step.label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>

                    <Stepper
                      className="video"
                      activeStep={activeStep}
                      nonLinear
                    >
                      {steps.map((step, index) => (
                        <Step key={step.label}>
                          <StepContent>
                            <Typography>{step.description}</Typography>
                            {/* <Box sx={{ mb: 2 }}>
                                <div>
                                  <Button
                                    variant="contained"
                                    onClick={handleNext}
                                    sx={{ mt: 1, mr: 1 }}
                                    className="btn btn-primary">
                                    {index === steps.length - 1 ? 'Upload Video' : 'Next'}
                                  </Button>
                                  <Button
                                    disabled={index === 0}
                                    onClick={handleBack}
                                    sx={{ mt: 1, mr: 1 }}
                                    className="btn btn-dark">
                                    Back
                                  </Button>
                                </div>
                              </Box> */}
                          </StepContent>
                        </Step>
                      ))}
                    </Stepper>

                    {activeStep === steps.length && (
                      <Paper square elevation={0} sx={{ p: 3 }}>
                        <Typography>
                          All steps completed - you&apos;re finished
                        </Typography>
                        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                          Reset
                        </Button>
                      </Paper>
                    )}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Add_NewVideo;
