import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AddManage_VideoCategories = () => {
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [home_genre, setHome_genre] = useState("");
  const [in_home, setIn_home] = useState("");
  const [in_menu, setIn_menu] = useState("");
  const [image, setImage] = useState("");
  const [banner_image, setBanner_image] = useState("");
  const [parent_id, setParent_id] = useState("");
  const [banner, setBanner] = useState("");

  const [data, setData] = useState([]);

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");
  const [Content_Partner_id, setContent_Partner_id] = useState("1");

  const handleInput = (e) => {
    setIn_home({ ...in_home, [e.target.name]: e.target.value });
    setIn_menu({ ...in_menu, [e.target.name]: e.target.value });
    setBanner({ ...banner, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setIn_home({ ...in_home, [e.target.name]: 1 });
      setIn_menu({ ...in_menu, [e.target.name]: 1 });
      setBanner({ ...banner, [e.target.name]: 1 });
    } else {
      setIn_home({ ...in_home, [e.target.name]: 0 });
      setIn_menu({ ...in_menu, [e.target.name]: 0 });
      setBanner({ ...banner, [e.target.name]: 0 });
    }
  };

  async function AddManagevideocategories() {
    // console.log('append data')

    const editInputin_home = in_home?.in_home;
    var datain_home = JSON.stringify(editInputin_home);
    var in_homedata = datain_home;

    const editInputin_menu = in_menu?.in_menu;
    var datain_menu = JSON.stringify(editInputin_menu);
    var in_menudata = datain_menu;

    const editInputbanner = banner?.banner;
    var databanner = JSON.stringify(editInputbanner);
    var bannerdata = databanner;

    const formData = new FormData();

    formData.append("name", name);
    formData.append("slug", slug);
    formData.append("home_genre", home_genre);
    formData.append("in_home", in_homedata);
    formData.append("in_menu", in_menudata);
    formData.append("parent_id", parent_id);
    formData.append("banner", bannerdata);
    formData.append("Content_Partner_id", Content_Partner_id);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/videos-category/store`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        console.log("api", response);
        if (response.data.status === true) {
          console.log("result", response);
          // navigate('/admin/series-list')
          // alert("Added successfully  !")
          var VideoCategory_id = response.data.VideoCategory.id;
          // setSeries_genre_id(series_id)
          // console.log("resssss", VideoCategory_id);
          // console.log('series_id', series_id);

          const formDat = new FormData();

          formDat.append("image", image);
          formDat.append("banner_image", banner);
          formDat.append("VideoCategory_id", VideoCategory_id);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/CPP/Backend/videos-category/image-upload`,
              formDat,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api', response);
              if (response.data.status === true) {
                var result = response.data;
                console.log("result", result);
                alert("Added successfully  !");
              }
            });
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/videos-category/create`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        var result = response?.data?.Video_Category;
        setData(result);
        // console.log(result)
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="pt-3">
              <div className="admin-section-title">
                <div className="iq-card">
                  <div className="row">
                    <div className="col-md-6">
                      <h4>
                        <i className=""></i> Add Video Categories
                      </h4>
                    </div>
                  </div>
                  <hr />

                  <div className="">
                    <div>
                      <div className="form-group ">
                        <label>Name:</label>

                        <input
                          type="text"
                          id="name"
                          name="name"
                          onChange={(e) => setName(e.target.value)}
                          className="form-control"
                          placeholder="Enter Name"
                        />
                      </div>

                      <div className="form-group ">
                        <label>Slug:</label>

                        <input
                          type="text"
                          id="slug"
                          name="slug"
                          onChange={(e) => setSlug(e.target.value)}
                          className="form-control"
                          placeholder="Enter Slug"
                        />
                      </div>

                      <div className="form-group ">
                        <label>Display In Home page:</label>

                        <label className="switch">
                          <input
                            name="in_home"
                            onChange={handleInput}
                            defaultChecked={
                              in_home.in_home === 1 ? true : false
                            }
                            checked={in_home.in_home === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="in_home"
                            onChange={handleInput}
                            value={in_home.in_home === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>

                      <div className="form-group ">
                        <label>Display In Menu :</label>
                        <label className="switch">
                          <input
                            name="in_menu"
                            onChange={handleInput}
                            defaultChecked={
                              in_menu.in_menu === 1 ? true : false
                            }
                            checked={in_menu.in_menu === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="in_menu"
                            onChange={handleInput}
                            value={in_menu.in_menu === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                      <div className="form-group ">
                        <label>Image:</label>
                        <input
                          type="file"
                          multiple="true"
                          className="form-control"
                          name="image"
                          onChange={(e) => setImage(e.target.files[0])}
                          id="image"
                        />
                      </div>

                      <div className="col-md-12 mb-3">
                        <label>Category:</label>
                        <select
                          className="form-control"
                          name="parent_id"
                          id="parent_id"
                          onChange={(e) => setParent_id(e.target.value)}
                        >
                          <option value="">Select</option>
                          {data?.map((item) => (
                            <option value={item?.parent_id}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className=" text-end form-group">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          id="submit-new-cat"
                          onClick={AddManagevideocategories}
                        >
                          Add Category
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AddManage_VideoCategories;
